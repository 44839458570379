import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import { trashNotiMoney, trashPermanentlyDelete, trashRecovery, trashSubsidyMoney } from 'api/trashNotification';
import { RootState } from 'app/store';
import { FetchNotificationList, RestoreTrashManagement, TrashNotiData, TrashNotiExpenseMoney } from 'types';

/**
 * GET Trash Expense Money
 **/
export const fetchTrashExpenseMoney = createAsyncThunk(
    '/admin/funds/trash ',
    async (params: FetchNotificationList, { dispatch, rejectWithValue }) => {
        try {
            const response = await trashNotiMoney(params);
            const { success } = response.data;

            if (success) {
                dispatch(setTrashExpenseMoney(response.data));
                return success;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * DELETE Trash Subsidy Money
 **/
export const deleteSubsidyMoney = createAsyncThunk('/admin/funds/subsidyId ', async (fundId: number, { rejectWithValue }) => {
    try {
        const response = await trashSubsidyMoney(fundId);
        const { success } = response.data;

        return success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

/**
 * DELETE Trash Grant Money
 **/
export const deleteGrantMoney = createAsyncThunk('/admin/funds/grantId ', async (fundId: number, { rejectWithValue }) => {
    try {
        const response = await trashSubsidyMoney(fundId);
        const { success } = response.data;

        return success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

/**
 * DELETE Trash Loan Money
 **/
export const deleteLoanMoney = createAsyncThunk('/admin/funds/loanId ', async (fundId: number, { rejectWithValue }) => {
    try {
        const response = await trashSubsidyMoney(fundId);
        const { success } = response.data;

        return success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

/**
 * DELETE trash permanently delete
 **/
export const trashManagePermanentlyDelete = createAsyncThunk('/admin/trash/fundId/delete ', async (fundId: number, { rejectWithValue }) => {
    try {
        const response = await trashPermanentlyDelete(fundId);
        const { success } = response.data;

        return success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

/**
 * POST Recovery Money
 **/
export const trashRecoveryManagement = createAsyncThunk('/admin/trash/fundId/recovery ', async (fundId: number, { rejectWithValue }) => {
    try {
        const response = await trashRecovery(fundId);
        const { success } = response.data;

        if (success) {
            return true;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

type TrashNotiManagement = {
    trashExpenseMoney: TrashNotiExpenseMoney;
    subsidyMoney: RestoreTrashManagement;
    grantMoney: RestoreTrashManagement;
    loanMoney: RestoreTrashManagement;
    permanentlyDelete: RestoreTrashManagement;
};

const initialState = {
    trashExpenseMoney: {
        success: false,
        loading: false,
        error: false,
        data: [] as TrashNotiData[],
    },
    subsidyMoney: {
        success: false,
        message: '',
        loading: false,
        error: false,
    },
    grantMoney: {
        success: false,
        message: '',
        loading: false,
        error: false,
    },
    loanMoney: {
        success: false,
        message: '',
        loading: false,
        error: false,
    },
    permanentlyDelete: {
        success: false,
        message: '',
        loading: false,
        error: false,
    },
} as TrashNotiManagement;

export const trashNotiManageSlice = createSlice({
    name: 'trashNoti',
    initialState: initialState,
    reducers: {
        setTrashExpenseMoney: (state, action) => {
            state.trashExpenseMoney = action?.payload;
        },
        resetSubsidyMoney: (state) => {
            state.subsidyMoney = {
                success: false,
                message: '',
                loading: false,
                error: false,
            } as RestoreTrashManagement;
        },
        resetGrantMoney: (state) => {
            state.grantMoney = {
                success: false,
                message: '',
                loading: false,
                error: false,
            } as RestoreTrashManagement;
        },
        resetLoanMoney: (state) => {
            state.loanMoney = {
                success: false,
                message: '',
                loading: false,
                error: false,
            } as RestoreTrashManagement;
        },
        resetPermanentlyDelete: (state) => {
            state.permanentlyDelete = {
                success: false,
                message: '',
                loading: false,
                error: false,
            } as RestoreTrashManagement;
        },
    },
    extraReducers: (builder) => {
        builder
            // subsidyMoney
            .addCase(deleteSubsidyMoney.pending, (state: { subsidyMoney: RestoreTrashManagement }, action) => {
                state.subsidyMoney.loading = true;
            })
            .addCase(deleteSubsidyMoney.rejected, (state: { subsidyMoney: RestoreTrashManagement }, action) => {
                state.subsidyMoney = {
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string,
                };
            })
            .addCase(deleteSubsidyMoney.fulfilled, (state: { subsidyMoney: RestoreTrashManagement }, action) => {
                state.subsidyMoney.success = true;
                state.subsidyMoney.loading = false;
            })

            // grantMoney
            .addCase(deleteGrantMoney.pending, (state: { grantMoney: RestoreTrashManagement }, action) => {
                state.grantMoney.loading = true;
            })
            .addCase(deleteGrantMoney.rejected, (state: { grantMoney: RestoreTrashManagement }, action) => {
                state.grantMoney = {
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string,
                };
            })
            .addCase(deleteGrantMoney.fulfilled, (state: { grantMoney: RestoreTrashManagement }, action) => {
                state.grantMoney.success = true;
                state.grantMoney.loading = false;
            })

            // loanMoney
            .addCase(deleteLoanMoney.pending, (state: { loanMoney: RestoreTrashManagement }, action) => {
                state.loanMoney.loading = true;
            })
            .addCase(deleteLoanMoney.rejected, (state: { loanMoney: RestoreTrashManagement }, action) => {
                state.loanMoney = {
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string,
                };
            })

            .addCase(fetchTrashExpenseMoney.pending, (state: TrashNotiManagement) => {
                state.trashExpenseMoney.loading = true;
            })
            .addCase(fetchTrashExpenseMoney.fulfilled, (state: TrashNotiManagement) => {
                state.trashExpenseMoney.loading = false;
                state.trashExpenseMoney.success = true;
            })
            .addCase(fetchTrashExpenseMoney.rejected, (state: TrashNotiManagement, { payload }) => {
                state.trashExpenseMoney.loading = false;
                state.trashExpenseMoney.success = false;
                state.trashExpenseMoney.error = true;
            })

            .addCase(deleteLoanMoney.fulfilled, (state: { loanMoney: RestoreTrashManagement }, action) => {
                state.loanMoney.success = true;
                state.loanMoney.loading = false;
            })

            // permanently delete
            .addMatcher(isAnyOf(trashRecoveryManagement.pending, trashManagePermanentlyDelete.pending), (state: TrashNotiManagement) => {
                state.permanentlyDelete.loading = true;
            })
            .addMatcher(
                isAnyOf(trashRecoveryManagement.fulfilled, trashManagePermanentlyDelete.fulfilled),
                (state: TrashNotiManagement) => {
                    state.permanentlyDelete.loading = false;
                    state.permanentlyDelete.success = true;
                    state.permanentlyDelete.error = false;
                }
            )
            .addMatcher(
                isAnyOf(trashRecoveryManagement.rejected, trashManagePermanentlyDelete.rejected),
                (state: TrashNotiManagement, { payload }) => {
                    state.permanentlyDelete.loading = false;
                    state.permanentlyDelete.success = false;
                    state.permanentlyDelete.error = true;
                    state.permanentlyDelete.message = payload as string | object;
                }
            );
    },
});

export const { setTrashExpenseMoney, resetSubsidyMoney, resetGrantMoney, resetLoanMoney, resetPermanentlyDelete } =
    trashNotiManageSlice.actions;
export const trashNotificationSelector = (state: RootState) => state.trashNoti;
