import React, { useEffect } from 'react';
import { Constant } from '../../config/constant';
import { Layout, Menu, SiderProps } from 'antd';
import { AreaChartOutlined, LayoutOutlined, FolderOpenOutlined, FileTextOutlined, TeamOutlined } from '@ant-design/icons';
import { NavLink, useLocation } from 'react-router-dom';
import { useIsActiveMenu, useSelectedMenu } from '../../lib/utils';

const { Header, Sider } = Layout;
const { SubMenu } = Menu;

type Props = SiderProps & {
    onCloseCollapsed?: () => void;
};

const Sidebar = (props: Props) => {
    const { breakpoint, collapsedWidth, onBreakpoint, collapsed, onCollapse, onCloseCollapsed } = props;
    const { pathname } = useLocation();
    const [openKeys, setOpenKeys] = useIsActiveMenu();
    const selectedMenus = useSelectedMenu();

    const onOpenChange = (keys: string[]) => {
        let newOpenKeys = [];
        let lastKey = keys.pop();

        if (lastKey) {
            if (lastKey === 'master-data-subsidy-grand-management') {
                newOpenKeys.push('support-online', 'master-data-subsidy-grand-management');
            }
            newOpenKeys.push(lastKey);
        }
        setOpenKeys(newOpenKeys);
    };

    useEffect(() => {
        onCloseCollapsed?.();
    }, [onCloseCollapsed, pathname]);

    return (
        <Sider
            breakpoint={breakpoint}
            collapsedWidth={collapsedWidth}
            onBreakpoint={onBreakpoint}
            onCollapse={onCollapse}
            collapsed={collapsed}
            width={Constant.SIDEBAR_WIDTH}
            className='position-fixed top-0 bottom-0 start-0 site-sidebar ant-menu-light'
        >
            <Header className='site-sidebar-header text-nowrap px-4'>
                <div className='title'>中企団企業マッチング管理ページ</div>
            </Header>
            <Menu
                key={pathname}
                defaultSelectedKeys={selectedMenus}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                theme='dark'
                mode='inline'
                className='position-relative overflow-auto c-sidebar-nav'
            >
                <Menu.Item
                    className='mt-0 mb-0 text-wrap site-menu-item'
                    key='/dashboard'
                    onClick={() => setOpenKeys([])}
                    icon={<LayoutOutlined className='fs-6' />}
                >
                    <NavLink to='/dashboard'>ダッシュボード</NavLink>
                </Menu.Item>
                <SubMenu
                    className='text-wrap site-menu-item'
                    theme='light'
                    key='/management/accounts'
                    icon={<TeamOutlined className='fs-6' />}
                    title='専門家管理'
                >
                    <Menu.Item className='my-0' key='/accounts'>
                        <NavLink to='/accounts'>社労士管理</NavLink>
                    </Menu.Item>
                    <Menu.Item key='/diagnosticians'>
                        <NavLink to='/diagnosticians'>診断士管理</NavLink>
                    </Menu.Item>
                    <Menu.Item key='/email-management'>
                        <NavLink to='/email-management'>メール管理</NavLink>
                    </Menu.Item>
                    <Menu.Item key='/trash-management'>
                        <NavLink to='/trash-management'>ゴミ箱</NavLink>
                    </Menu.Item>
                    <Menu.Item className='my-0' key='/master-data-management'>
                        <NavLink to='/master-data-management'>マスターデータ</NavLink>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    className='text-wrap site-menu-item'
                    theme='light'
                    key='support-online'
                    icon={<FolderOpenOutlined className='fs-6' />}
                    title={`公的支援オンライン<br/>サービス`.split('<br/>').join('\n')}
                >
                    <Menu.Item className='my-0' key='/business-management'>
                        <NavLink to='/business-management' className=''>
                            企業管理
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key='/interview-management'>
                        <NavLink to='/interview-management' className=''>
                            面談管理
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key='/subsidy-management'>
                        <NavLink to='/subsidy-management' className=''>
                            補助金管理
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key='/grant-management'>
                        <NavLink to='/grant-management' className=''>
                            助成金管理
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key='/loan-management'>
                        <NavLink to='/loan-management' className=''>
                            融資管理
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item className='my-0' key='/email-notification'>
                        <NavLink to='/email-notification'>メールテンプレート管理</NavLink>
                    </Menu.Item>
                    <SubMenu key='master-data-subsidy-grand-management' title='マスタデータ管理' className='ant-menu-submenu-item'>
                        <Menu.Item key='/master-data-subsidy-management'>
                            <NavLink to='/master-data-subsidy-management'>助成金・補助金</NavLink>
                        </Menu.Item>
                        <Menu.Item key='/master-data-grant-management'>
                            <NavLink to='/master-data-grant-management'>融資</NavLink>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key='/setting'>
                        <NavLink to='/setting'>マッチング期限の設定</NavLink>
                    </Menu.Item>
                    <Menu.Item key='/export'>
                        <NavLink to='/export'>エクスポート</NavLink>
                    </Menu.Item>
                    <Menu.Item className='my-0' key='/trash-management-notification'>
                        <NavLink to='/trash-management-notification'>ゴミ箱</NavLink>
                    </Menu.Item>
                    <Menu.Item className='my-0' key='/notification'>
                        <NavLink to='/notification'>通知管理</NavLink>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    className='text-wrap site-menu-item'
                    theme='light'
                    key='diagnosi-online'
                    icon={<FileTextOutlined className='fs-6' />}
                    title={`就業規則診断オンライン<br/>サービス`.split('<br/>').join('\n')}
                >
                    <Menu.Item className='my-0' key='/company'>
                        <NavLink to='/company'>企業管理</NavLink>
                    </Menu.Item>
                    <Menu.Item key='/notification-content'>
                        <NavLink to='/notification-content'>通知管理</NavLink>
                    </Menu.Item>
                    <Menu.Item key='/diagnosis-email'>
                        <NavLink to='/diagnosis-email'>メールテンプレート管理</NavLink>
                    </Menu.Item>
                    <Menu.Item key='/export-diagnosis'>
                        <NavLink to='/export-diagnosis'>エクスポート</NavLink>
                    </Menu.Item>
                </SubMenu>
                <SubMenu 
                    key='search-data-management' 
                    title='データ集計'  
                    icon={<AreaChartOutlined className='fs-6' />}
                    theme='light'
                    className='text-wrap site-menu-item'
                >
                    <Menu.Item key='/search-data-management'>
                        <NavLink to='/search-data-management'>検索データ</NavLink>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        </Sider>
    );
};

export default Sidebar;
