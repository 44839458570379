import 'assets/styles/app.scss';
import 'moment/locale/ja';

import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from 'pages/Login';
import { fetchUserData, selectAuth } from 'app/slice/authSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Loader from 'components/common/Loader';
import BaseLayout from 'components/common/BaseLayout';
import TrashManagement from 'pages/Trash';
import SuccessModal from 'components/common/SuccessModal';

const MasterDataManagement = lazy(() => import('pages/MasterData'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const ProfileEdit = lazy(() => import('pages/Profiles/ProfileEdit'));
const Profiles = lazy(() => import('pages/Profiles'));
const Accounts = lazy(() => import('pages/Accounts'));
const AccountApproveDetail = lazy(() => import('pages/Accounts/AccountApproveDetail'));
const AccountDetailLayout = lazy(() => import('pages/Accounts/AccountDetailLayout'));
const EditAccountApprove = lazy(() => import('pages/Accounts/EditAccountApprove'));
const DetailAccountApprove = lazy(() => import('pages/Accounts/DetailAccountApprove'));
const AccountRegistration = lazy(() => import('pages/Accounts/AccountRegistration'));
const DiagnosticianRegistration = lazy(() => import('pages/Diagnosticians/DiagnosticianRegistration'));
const Diagnosticians = lazy(() => import('pages/Diagnosticians'));
const DiagnosticianApproveDetail = lazy(() => import('pages/Diagnosticians/DiagnosticianApproveDetail'));
const BusinessManagement = lazy(() => import('pages/BusinessManagement'));
const EmailManagement = lazy(() => import('pages/Email'));
const EditTemplateEmail = lazy(() => import('pages/Email/EditTemplateEmail'));
const BusinessDetail = lazy(() => import('pages/BusinessManagement/BusinessDetail'));
const Interview = lazy(() => import('pages/Interview'));
const Loan = lazy(() => import('pages/Loan'));
const LoanDetail = lazy(() => import('pages/Loan/LoanDetail'));
const LoanEdit = lazy(() => import('pages/Loan/LoanEdit'));
const LoanAddition = lazy(() => import('pages/Loan/LoanAddition'));
const Subsidy = lazy(() => import('pages/Subsidy'));
const SubsidyDetail = lazy(() => import('pages/Subsidy/SubsidyDetail'));
const SubsidyAddition = lazy(() => import('pages/Subsidy/SubsidyAddition'));
const SubsidyEdit = lazy(() => import('pages/Subsidy/SubsidyEdit'));
const Grant = lazy(() => import('pages/Grant'));
const GrantDetail = lazy(() => import('pages/Grant/GrantDetail'));
const GrantEdit = lazy(() => import('pages/Grant/GrantEdit'));
const GrantAddition = lazy(() => import('pages/Grant/GrantAddition'));
const MasterDataSubsidy = lazy(() => import('pages/MasterDataSubsidy'));
const MasterDataGrant = lazy(() => import('pages/MasterDataGrant'));
const AppointmentDetail = lazy(() => import('pages/BusinessManagement/AppointmentDetail'));
const ConsultationDetails = lazy(() => import('pages/BusinessManagement/ConsultationDetails'));
const DiagnosticianDetailLayout = lazy(() => import('pages/Diagnosticians/DiagnosticianDetailLayout'));
const DetailDiagnostician = lazy(() => import('pages/Diagnosticians/DetailDiagnostician'));
const EditDiagnostician = lazy(() => import('pages/Diagnosticians/EditDiagnostician'));
const Notification = lazy(() => import('pages/Notification'));
const NotificationDetail = lazy(() => import('pages/Notification/NotificationDetail'));
const NotificationEdit = lazy(() => import('pages/Notification/NotificationEdit'));
const NotificationAddition = lazy(() => import('pages/Notification/NotificationAddition'));
const EmailNotification = lazy(() => import('pages/EmailNotification'));
const EmailNotificationDetail = lazy(() => import('pages/EmailNotification/EmailNotificationDetail'));
const NotificationContent = lazy(() => import('pages/NotificationContent'));
const TrashManagementNotification = lazy(() => import('pages/TrashManagementNotification'));
const DiagnosisEmail = lazy(() => import('pages/EmailDiagnosis'));
const EditDiagnosisEmail = lazy(() => import('pages/EmailDiagnosis/EditDiagnosisEmail'));
const ManageCompany = lazy(() => import('pages/CompanyDetails'));
const CompanyDetail = lazy(() => import('pages/CompanyDetails/DetailCompany'));
const NotificationDetailContent = lazy(() => import('pages/NotificationContent/NotificationDetailContent'));
const NotificationEditContent = lazy(() => import('pages/NotificationContent/NotificationEditContent'));
const NotificationAddContent = lazy(() => import('pages/NotificationContent/NotificationAddContent'));
const CategoryList = lazy(() => import('pages/NotificationContent/ManagementCategoryList'));
const SearchData = lazy(() => import('pages/SearchData'));
const Export = lazy(() => import('pages/Export'));
const ExportDiagnosis = lazy(() => import('pages/ExportDiagnosis'));
const Setting = lazy(() => import('pages/Setting'));
const ApplicationDetail = lazy(() => import('pages/ApplicationDetail'));

const App = () => {
    const dispatch = useAppDispatch();
    const { token } = useAppSelector(selectAuth);

    useEffect(() => {
        if (token) {
            dispatch(fetchUserData());
        }
    }, [token, dispatch]);

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader force />}>
                <Routes>
                    <Route path='/' element={<BaseLayout />}>
                        <Route path='/dashboard' element={<Dashboard />} />
                        <Route path='detail-profile' element={<Profiles />}>
                            <Route path='edit' element={<ProfileEdit />} />
                        </Route>
                        <Route path='accounts' element={<Accounts />}>
                            <Route path=':uuid' element={<AccountDetailLayout />}>
                                <Route index element={<DetailAccountApprove />} />
                                <Route path='edit' element={<EditAccountApprove />} />
                            </Route>
                            <Route path=':userId/:companyId/detail/:applicationId' element={<AppointmentDetail />} />
                            <Route path=':userId/:companyId/working-regulation/:applicationId'
                                   element={<ApplicationDetail />}
                            />
                            <Route path='approve-detail/:uuid' element={<AccountApproveDetail />} />
                        </Route>
                        <Route path='diagnosticians' element={<Diagnosticians />}>
                            <Route path=':uuid' element={<DiagnosticianDetailLayout />}>
                                <Route index element={<DetailDiagnostician />} />
                                <Route path='edit' element={<EditDiagnostician />} />
                            </Route>
                            <Route path=':userId/:companyId/detail/:applicationId' element={<AppointmentDetail />} />
                            <Route path=':userId/:companyId/working-regulation/:applicationId'
                                   element={<ApplicationDetail />}
                            />
                            <Route path='approve-detail/:uuid' element={<DiagnosticianApproveDetail />} />
                        </Route>
                        <Route path='email-management' element={<EmailManagement />}>
                            <Route path='email-details/:uuid' element={<EditTemplateEmail />} />
                        </Route>
                        <Route path='/trash-management' element={<TrashManagement />} />
                        <Route path='/master-data-management' element={<MasterDataManagement />} />
                        <Route path='business-management' element={<BusinessManagement />}>
                            <Route path=':uuid' element={<BusinessDetail />}>
                                <Route path=':applicationId/appointment-detail'>
                                    <Route path=':specialistUuid/schedule' element={<ConsultationDetails />} />
                                    <Route index element={<ConsultationDetails />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path='interview-management' element={<Interview />}>
                            <Route path=':uuid/:applicationId/detail'>
                                <Route index element={<ConsultationDetails />} />
                                <Route path=':specialistUuid/schedule' element={<ConsultationDetails />} />
                            </Route>
                        </Route>

                        <Route path='loan-management' element={<Loan />}>
                            <Route path=':uuid' element={<LoanDetail />} />
                            <Route path=':uuid/edit' element={<LoanEdit />} />
                            <Route path='register' element={<LoanAddition />} />
                        </Route>
                        <Route path='notification' element={<Notification />}>
                            <Route path=':uuid' element={<NotificationDetail />} />
                            <Route path=':uuid/edit' element={<NotificationEdit />} />
                            <Route path='register' element={<NotificationAddition />} />
                        </Route>
                        <Route path='subsidy-management' element={<Subsidy />}>
                            <Route path=':uuid' element={<SubsidyDetail />} />
                            <Route path='register' element={<SubsidyAddition />} />
                            <Route path=':uuid/edit' element={<SubsidyEdit />} />
                        </Route>
                        <Route path='grant-management' element={<Grant />}>
                            <Route path='register' element={<GrantAddition />} />
                            <Route path=':uuid' element={<GrantDetail />} />
                            <Route path=':uuid/edit' element={<GrantEdit />} />
                        </Route>
                        <Route path='master-data-subsidy-management' element={<MasterDataSubsidy />} />
                        <Route path='master-data-grant-management' element={<MasterDataGrant />} />
                        <Route path='email-notification' element={<EmailNotification />}>
                            <Route path='email-detail/:uuid' element={<EmailNotificationDetail />} />
                        </Route>
                        <Route path='/notification-content' element={<NotificationContent />}>
                            <Route path='detail' element={<NotificationDetailContent />} />
                            <Route path='edit' element={<NotificationEditContent />} />
                            <Route path='register' element={<NotificationAddContent />} />
                            <Route path='category' element={<CategoryList />} />
                        </Route>
                        <Route path='company' element={<ManageCompany />}>
                            <Route path=':uuid' element={<CompanyDetail />}>
                                <Route path=':applicationId/exchange-detail' element={<AppointmentDetail />} />
                                <Route path='detail/:applicationId' element={<ApplicationDetail />} />
                            </Route>
                        </Route>
                        <Route path='trash-management-notification' element={<TrashManagementNotification />} />

                        <Route path='/diagnosis-email' element={<DiagnosisEmail />}>
                            <Route path='diagnosis-email-detail/:uuid' element={<EditDiagnosisEmail />} />
                        </Route>
                        <Route path='/search-data-management' element={<SearchData />} />
                        <Route path='/export' element={<Export />} />
                        <Route path='/export-diagnosis' element={<ExportDiagnosis />} />
                        <Route path='/setting' element={<Setting />} />
                    </Route>
                    <Route path='/account/registration' element={<AccountRegistration />} />
                    <Route path='/diagnostician/registration' element={<DiagnosticianRegistration />} />
                    <Route path='/login' element={<LoginPage />} />
                </Routes>
            </Suspense>
            <SuccessModal />
        </BrowserRouter>
    );
};

export default App;
