import {
    DaidoEmployee,
    FetchCompanyRulesRequest,
    ImportSubsidyRequest,
    InterviewResultRequest,
    RegisterSubsidyRequest,
    UpdateSubsidyRequest,
} from 'types';
import { getAxiosClientWithToken } from 'api';
import { handleFormData } from 'lib/utils';

export const getCompanyFile = ({ uuid, page }: { uuid?: string; page: number }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/companies/${uuid}/files`, {
        params: { page },
    });
};

export const getInfoCompany = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/companies/${uuid}`);
};

export const getCompanyRules = (params: FetchCompanyRulesRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/companies', { params });
};

export const getDetailExchange = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/companies/topic/${uuid}`);
};

export const updateStatusCompany = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.put(`/admin/companies/${uuid}/change-status`);
};

export const destroyCompany = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/companies/${uuid}`);
};

export const registerSubsidy = (params: RegisterSubsidyRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/funds', handleFormData(params));
};

export const updateSubsidy = (params: UpdateSubsidyRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/funds/${params.fundId}`, handleFormData({ ...params, fundId: undefined }));
};

export const importSubsidy = (params: ImportSubsidyRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('admin/funds/import', handleFormData(params));
};

export const personCharge = ({ employeeId, name }: DaidoEmployee) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/companies/${employeeId}/update-person-in-charge`, {
        _method: 'PUT',
        name,
    });
};

export const exchangeResultInterview = ({ employeeId, interview_result }: InterviewResultRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/companies/${employeeId}/update-interview-result`, {
        interview_result,
        _method: 'PUT',
    });
};

export const exportCompanies = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/companies/export', {
        responseType: 'arraybuffer',
    });
};
