import { getAxiosClientWithToken } from 'api';
import { MasterDataGrantRequest } from 'app/slice/masterDataGrantSlide';

export const getCapitalOrInvestment = (params: MasterDataGrantRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/capital-ranges', { params });
};

export const getBusinessScale = (params: MasterDataGrantRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/search-employee-count-ranges', { params });
};

export const getYearsEstablished = (params: MasterDataGrantRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/years-established-ranges', { params });
};

export const getIndustries = (params: MasterDataGrantRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/search-industries', { params });
};

export const getIndividualOrCorporation = (params: MasterDataGrantRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/coverage-types', { params });
};
