import React from 'react';

type Props = {
    force?: boolean;
};

const Loader = (props: Props) => {
    const { force } = props;

    const opacity = force ? 1 : 0;

    return <div className='loader' style={{ opacity }} />;
};

export default Loader;
