import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import { deleteTrash, getBusinessConsultingTrash, restoreTrash } from 'api/traskManagement';

import { RootState } from 'app/store';
import { TraskManagement, TrashParams, RestoreTrashManagement } from 'types';

/**
 * GET Trash Data Management
 **/
export const fetchTrashData = createAsyncThunk('/admin/users/consulting', async (params: TrashParams, { dispatch, rejectWithValue }) => {
    try {
        const response = await getBusinessConsultingTrash(params);
        const { success } = response.data;

        if (success) {
            dispatch(setTraskDataManagement(response.data));
            return success;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

/**
 * GET Trash Data Business
 **/
export const fetchTrashBusiness = createAsyncThunk('/admin/users/business', async (params: TrashParams, { dispatch, rejectWithValue }) => {
    try {
        const response = await getBusinessConsultingTrash(params);
        const { success } = response.data;

        if (success) {
            dispatch(setTraskDataBusiness(response.data));
            return success;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

/**
 * POST Restore Trash
 **/
export const restoreTrashManagement = createAsyncThunk(
    'admin/users/:userId/restore',
    async (userId: number, { dispatch, rejectWithValue }) => {
        try {
            const response = await restoreTrash(userId);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * POST Delete Trash
 **/
export const deleteTrashManagement = createAsyncThunk(
    'admin/user/:userId/delete',
    async (userId: number, { dispatch, rejectWithValue }) => {
        try {
            const response = await deleteTrash(userId);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

const initialState = {
    trashData: {
        loading: false,
        error: false,
        success: false,
    } as TraskManagement,
    trashBusiness: {
        loading: false,
        error: false,
        success: false,
    } as TraskManagement,
    restoreTrashState: {
        success: false,
        message: '',
        loading: false,
        error: false,
    } as RestoreTrashManagement,
    deleteTrashState: {
        success: false,
        message: '',
        loading: false,
        error: false,
    } as RestoreTrashManagement,
};

export const traskManagementSlice = createSlice({
    name: 'trashData',
    initialState: initialState,
    reducers: {
        setTraskDataManagement: (state, action) => {
            state.trashData = action?.payload ?? { data: [] };
        },
        setTraskDataBusiness: (state, action) => {
            state.trashBusiness = action?.payload ?? { data: [] };
        },
        resetRestore: (state) => {
            state.restoreTrashState = {
                success: false,
                message: '',
                loading: false,
                error: false,
            } as RestoreTrashManagement;
        },
        resetDelete: (state) => {
            state.deleteTrashState = {
                success: false,
                message: '',
                loading: false,
                error: false,
            } as RestoreTrashManagement;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTrashData.pending, (state) => {
                state.trashData.loading = true;
            })
            .addCase(fetchTrashData.fulfilled, (state) => {
                state.trashData.loading = false;
                state.trashData.success = true;
            })
            .addCase(fetchTrashData.rejected, (state) => {
                state.trashData.loading = false;
                state.trashData.success = false;
                state.trashData.error = true;
            })

            .addCase(fetchTrashBusiness.pending, (state) => {
                state.trashBusiness.loading = true;
            })
            .addCase(fetchTrashBusiness.fulfilled, (state) => {
                state.trashBusiness.loading = false;
                state.trashBusiness.success = true;
            })
            .addCase(fetchTrashBusiness.rejected, (state) => {
                state.trashBusiness.loading = false;
                state.trashBusiness.success = false;
                state.trashBusiness.error = true;
            })


            .addCase(restoreTrashManagement.pending, (state) => {
                state.restoreTrashState.loading = true;
            })
            .addCase(restoreTrashManagement.fulfilled, (state) => {
                state.restoreTrashState.success = true;
                state.restoreTrashState.loading = false;
            })
            .addCase(restoreTrashManagement.rejected, (state, action) => {
                state.restoreTrashState = {
                    success: false,
                    message: action.payload as string,
                    loading: false,
                    error: true,
                };
            })

            .addCase(deleteTrashManagement.pending, (state: { deleteTrashState: RestoreTrashManagement }, action) => {
                state.deleteTrashState.loading = true;
            })
            .addCase(deleteTrashManagement.rejected, (state: { deleteTrashState: RestoreTrashManagement }, action) => {
                state.deleteTrashState = {
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string,
                };
            })
            .addCase(deleteTrashManagement.fulfilled, (state: { deleteTrashState: RestoreTrashManagement }, action) => {
                state.deleteTrashState.success = true;
                state.deleteTrashState.loading = false;
            })
    },
});

export const { setTraskDataManagement, setTraskDataBusiness, resetRestore, resetDelete } = traskManagementSlice.actions;
export const trashManagementSelector = (state: RootState) => state.trashData;
