import { EditEmailTemplateRequest, EmailParams } from 'types';
import { getAxiosClientWithToken } from './index';
import { handleFormData } from '../lib/utils';

export const getEmailTemplateManagement = (params: EmailParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/mail-templates', { params });
};

export const getDetailEmailTemplate = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/mail-templates/${uuid}`);
};

export const updateEmailTemplate = (params: EditEmailTemplateRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/mail-templates/${params.uuid}`, formData);
};
