import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
    getResourceIndustry,
    getResourceEmployeeCountRanges,
    getResourceFields,
    registerResourceIndustry,
    registerResourceEmployeeCountRanges,
    registerResourceFields,
    updateResourceIndustry,
    updateResourceFields,
    updateResourceEmployeeCountRanges,
    deleteResourceIndustry,
    deleteResourceFields,
    deleteResourceEmployeeCountRanges,
} from 'api/masterData';

import { RootState } from 'app/store';
import { FetchState, MasterParams } from 'types';
import { EmployeeCountRangeTabKey, FieldTabKey, IndustryTabKey } from 'config/constant';
import { getErrorMessage } from 'api';
import { mbTrim } from 'lib/utils';

/**
 * GET Master Data Management
 **/
export const fetchIndustries = createAsyncThunk('resource/industry', async (params: MasterParams, { dispatch, rejectWithValue }) => {
    try {
        const response = await getResourceIndustry(params);
        const { data = [], success } = response.data;

        if (success) {
            dispatch(setIndustryData(data));
            return true;
        }

        return rejectWithValue(false);
    } catch (err) {
        return rejectWithValue(false);
    }
});

/**
 * GET Master Data Size Industry
 **/
export const fetchEmployeeCountRanges = createAsyncThunk(
    'resource/employee-count-range',
    async (params: MasterParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await getResourceEmployeeCountRanges(params);
            const { data = [], success } = response.data;

            if (success) {
                dispatch(setEmployeeCountRangeData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (err) {
            return rejectWithValue(false);
        }
    }
);

/**
 * GET Master Data Specialty
 **/
export const fetchResourceFields = createAsyncThunk('resource/field', async (params: MasterParams, { dispatch, rejectWithValue }) => {
    try {
        const response = await getResourceFields(params);
        const { data = [], success } = response.data;

        if (success) {
            dispatch(setFieldData(data));
            return true;
        }

        return rejectWithValue(false);
    } catch (err) {
        return rejectWithValue(false);
    }
});

export const asyncRegisterMasterData = createAsyncThunk(
    'admin/register/master-data',
    async (params: RegisterMasterData, { dispatch, rejectWithValue }) => {
        try {
            let response;
            params.code = mbTrim(params.code);
            params.name = mbTrim(params.name);
            switch (params.type) {
                case IndustryTabKey:
                    response = await registerResourceIndustry(params);
                    break;
                case EmployeeCountRangeTabKey:
                    response = await registerResourceEmployeeCountRanges(params);
                    break;
                case FieldTabKey:
                    params.is_sharoushi = params.is_sharoushi ? 1 : 0;
                    params.is_shindanshi = params.is_shindanshi ? 1 : 0;
                    response = await registerResourceFields(params);
                    break;
                default:
                    return rejectWithValue(false);
            }
            const { success = false } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (err: any) {
            return rejectWithValue(getErrorMessage(err));
        }
    }
);

export const asyncUpdateMasterData = createAsyncThunk(
    'admin/update/master-data',
    async (params: UpdateMasterData, { dispatch, rejectWithValue }) => {
        try {
            let response;
            params.code = mbTrim(params.code);
            params.name = mbTrim(params.name);
            params._method = 'PUT';
            switch (params.type) {
                case IndustryTabKey:
                    response = await updateResourceIndustry(params);
                    break;
                case EmployeeCountRangeTabKey:
                    response = await updateResourceEmployeeCountRanges(params);
                    break;
                case FieldTabKey:
                    params.is_sharoushi = params.is_sharoushi ? 1 : 0;
                    params.is_shindanshi = params.is_shindanshi ? 1 : 0;
                    response = await updateResourceFields(params);
                    break;
                default:
                    return rejectWithValue(false);
            }
            const { success = false } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (err: any) {
            return rejectWithValue(getErrorMessage(err));
        }
    }
);

export const asyncDeleteMasterData = createAsyncThunk(
    'admin/delete/master-data',
    async (params: DeleteMasterData, { dispatch, rejectWithValue }) => {
        try {
            let response;
            switch (params.type) {
                case IndustryTabKey:
                    response = await deleteResourceIndustry(params);
                    break;
                case EmployeeCountRangeTabKey:
                    response = await deleteResourceEmployeeCountRanges(params);
                    break;
                case FieldTabKey:
                    response = await deleteResourceFields(params);
                    break;
                default:
                    return rejectWithValue(false);
            }
            const { success = false } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (err: any) {
            return rejectWithValue(getErrorMessage(err));
        }
    }
);

type MasterDataState = {
    fetchState: FetchState;
    registerState: StoreState;
    updateState: StoreState;
    deleteState: DeleteState;
    industries: BasicMasterData[];
    employeeCountRanges: BasicMasterData[];
    fields: BasicMasterData[];
};

interface StoreState extends FetchState {
    messages: string | ValidateState;
}

interface DeleteState extends FetchState {
    message: string;
}

export type ValidateState = {
    name: string | string[];
    code: string | string[];
};

export type BasicMasterData = {
    id: number;
    code: string;
    name: string;
    is_sharoushi?: MasterDataType,
    is_shindanshi?: MasterDataType,
};

export type MasterDataTabKey = '1' | '2' | '3';

export type MasterDataType = 1 | 0;

export type RegisterMasterData = {
    code: string;
    name: string;
    type: MasterDataTabKey;
    is_sharoushi?: MasterDataType,
    is_shindanshi?: MasterDataType,
};

export type UpdateMasterData = {
    code: string;
    name: string;
    type: MasterDataTabKey;
    _method?: 'PUT';
    is_sharoushi?: MasterDataType,
    is_shindanshi?: MasterDataType,
};

export type DeleteMasterData = {
    code: string;
    type: MasterDataTabKey;
};

const initialState = {
    fetchState: {
        loading: false,
        success: false,
        error: false,
    },
    registerState: {
        loading: false,
        success: false,
        error: false,
        messages: '',
    },
    updateState: {
        loading: false,
        success: false,
        error: false,
        messages: '',
    },
    deleteState: {
        loading: false,
        success: false,
        error: false,
        message: '',
    },
    industries: [],
    employeeCountRanges: [],
    fields: [],
} as MasterDataState;

export const masterManagementSlice = createSlice({
    name: 'masterData',
    initialState: initialState,
    reducers: {
        setIndustryData: (state: MasterDataState, action) => {
            state.industries = action.payload;
        },
        setEmployeeCountRangeData: (state: MasterDataState, action) => {
            state.employeeCountRanges = action.payload;
        },
        setFieldData: (state: MasterDataState, action) => {
            state.fields = action.payload;
        },
        resetRegisterState: (state: MasterDataState) => {
            state.registerState = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        resetUpdateState: (state: MasterDataState) => {
            state.updateState = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        resetDeleteState: (state: MasterDataState) => {
            state.deleteState = {
                loading: false,
                success: false,
                error: false,
                message: '',
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(asyncRegisterMasterData.pending, (state: MasterDataState) => {
                state.registerState.loading = true;
            })
            .addCase(asyncRegisterMasterData.rejected, (state: MasterDataState, { payload }) => {
                state.registerState.loading = false;
                state.registerState.success = false;
                state.registerState.error = true;
                state.registerState.messages = payload as string | ValidateState;
            })
            .addCase(asyncRegisterMasterData.fulfilled, (state: MasterDataState) => {
                state.registerState.loading = false;
                state.registerState.success = true;
                state.registerState.error = false;
            })
            .addCase(asyncUpdateMasterData.pending, (state: MasterDataState) => {
                state.updateState.loading = true;
            })
            .addCase(asyncUpdateMasterData.rejected, (state: MasterDataState, { payload }) => {
                state.updateState.loading = false;
                state.updateState.success = false;
                state.updateState.error = true;
                state.updateState.messages = payload as string | ValidateState;
            })
            .addCase(asyncUpdateMasterData.fulfilled, (state: MasterDataState) => {
                state.updateState.loading = false;
                state.updateState.success = true;
                state.updateState.error = false;
            })
            .addCase(asyncDeleteMasterData.pending, (state: MasterDataState) => {
                state.deleteState.loading = true;
            })
            .addCase(asyncDeleteMasterData.rejected, (state: MasterDataState, { payload }) => {
                state.deleteState.loading = false;
                state.deleteState.success = false;
                state.deleteState.error = true;
                state.deleteState.message = payload as string;
            })
            .addCase(asyncDeleteMasterData.fulfilled, (state: MasterDataState) => {
                state.deleteState.loading = false;
                state.deleteState.success = true;
                state.deleteState.error = false;
            })
            .addMatcher(
                isAnyOf(fetchIndustries.pending, fetchEmployeeCountRanges.pending, fetchResourceFields.pending),
                (state: MasterDataState) => {
                    state.fetchState.loading = true;
                }
            )
            .addMatcher(
                isAnyOf(fetchIndustries.rejected, fetchEmployeeCountRanges.rejected, fetchResourceFields.rejected),
                (state: MasterDataState) => {
                    state.fetchState.loading = false;
                    state.fetchState.success = false;
                    state.fetchState.error = true;
                    state.industries = [];
                    state.employeeCountRanges = [];
                    state.fields = [];
                }
            )
            .addMatcher(
                isAnyOf(fetchIndustries.fulfilled, fetchEmployeeCountRanges.fulfilled, fetchResourceFields.fulfilled),
                (state: MasterDataState) => {
                    state.fetchState.loading = false;
                    state.fetchState.success = true;
                    state.fetchState.error = false;
                }
            );
    },
});

export const { setIndustryData, setEmployeeCountRangeData, setFieldData, resetRegisterState, resetUpdateState, resetDeleteState } =
    masterManagementSlice.actions;
export const masterManagementSelector = (state: RootState) => state.masterData;
