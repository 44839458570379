import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getPrefectureJp } from 'api/prefecture';

/**
 * GET prefecture Jp
 **/
export const getUserScheduledMeetings = createAsyncThunk('resource/prefecture', async (data, { dispatch, rejectWithValue }) => {
    try {
        const response = await getPrefectureJp();
        const { data = [], success } = response.data;
        dispatch(setPrefectureJp(data));
        return success;
    } catch (err) {
        return rejectWithValue(false);
    }
});

export type PrefectureJpState = {
    id: string;
    code: string;
    name: string;
};

const initialState = {
    prefecture: [] as PrefectureJpState[],
};

export const prefectureJpSlice = createSlice({
    name: 'prefectures',
    initialState: initialState,
    reducers: {
        setPrefectureJp: (state, { payload }) => {
            state.prefecture = payload;
        },
    },
});

export const { setPrefectureJp } = prefectureJpSlice.actions;
export const selectPrefectureJp = (state: RootState) => state.prefecture;
