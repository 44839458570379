import { ChangeInterview, ExchangeParams, FetchCompanyRulesRequest, ListSearch, ReasonParams } from 'types';
import { getAxiosClientWithToken } from 'api';
import { CompanyMeetingRequest } from 'app/slice/businessSlice';
import { handleFormData } from 'lib/utils';

export const getListInterviewData = ({ status, keyword, uuid, page }: CompanyMeetingRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/companies/${uuid}/meetings`, { params: { keyword, status, page } });
};

export const getCompanyManagementList = (params: FetchCompanyRulesRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/companies', { params });
};

export const getCompanyProfile = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/companies/${uuid}`);
};

export const getDetailExchange = (params: ExchangeParams) => {
    const { companyId, applicationId, userId } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/companies/${companyId}/${applicationId}`, { params: { userId } });
};

export const postCancelInterview = (params: ReasonParams) => {
    const { reason, applicationId } = params;

    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/companies/${applicationId}/update`, handleFormData({ reason }));
};

export const postInterviewSchedule = ({
    companies,
    applicationId,
    schedules,
    schedule,
    start_time,
    daido_joins,
    specialist_id,
}: ChangeInterview) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/companies/${companies}/applications/${applicationId}/schedules/${schedules}`, {
        _method: 'PUT',
        schedule,
        start_time,
        daido_joins,
        specialist_id,
    });
};

export const getSpecialSearch = (params: ListSearch) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/specialists/search', { params });
};
