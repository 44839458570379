import { Button, Modal } from 'antd';
import { closeSuccessModal, successModalSelector } from 'app/slice/successSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty, isNumber, isString } from 'lodash';
import classNames from 'classnames';
import { isValidElement } from 'react';

/**
 * Component used for all Success Modal
 * @return html
 **/
const SuccessModal = () => {
    const navigate = useNavigate();
    const {
        image = '',
        title,
        message,
        withButton,
        isShow,
        buttonLink,
        buttonTitle,
        onCancel,
        centered,
        onButtonPress,
        modalClassname,
    } = useAppSelector(successModalSelector);
    const dispatch = useAppDispatch();
    const isShowButtonConfirm = withButton && (!isEmpty(buttonLink) || isNumber(buttonLink));

    const onClose = () => {
        onCancel?.();
        dispatch(closeSuccessModal());
    };

    const onCancelModal = () => {
        onClose();
    };

    const onConfirm = () => {
        onClose();

        onButtonPress?.();

        isNumber(buttonLink) && navigate(buttonLink);
        !isEmpty(buttonLink) && isString(buttonLink) && navigate(buttonLink);
    };

    return (
        <Modal
            className={classNames('text-center success-modal', modalClassname)}
            width={572}
            centered={centered}
            visible={isShow}
            onCancel={onCancelModal}
            maskClosable={false}
            keyboard={false}
            footer={null}
        >
            <div className='title'>{title}</div>
            {image && <img src={image} alt='chukidan-icon' className='mx-3' />}
            {isValidElement(message) ? message : <div className={classNames({ 'mb-5': !isShowButtonConfirm }, 'message')}>{message}</div>}

            {withButton && (
                <Button onClick={onConfirm} type='primary' className='mt-3 w-75'>
                    {buttonTitle}
                </Button>
            )}
        </Modal>
    );
};

export default SuccessModal;
