import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { MoneyDetail, MoneyInfo, FetchState, RegisterSubsidyRequest, UpdateSubsidyRequest, ImportSubsidyRequest } from 'types';
import { getErrorMessage } from 'api';
import { downloadCSV, getSubsidies, getSubsidyDetail } from 'api/subsidies';
import { importSubsidy, registerSubsidy, updateSubsidy } from 'api/companies';
import { FUND_TYPE_SUBSIDY } from 'config/constant';
import moment from 'moment';

export type SubsidiesData = {
    subsidies: {
        loading: boolean;
        success: boolean;
        total: number;
        per_page: number;
        current_page: number;
        lastPage: number;
        subsidyData: MoneyInfo[];
    };
    subsidyDrafts: {
        loading: boolean;
        success: boolean;
        total: number;
        per_page: number;
        current_page: number;
        lastPage: number;
        subsidyDraftData: MoneyInfo[];
    };
    detail: {
        loading: boolean;
        success: boolean;
        error: boolean;
        subsidyDetail: MoneyDetail;
    };
    registerSubsidy: RegisterSubsidyState;
    updateSubsidy: UpdateSubsidyState;
    importSubsidy: ImportSubsidyState;
    downloadCSVData: string;
};

export type SubsidyManagementRequest = {
    column?: OptionFilterSubsidyManagement;
    keyword?: string;
    page?: number;
    type?: number;
    is_draft?: 0 | 1;
};

export type DownloadCSVRequest = {
    column?: OptionFilterSubsidyManagement;
    keyword?: string;
    type?: number;
    is_draft?: 0 | 1;
};

interface RegisterSubsidyState extends FetchState {
    messages: string | object;
}

interface UpdateSubsidyState extends FetchState {
    messages: string | object;
}

interface ImportSubsidyState extends FetchState {
    messages: string | object;
}

export type OptionFilterSubsidyManagement = 'code' | 'title' | 'overview' | 'target_groups' | 'amount' | 'application_period';

export const fetchSubsidies = createAsyncThunk(
    'admin/funds/subsidy/list',
    async (params: SubsidyManagementRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSubsidies(params);
            dispatch(setSubsidies(response?.data));
            return response?.data?.success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchSubsidyDrafts = createAsyncThunk(
    'admin/funds/subsidy/drafts',
    async (params: SubsidyManagementRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSubsidies(params);
            dispatch(setSubsidyDrafts(response?.data));
            return response?.data?.success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchSubsidyDetail = createAsyncThunk(
    'admin/funds/subsidy/:uuid',
    async (params: { uuid?: string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSubsidyDetail(params.uuid);
            const { data = {}, success } = response.data;

            if (success) {
                dispatch(setSubsidyDetail(data));

                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const asyncRegisterSubsidy = createAsyncThunk(
    '/admins/subsidies/register',
    async (params: RegisterSubsidyRequest, { dispatch, rejectWithValue }) => {
        try {
            params.type = FUND_TYPE_SUBSIDY;
            params.updated_at = params?.updated_at ? moment(params?.updated_at, 'YYYY/MM/DD').format('YYYY/MM/DD') : '';
            const response = await registerSubsidy(params);
            return response.data?.success ?? false;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const asyncUpdateSubsidy = createAsyncThunk(
    '/admins/subsidies/update',
    async (params: UpdateSubsidyRequest, { dispatch, rejectWithValue }) => {
        try {
            params._method = 'PUT';
            params.updated_at = params?.updated_at ? moment(params?.updated_at, 'YYYY/MM/DD').format('YYYY/MM/DD') : '';
            const response = await updateSubsidy(params);
            return response.data?.success ?? false;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const asyncImportSubsidy = createAsyncThunk(
    '/admins/subsidies/import',
    async (params: ImportSubsidyRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await importSubsidy(params);
            return response.data?.success ?? false;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const downloadCSVSubsidy = createAsyncThunk(
    'admin/funds/export',
    async (params: DownloadCSVRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await downloadCSV(params);
            dispatch(setDownloadCSV(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const subsidiesManagementSlice = createSlice({
    name: 'subsidiesManagement',
    initialState: {
        subsidies: {
            loading: false,
            success: false,
            total: 0,
            per_page: 0,
            current_page: 1,
            lastPage: 0,
            subsidyData: [],
        },
        subsidyDrafts: {
            loading: false,
            success: false,
            total: 0,
            per_page: 0,
            current_page: 1,
            lastPage: 0,
            subsidyDraftData: [],
        },
        detail: {
            loading: false,
            success: false,
            error: false,
            subsidyDetail: {} as MoneyDetail,
        },
        registerSubsidy: {
            loading: false,
            success: false,
            error: false,
            messages: '',
        },
        updateSubsidy: {
            loading: false,
            success: false,
            error: false,
            messages: '',
        },
        importSubsidy: {
            loading: false,
            success: false,
            error: false,
            messages: '',
        },
        downloadCSVData: '',
    } as SubsidiesData,
    reducers: {
        setSubsidies: (state: SubsidiesData, { payload }) => {
            state.subsidies.total = payload.total;
            state.subsidies.per_page = payload.per_page;
            state.subsidies.current_page = payload.current_page;
            state.subsidies.lastPage = payload.last_page;
            state.subsidies.subsidyData = payload.data;
        },
        setSubsidyDrafts: (state: SubsidiesData, { payload }) => {
            state.subsidyDrafts.total = payload.total;
            state.subsidyDrafts.per_page = payload.per_page;
            state.subsidyDrafts.current_page = payload.current_page;
            state.subsidyDrafts.lastPage = payload.last_page;
            state.subsidyDrafts.subsidyDraftData = payload.data;
        },
        setSubsidyDetail: (state, { payload }) => {
            state.detail.subsidyDetail = payload;
        },
        resetSubsidyDetailState: (state) => {
            state.detail.loading = false;
            state.detail.success = false;
            state.detail.error = false;
            state.detail.subsidyDetail = {} as MoneyDetail;
        },
        resetRegisterSubsidyState: (state: SubsidiesData) => {
            state.registerSubsidy.loading = false;
            state.registerSubsidy.success = false;
            state.registerSubsidy.error = false;
            state.registerSubsidy.messages = '';
        },
        resetUpdateSubsidyState: (state: SubsidiesData) => {
            state.updateSubsidy.loading = false;
            state.updateSubsidy.success = false;
            state.updateSubsidy.error = false;
            state.updateSubsidy.messages = '';
        },
        resetImportSubsidyState: (state: SubsidiesData) => {
            state.importSubsidy.loading = false;
            state.importSubsidy.success = false;
            state.importSubsidy.error = false;
            state.importSubsidy.messages = '';
        },
        setDownloadCSV: (state, { payload }) => {
            state.downloadCSVData = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubsidyDetail.pending, (state: SubsidiesData) => {
                state.detail.loading = true;
            })
            .addCase(fetchSubsidyDetail.fulfilled, (state: SubsidiesData) => {
                state.detail.loading = false;
                state.detail.success = true;
            })
            .addCase(fetchSubsidyDetail.rejected, (state: SubsidiesData, { payload }) => {
                state.detail.loading = false;
                state.detail.success = false;
                state.detail.error = true;
            })
            .addCase(asyncRegisterSubsidy.pending, (state: SubsidiesData) => {
                state.registerSubsidy.loading = true;
            })
            .addCase(asyncRegisterSubsidy.fulfilled, (state: SubsidiesData) => {
                state.registerSubsidy.loading = false;
                state.registerSubsidy.success = true;
                state.registerSubsidy.error = false;
            })
            .addCase(asyncRegisterSubsidy.rejected, (state: SubsidiesData, { payload }) => {
                state.registerSubsidy.loading = false;
                state.registerSubsidy.success = false;
                state.registerSubsidy.error = true;
                state.registerSubsidy.messages = payload as string | object;
            })
            .addCase(asyncUpdateSubsidy.pending, (state: SubsidiesData) => {
                state.updateSubsidy.loading = true;
            })
            .addCase(asyncUpdateSubsidy.fulfilled, (state: SubsidiesData) => {
                state.updateSubsidy.loading = false;
                state.updateSubsidy.success = true;
                state.updateSubsidy.error = false;
            })
            .addCase(asyncUpdateSubsidy.rejected, (state: SubsidiesData, { payload }) => {
                state.updateSubsidy.loading = false;
                state.updateSubsidy.success = false;
                state.updateSubsidy.error = true;
                state.updateSubsidy.messages = payload as string | object;
            })
            .addCase(asyncImportSubsidy.pending, (state: SubsidiesData) => {
                state.importSubsidy.loading = true;
            })
            .addCase(asyncImportSubsidy.fulfilled, (state: SubsidiesData) => {
                state.importSubsidy.loading = false;
                state.importSubsidy.success = true;
                state.importSubsidy.error = false;
            })
            .addCase(asyncImportSubsidy.rejected, (state: SubsidiesData, { payload }) => {
                state.importSubsidy.loading = false;
                state.importSubsidy.success = false;
                state.importSubsidy.error = true;
                state.importSubsidy.messages = payload as string | object;
            })
            .addCase(fetchSubsidies.pending, (state: SubsidiesData) => {
                state.subsidies.loading = true;
            })
            .addCase(fetchSubsidies.fulfilled, (state: SubsidiesData) => {
                state.subsidies.loading = false;
                state.subsidies.success = true;
            })
            .addCase(fetchSubsidies.rejected, (state: SubsidiesData) => {
                state.subsidies.loading = false;
                state.subsidies.success = false;
            })
            .addCase(fetchSubsidyDrafts.pending, (state: SubsidiesData) => {
                state.subsidyDrafts.loading = true;
            })
            .addCase(fetchSubsidyDrafts.fulfilled, (state: SubsidiesData) => {
                state.subsidyDrafts.loading = false;
                state.subsidyDrafts.success = true;
            })
            .addCase(fetchSubsidyDrafts.rejected, (state: SubsidiesData) => {
                state.subsidyDrafts.loading = false;
                state.subsidyDrafts.success = false;
            });
    },
});

export const {
    setSubsidies,
    setSubsidyDetail,
    setSubsidyDrafts,
    resetSubsidyDetailState,
    resetRegisterSubsidyState,
    resetUpdateSubsidyState,
    resetImportSubsidyState,
    setDownloadCSV,
} = subsidiesManagementSlice.actions;
export const subsidiesManagementSelector = (state: RootState) => state.subsidiesManagement;
