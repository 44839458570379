import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { authSlice } from 'app/slice/authSlice';
import { loginSlice } from 'app/slice/loginSlice';
import { successModalSlice } from 'app/slice/successSlice';
import { prefectureJpSlice } from 'app/slice/prefectureSlice';
import { diagnosticianSlice } from 'app/slice/diagnosticianSlice';
import { userSlice } from 'app/slice/userSlice';
import { dashboardSlice } from 'app/slice/dashboardSlice';
import { diagnosticianDetailSlide } from 'app/slice/diagnosticianDetailSlide';
import { masterManagementSlice } from 'app/slice/masterDataSlice';
import { emailManagementSlice } from 'app/slice/emailTemplateSlice';
import { masterDataSubsidiesSlide } from 'app/slice/masterDataSubsidiesSlide';
import { masterDataGrantSlice } from 'app/slice/masterDataGrantSlide';
import { traskManagementSlice } from 'app/slice/traskSlice';
import { businessSlice } from 'app/slice/businessSlice';
import { companiesSlice } from 'app/slice/companiesSlice';
import { exchangeManagementSlice } from 'app/slice/exchangeSlice';
import { emailExpiredSlice } from 'app/slice/emailExpiredSLice';
import { subsidiesManagementSlice } from 'app/slice/subsidiesSlice';
import { grantsManagementSlice } from 'app/slice/grantsSlice';
import { loanManagementSlice } from 'app/slice/loanSlice';
import { notificationSlice } from 'app/slice/notificationSlice';
import { trashNotiManageSlice } from 'app/slice/trashNotificationSlice';
import { categoriesSlice } from 'app/slice/categoriesSlice';
import { settingSlice } from 'app/slice/settingSlice';
import { exportSlice } from 'app/slice/exportSlice';
import { applicationsSlice } from 'app/slice/applicationsSlice';
import { searchQuerySlice } from 'app/slice/searchQuerySlice';
import { searchLogSlice } from 'app/slice/searchLogSlice';
import { jurisdictionSlice } from 'app/slice/jurisdictionSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [''],
    blacklist: [''],
};

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['email', 'name', 'uuid', 'token'],
};

const reducers = combineReducers({
    auth: persistReducer(authPersistConfig, authSlice.reducer),
    login: loginSlice.reducer,
    successModal: successModalSlice.reducer,
    prefecture: prefectureJpSlice.reducer,
    diagnosticians: diagnosticianSlice.reducer,
    user: userSlice.reducer,
    dashboard: dashboardSlice.reducer,
    diagnosticianDetail: diagnosticianDetailSlide.reducer,
    masterData: masterManagementSlice.reducer,
    emailManagement: emailManagementSlice.reducer,
    grant: masterDataGrantSlice.reducer,
    subsidies: masterDataSubsidiesSlide.reducer,
    trashData: traskManagementSlice.reducer,
    business: businessSlice.reducer,
    companies: companiesSlice.reducer,
    exchange: exchangeManagementSlice.reducer,
    emailExpired: emailExpiredSlice.reducer,
    subsidiesManagement: subsidiesManagementSlice.reducer,
    grantsManagement: grantsManagementSlice.reducer,
    loanManagement: loanManagementSlice.reducer,
    notification: notificationSlice.reducer,
    trashNoti: trashNotiManageSlice.reducer,
    categories: categoriesSlice.reducer,
    settings: settingSlice.reducer,
    export: exportSlice.reducer,
    applications: applicationsSlice.reducer,
    searchQuery: searchQuerySlice.reducer,
    searchLog: searchLogSlice.reducer,
    jurisdiction: jurisdictionSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
