import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
    getBusinessScale,
    getCapitalOrInvestment,
    getIndividualOrCorporation,
    getIndustries,
    getYearsEstablished,
} from '../../api/masterDataGrant';
import { FetchState } from '../../types';

export type MasterDataGrantState = {
    capitalOrInvestment: CapitalOrInvestmentState;
    businessScale: BusinessScaleState;
    yearsEstablished: YearsEstablishedState;
    industry: IndustryState;
    individualOrCorporation: IndividualOrCorporationState;
};

interface CapitalOrInvestmentState extends FetchState {
    capitalOrInvestment: CapitalOrInvestment[];
}

interface BusinessScaleState extends FetchState {
    businessScale: BusinessScale[];
}

interface YearsEstablishedState extends FetchState {
    yearsEstablished: YearsEstablished[];
}

interface IndustryState extends FetchState {
    industries: Industry[];
}

interface IndividualOrCorporationState extends FetchState {
    individualOrCorporation: IndividualOrCorporation[];
}

export type CapitalOrInvestment = {
    id: number;
    code: string;
    name: string;
};

export type BusinessScale = {
    id: number;
    code: string;
    name: string;
};

export type YearsEstablished = {
    id: number;
    code: string;
    name: string;
};

export type Industry = {
    id: number;
    code: string;
    name: string;
};

export type IndividualOrCorporation = {
    id: number;
    code: string;
    name: string;
};

export type MasterDataGrantRequest = {
    column?: OptionFilterGrant;
    keyword?: string;
};

export type UpdateMasterDataGrantRequest = {
    code: string;
    name: string;
};

export type OptionFilterGrant = 'code' | 'name';

export const fetchCapitalOrInvestment = createAsyncThunk(
    'admin/capital/investment',
    async (params: MasterDataGrantRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getCapitalOrInvestment(params);
            const { data = [], success } = response.data;

            if (success) {
                dispatch(setCapitalOrInvestmentData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(false);
        }
    }
);

export const fetchBusinessScale = createAsyncThunk(
    'admin/master/grant/business-scale',
    async (params: MasterDataGrantRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getBusinessScale(params);
            const { data = [], success } = response.data;

            if (success) {
                dispatch(setBusinessScaleData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(false);
        }
    }
);

export const fetchYearsEstablished = createAsyncThunk(
    'admin/master/grant/years-established',
    async (params: MasterDataGrantRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getYearsEstablished(params);
            const { data = [], success } = response.data;

            if (success) {
                dispatch(setYearsEstablishedData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(false);
        }
    }
);

export const fetchIndustries = createAsyncThunk(
    'admin/master/grant/industry',
    async (params: MasterDataGrantRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getIndustries(params);
            const { data = [], success } = response.data;

            if (success) {
                dispatch(setIndustryData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(false);
        }
    }
);

export const fetchIndividualOrCorporation = createAsyncThunk(
    'admin/master/grant/individual-or-corporation',
    async (params: MasterDataGrantRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getIndividualOrCorporation(params);
            const { data = [], success } = response.data;

            if (success) {
                dispatch(setIndividualOrCorporationData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(false);
        }
    }
);

export const masterDataGrantSlice = createSlice({
    name: 'master-data-grant',
    initialState: {
        capitalOrInvestment: {
            loading: false,
            success: false,
            error: false,
            capitalOrInvestment: [],
        },
        businessScale: {
            loading: false,
            success: false,
            error: false,
            businessScale: [],
        },
        yearsEstablished: {
            loading: false,
            success: false,
            error: false,
            yearsEstablished: [],
        },
        industry: {
            loading: false,
            success: false,
            error: false,
            industries: [],
        },
        individualOrCorporation: {
            loading: false,
            success: false,
            error: false,
            individualOrCorporation: [],
        },
    } as MasterDataGrantState,
    reducers: {
        setCapitalOrInvestmentData: (state: MasterDataGrantState, { payload }) => {
            state.capitalOrInvestment.capitalOrInvestment = payload;
        },
        setBusinessScaleData: (state: MasterDataGrantState, { payload }) => {
            state.businessScale.businessScale = payload;
        },
        setYearsEstablishedData: (state: MasterDataGrantState, { payload }) => {
            state.yearsEstablished.yearsEstablished = payload;
        },
        setIndustryData: (state: MasterDataGrantState, { payload }) => {
            state.industry.industries = payload;
        },
        setIndividualOrCorporationData: (state: MasterDataGrantState, { payload }) => {
            state.individualOrCorporation.individualOrCorporation = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCapitalOrInvestment.pending, (state: MasterDataGrantState) => {
                state.capitalOrInvestment.loading = true;
            })
            .addCase(fetchCapitalOrInvestment.fulfilled, (state: MasterDataGrantState) => {
                state.capitalOrInvestment.loading = false;
                state.capitalOrInvestment.success = true;
                state.capitalOrInvestment.error = false;
            })
            .addCase(fetchCapitalOrInvestment.rejected, (state: MasterDataGrantState) => {
                state.capitalOrInvestment.loading = false;
                state.capitalOrInvestment.success = false;
                state.capitalOrInvestment.error = true;
                state.capitalOrInvestment.capitalOrInvestment = [];
            })
            .addCase(fetchBusinessScale.pending, (state: MasterDataGrantState) => {
                state.businessScale.loading = true;
            })
            .addCase(fetchBusinessScale.fulfilled, (state: MasterDataGrantState) => {
                state.businessScale.loading = false;
                state.businessScale.success = true;
                state.businessScale.error = false;
            })
            .addCase(fetchBusinessScale.rejected, (state: MasterDataGrantState) => {
                state.businessScale.loading = false;
                state.businessScale.success = false;
                state.businessScale.error = true;
                state.businessScale.businessScale = [];
            })
            .addCase(fetchYearsEstablished.pending, (state: MasterDataGrantState) => {
                state.yearsEstablished.loading = true;
            })
            .addCase(fetchYearsEstablished.fulfilled, (state: MasterDataGrantState) => {
                state.yearsEstablished.loading = false;
                state.yearsEstablished.success = true;
                state.yearsEstablished.error = false;
            })
            .addCase(fetchYearsEstablished.rejected, (state: MasterDataGrantState) => {
                state.yearsEstablished.loading = false;
                state.yearsEstablished.success = false;
                state.yearsEstablished.error = true;
                state.yearsEstablished.yearsEstablished = [];
            })
            .addCase(fetchIndustries.pending, (state: MasterDataGrantState) => {
                state.industry.loading = true;
            })
            .addCase(fetchIndustries.fulfilled, (state: MasterDataGrantState) => {
                state.industry.loading = false;
                state.industry.success = true;
                state.industry.error = false;
            })
            .addCase(fetchIndustries.rejected, (state: MasterDataGrantState) => {
                state.industry.loading = false;
                state.industry.success = false;
                state.industry.error = true;
                state.industry.industries = [];
            })
            .addCase(fetchIndividualOrCorporation.pending, (state: MasterDataGrantState) => {
                state.individualOrCorporation.loading = true;
            })
            .addCase(fetchIndividualOrCorporation.fulfilled, (state: MasterDataGrantState) => {
                state.individualOrCorporation.loading = false;
                state.individualOrCorporation.success = true;
                state.individualOrCorporation.error = false;
            })
            .addCase(fetchIndividualOrCorporation.rejected, (state: MasterDataGrantState) => {
                state.individualOrCorporation.loading = false;
                state.individualOrCorporation.success = false;
                state.individualOrCorporation.error = true;
                state.individualOrCorporation.individualOrCorporation = [];
            });
    },
});

export const masterDataGrantSelector = (state: RootState) => state.grant;
export const {
    setCapitalOrInvestmentData,
    setBusinessScaleData,
    setYearsEstablishedData,
    setIndustryData,
    setIndividualOrCorporationData,
} = masterDataGrantSlice.actions;
