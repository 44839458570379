import { MasterParams } from 'types';
import { getAxiosClientWithToken } from 'api';
import { UpdateMasterData, RegisterMasterData, DeleteMasterData } from 'app/slice/masterDataSlice';

export const getResourceIndustry = (params: MasterParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/industries', { params });
};

export const getResourceEmployeeCountRanges = (params: MasterParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/employee-count-ranges', { params });
};

export const getResourceFields = (params: MasterParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/fields', { params });
};

export const registerResourceIndustry = ({ code, name }: RegisterMasterData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/industries', { code, name });
};

export const registerResourceEmployeeCountRanges = ({ code, name }: RegisterMasterData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/employee-count-ranges', { code, name });
};

export const registerResourceFields = ({ code, name, is_sharoushi, is_shindanshi }: RegisterMasterData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/fields', { code, name, is_sharoushi, is_shindanshi });
};

export const updateResourceIndustry = ({ code, name, _method }: UpdateMasterData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/industries/${code}`, { name, _method });
};

export const updateResourceEmployeeCountRanges = ({ code, name, _method }: UpdateMasterData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/employee-count-ranges/${code}`, { name, _method });
};

export const updateResourceFields = ({ code, name, is_sharoushi, is_shindanshi, _method }: UpdateMasterData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/fields/${code}`, { name, _method, is_sharoushi, is_shindanshi });
};

export const deleteResourceIndustry = ({ code }: DeleteMasterData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/industries/${code}`);
};

export const deleteResourceEmployeeCountRanges = ({ code }: DeleteMasterData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/employee-count-ranges/${code}`);
};

export const deleteResourceFields = ({ code }: DeleteMasterData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/fields/${code}`);
};
