import { CSSProperties } from 'react';
import { OptionFilterGrant } from 'app/slice/masterDataGrantSlide';
import { OptionFilterSubsidies } from 'app/slice/masterDataSubsidiesSlide';
import { InterviewFilter, OptionFilterAccount } from 'types';
import { OptionFilterSubsidyManagement } from 'app/slice/subsidiesSlice';
import { OptionFilterGrantManagement } from 'app/slice/grantsSlice';
import { OptionFilterLoanManagement } from 'app/slice/loanSlice';

export const Constant = {
    SIDEBAR_WIDTH: 265,
};

export const EDUCATIONS = [
    { label: '就業規則', value: 1 },
    { label: '雇用管理', value: 2 },
    { label: '人事制度・賃金制度・退職金制度', value: 3 },
    { label: '労務相談', value: 4 },
    { label: 'ハラスメント対策', value: 5 },
    { label: 'メンタルヘルス対策', value: 6 },
    { label: '労働時間・休暇', value: 7 },
    { label: '助成金（厚生労働省）', value: 8 },
    { label: '社会保険・労働保険', value: 9 },
    { label: '給与計算', value: 10 },
    { label: '年金（障害年金・老齢年金 等）', value: 11 },
    { label: '女性・高齢者・非正規労働者', value: 12 },
    { label: '障がい者雇用', value: 13 },
    { label: '外国人労働者', value: 14 },
    { label: '仕事と育児・介護、治療の両立支援', value: 15 },
    { label: '安全衛生', value: 16 },
    { label: '教育訓練', value: 17 },
    { label: '労務監査', value: 18 },
    { label: '健康経営', value: 20 },
    { label: '働き方改革', value: 21 },
    { label: 'セミナー講師', value: 22 },
];

export const EDUCATIONS_DIAGNOSTICIAN = [
    { label: '事業承継', value: 1 },
    { label: '補助金申請', value: 2 },
    { label: '経営革新', value: 3 },
    { label: '経営改善', value: 4 },
    { label: '経営戦略', value: 5 },
    { label: '販路拡大・販売促進', value: 6 },
    { label: '財務・資金繰り', value: 7 },
    { label: '融資相談', value: 8 },
    { label: '創業', value: 9 },
    { label: '事業再生', value: 10 },
    { label: '海外展開', value: 11 },
    { label: 'ＩＴ化', value: 12 },
    { label: '生産管理、製品・技術関連', value: 13 },
    { label: '省エネ・環境保全', value: 14 },
    { label: 'ＩＳＯ認証', value: 15 },
    { label: '人材育成、組織活性化', value: 16 },
    { label: 'ＢＣＰ', value: 17 },
    { label: '就業規則', value: 18 },
    { label: 'その他', value: 19 },
    { label: '健康経営', value: 20 },
    { label: '働き方改革', value: 21 },
    { label: 'セミナー講師', value: 22 },
];

export const SPECIALTY_INDUSTRY = [
    { label: '小売業', value: 1 },
    { label: '卸売業', value: 2 },
    { label: 'サービス業', value: 3 },
    { label: '飲食業', value: 4 },
    { label: '情報・通信業', value: 5 },
    { label: '医療・介護福祉業', value: 6 },
    { label: '人材派遣業', value: 7 },
    { label: '教育業', value: 8 },
    { label: '金融・保険業', value: 9 },
    { label: '不動産業', value: 10 },
    { label: '製造業', value: 11 },
    { label: '建設業', value: 12 },
    { label: '運輸業', value: 13 },
    { label: '鉱業', value: 14 },
    { label: '農林漁業', value: 15 },
    { label: '協同組合など団体', value: 16 },
];

export const ORGANIZATIONS = [
    { label: '0人', value: 1 },
    { label: '1～5人', value: 2 },
    { label: '6～10人', value: 3 },
    { label: '11～20人', value: 4 },
    { label: '21～50人', value: 5 },
    { label: '51～100人', value: 6 },
    { label: '101～300人', value: 7 },
    { label: '301～500人', value: 8 },
    { label: '501～1000人', value: 9 },
    { label: '1001人以上', value: 10 },
];

export const FilterMasterGrant = [
    {
        value: undefined,
        label: '全て',
    },
    {
        value: 'name' as OptionFilterGrant,
        label: '内容',
    },
    {
        value: 'code' as OptionFilterGrant,
        label: 'コード',
    },
];

export const FilterMasterSubsidies = [
    {
        value: undefined,
        label: '全て',
    },
    {
        value: 'name' as OptionFilterSubsidies,
        label: '内容',
    },
    {
        value: 'code' as OptionFilterSubsidies,
        label: 'コード',
    },
];

export const FilterAccountManagement = [
    {
        value: undefined,
        label: '全て',
    },
    {
        value: 'secretary_number' as OptionFilterAccount,
        label: '幹事番号',
    },
    {
        value: 'fullname' as OptionFilterAccount,
        label: '社労士名',
    },
    {
        value: 'email' as OptionFilterAccount,
        label: 'Eメール',
    },
    {
        value: 'office_name' as OptionFilterAccount,
        label: '事務所名称',
    },
    {
        value: 'finished_count' as OptionFilterAccount,
        label: '面談回数',
    },
    {
        value: 'rejected_count' as OptionFilterAccount,
        label: '辞退回数',
    },
    {
        value: 'schedule_count' as OptionFilterAccount,
        label: '申込回数',
    },
];

export const FilterAccountWaitingApproval = [
    {
        value: undefined,
        label: '全て',
    },
    {
        value: 'fullname' as OptionFilterAccount,
        label: '社労士名',
    },
    {
        value: 'email' as OptionFilterAccount,
        label: 'Eメール',
    },
    {
        value: 'office_name' as OptionFilterAccount,
        label: '事務所名称',
    },
    {
        value: 'address' as OptionFilterAccount,
        label: '住所',
    },
];

export const FilterDiagnosticiansManagement = [
    {
        value: undefined,
        label: '全て',
    },
    {
        value: 'secretary_number' as OptionFilterAccount,
        label: '幹事番号',
    },
    {
        value: 'fullname' as OptionFilterAccount,
        label: '診断士名',
    },
    {
        value: 'email' as OptionFilterAccount,
        label: 'Eメール',
    },
    {
        value: 'office_name' as OptionFilterAccount,
        label: '事務所名称',
    },
    {
        value: 'finished_count' as OptionFilterAccount,
        label: '面談回数',
    },
    {
        value: 'rejected_count' as OptionFilterAccount,
        label: '辞退回数',
    },
    {
        value: 'schedule_count' as OptionFilterAccount,
        label: '申込回数',
    },
];

export const FilterDiagnosticiansWaitingApproval = [
    {
        value: undefined,
        label: '全て',
    },
    {
        value: 'fullname' as OptionFilterAccount,
        label: '診断士名',
    },
    {
        value: 'email' as OptionFilterAccount,
        label: 'Eメール',
    },
    {
        value: 'office_name' as OptionFilterAccount,
        label: '事務所名称',
    },
    {
        value: 'address' as OptionFilterAccount,
        label: '住所',
    },
];

export const FilterInterview = [
    {
        value: 0 as InterviewFilter,
        label: '全て',
    },
    {
        value: 1 as InterviewFilter,
        label: '顧客名',
    },
    {
        value: 4 as InterviewFilter,
        label: '専門家名',
    },
    {
        value: 3 as InterviewFilter,
        label: '面談内容',
    },
    {
        value: 5 as InterviewFilter,
        label: '時間',
    },
];

export const CompanyRulesStatus = [
    {
        value: '',
        label: '全て',
    },
    {
        value: 'name' as string,
        label: '企業名',
    },
    {
        value: 'industry_name' as string,
        label: '業種',
    },
    {
        value: 'manager_name' as string,
        label: '担当者氏名',
    },
    {
        value: 'daido_employee_name' as string,
        label: '大同生命営業担当者',
    },
    {
        value: 'user_created_at' as string,
        label: '登録日',
    },
    {
        value: 'files_count' as string,
        label: 'ファイル数',
    },
    {
        value: 'user_status' as string,
        label: '状態',
    },
];

export const BusinessManagementStatus = [
    {
        value: '',
        label: '全て',
    },
    {
        value: 'company_name',
        label: '企業名',
    },
    {
        value: 'email',
        label: 'メールアドレス',
    },
    {
        value: 'manager_name',
        label: '担当者',
    },
    {
        value: 'daido_employee_name',
        label: '大同生命営業担当者',
    },
    {
        value: 'daido_employee_code',
        label: '担当者コード',
    },
    {
        value: 'daido_branch_name',
        label: '担当支社',
    },
    {
        value: 'daido_branch_code',
        label: '担当支社コード',
    },
];

export const statusData = [
    { value: '', content: 'ステータス' },
    { value: '1', content: 'キャンセル' },
    { value: '0,2', content: 'マッチング中' },
    { value: '3', content: '専門家選択' },
    { value: '4', content: '面談予約済み' },
    { value: '5', content: '面談済み' },
    { value: '6', content: 'マッチングなし' },
    { value: '7', content: '中企団相談窓口ご案内中' },
    { value: '8', content: '面談日程確認待ち' },
    { value: '9', content: '回答期限切れ' },
];

export const statusSpecialistData = [
    { value: '', content: 'ステータス' },
    { value: '4', content: 'マッチング終了' },
    { value: '0', content: 'マッチング中' },
    { value: '1', content: '辞退' },
    { value: '2', content: '企業からの返事待ち' },
    { value: '5', content: '面談済み' },
    { value: '6', content: 'キャンセル' },
    { value: '9', content: '回答期限切れ' },
    { value: '3', content: '面談予約済み' },
];

export const statusRules = [
    { value: '', content: '全て' },
    { value: 1, content: '有効' },
    { value: -1, content: '無効' },
];

export const MenuDropdownStyle: CSSProperties = {
    minWidth: 70,
};

export const ValidateStyle: CSSProperties = {
    bottom: 9,
};

export const Role = {
    ADMIN: 1,
    SHAROUSHI: 2,
    SHINDANSHI: 3,
    COMPANY: 4,
    CHUKIDAN_SHAROUSHI: 5,
    KANRI_DAIDO_SUPERADMIN: 6,
    KANRI_DAIDO_ADMIN: 7,
    KANRI_DAIDO_EMPLOYEE: 8,
};

export const IS_ONLINE_SUPPORT = 1;
export const IS_ALLOW_NOTIFICATIONS = 1;
export const ROLE_ID_REGISTER_ACCOUNT = 2;
export const ROLE_ID_REGISTER_DIAGNOSTICIAN = 3;
export const ALLOW_SPECIALIST_SEARCH = 1;
export const NOT_ALLOW_SPECIALIST_SEARCH = 0;
export const ALLOW_COMPANY_REFERRALS = 1;
export const NOT_ALLOW_COMPANY_REFERRALS = 0;
export const PER_PAGE_DEFAULT = 20;
export const PAGE_NUMBER_DEFAULT = 1;
export const DELETE_NUMBER_DEFAULT = 1;
export const STATUS_TRASH = 1;

export const FORMAT_TIME = 'HH:mm';

export const FilterSubsidyManagement = [
    {
        value: undefined,
        label: '全て',
    },
    {
        value: 'code' as OptionFilterSubsidyManagement,
        label: '補助金ID',
    },
    {
        value: 'title' as OptionFilterSubsidyManagement,
        label: 'タイトル',
    },
    {
        value: 'overview' as OptionFilterSubsidyManagement,
        label: '概要',
    },
    {
        value: 'target_groups' as OptionFilterSubsidyManagement,
        label: '対象企業',
    },
    {
        value: 'amount' as OptionFilterSubsidyManagement,
        label: '受給額',
    },
    {
        value: 'application_period' as OptionFilterSubsidyManagement,
        label: '募集期間',
    },
];

export const FilterGrantManagement = [
    {
        value: undefined,
        label: '全て',
    },
    {
        value: 'code' as OptionFilterGrantManagement,
        label: '補助金ID',
    },
    {
        value: 'title' as OptionFilterGrantManagement,
        label: 'タイトル',
    },
    {
        value: 'overview' as OptionFilterGrantManagement,
        label: '概要',
    },
    {
        value: 'target_groups' as OptionFilterGrantManagement,
        label: '対象企業',
    },
    {
        value: 'amount' as OptionFilterGrantManagement,
        label: '受給額',
    },
    {
        value: 'application_period' as OptionFilterGrantManagement,
        label: '募集期間',
    },
];

export const FilterLoanManagement = [
    {
        value: undefined,
        label: '全て',
    },
    {
        value: 'code' as OptionFilterLoanManagement,
        label: '管理No.',
    },
    {
        value: 'category' as OptionFilterLoanManagement,
        label: 'カテゴリー',
    },
    {
        value: 'title' as OptionFilterLoanManagement,
        label: '名称',
    },
    {
        value: 'updated_at' as OptionFilterLoanManagement,
        label: '更新日',
    },
];

export const MATCHING_SETTING_DEFAULT = 3;
export const SUBSIDY_APPROVED = 1;
export const SUBSIDY_APPROVAL_PENDING = 0;
export const SUBSIDY_DISPLAY = 0;
export const SUBSIDY_HIDDEN = 1;
export const GRANT_DISPLAY = 0;
export const GRANT_HIDDEN = 1;
export const LOAN_DISPLAY = 0;
export const LOAN_HIDDEN = 1;
export const SUBSIDY_APPLICABLE = 1;
export const SUBSIDY_NOT_APPLICABLE = 0;
export const GRANT_APPLICABLE = 1;
export const GRANT_NOT_APPLICABLE = 0;
export const LOAN_APPROVED = 1;
export const LOAN_APPROVAL_PENDING = 0;
export const FUND_TYPE_LOAN = 2;
export const FUND_TYPE_SUBSIDY = 3;
export const FUND_TYPE_GRANT = 4;
export const CREATED = 1;
export const UPDATE = 2;
export const DELETE = 3;
export const MONEY_STATUS = ['承認待ち', '承認済み'];

export const MONEY_APPEARANCES = ['表示', '非表示'];

export const SELECTED = 'SELECTED';
export const CHKD_SELECTING = 'CHKD_SELECTING';
export const FINISHED = 'FINISHED';
export const PRESENT = 1;
export const NOT_PRESENT = 0;
export const FIRST_HOPE = 0;
export const SECOND_HOPE = 1;
export const INVALID = '無効';

export enum StatusAppointment {
    MATCHED = 'MATCHED',
    PAST_DUE = 'PAST_DUE',
    NO_MATCH = 'NO_MATCH',
    SELECTED = 'SELECTED',
    FINISHED = 'FINISHED',
    SELECTING = 'SELECTING',
    CANCELLED = 'CANCELLED',
    CHKD_WAITING = 'CHKD_WAITING',
    CHKD_SELECTING = 'CHKD_SELECTING',
    UNCONFIRMED = 'UNCONFIRMED',
    WAITING_CONF = 'WAITING_CONF'
}

export const IndustryTabKey = '1';
export const EmployeeCountRangeTabKey = '2';
export const FieldTabKey = '3';
export const NOT_DRAFT = 0;
export const IS_DRAFT = 1;

export const FUND_LOAN_TAB_DRAFT = '2';
export const FUND_GRANT_TAB_DRAFT = '2';
export const FUND_SUBSIDY_TAB_DRAFT = '2';

export const FUND_LOAN_TAB = '1';
export const FUND_GRANT_TAB = '1';
export const FUND_SUBSIDY_TAB = '1';

export const FUND_LOAN_TAB_TRASH = '1';
export const FUND_SUBSIDY_TAB_TRASH = '2';
export const FUND_GRANT_TAB_TRASH = '3';

export const FUND_LOAN_TYPE = 2;
export const FUND_SUBSIDY_TYPE = 3;
export const FUND_GRANT_TYPE = 4;

export const ACCOUNT_TAB = '1';
export const ACCOUNT_WAITING_APPROVAL_TAB = '2';
export const ACCOUNT_STATUS = 1;
export const ACCOUNT_STATUS_WAITING_APPROVAL = 0;
export const DIAGNOSTICIAN_ROLE_ID = 3;
export const ACCOUNT_ROLE_ID = 2;
export const PROFILE_TAB = '1';
export const LIST_INTERVIEW_TAB = '2';
export const EVALUATION_TAB = '3';
export const NOTIFICATION_TYPE = 1;
export const NOTIFICATION_CONTENT_TYPE = 2;

export const APPLICATION_TYPE = {
    FUND: 1,
    POLICY: 2,
};

export enum ConsultationStatuses {
    MATCHING = 'マッチング中',
    SCHEDULED = '面談予約済み',
    SELECTING_SPECIALIST = '専門家選択',
    NO_MATCH = 'マッチングなし',
    CANCELED = 'キャンセル',
    FINISHED = '面談済み',
    REQUESTING = '中企団相談窓口ご案内中',
    WAITING_CONF = '面談日程確認待ち',
    PAST_DUE = '回答期限切れ'
}

export const APPLICATION_POLICY_TYPE_LABELS = ['就業規則', '賃金規程', '育児介護休業規程'];