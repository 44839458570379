import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getExchange } from 'api/exchange';
import { RootState } from 'app/store';
import { ExchangeData, ExchangeManagement, ExchangeState, FetchExchangeRequest, Pagination } from 'types';
import { PER_PAGE_DEFAULT } from 'config/constant';

type ExchangeManagementState = {
    exchangeManage: ExchangeManagement;
};

/**
 * GET API Exchange Management
 **/
export const fetchExchangeManagement = createAsyncThunk(
    'admin/companies/meeting',
    async (params: FetchExchangeRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getExchange(params);
            const {
                data = [],
                success,
                current_page: currentPage = 1,
                last_page: lastPage = 1,
                per_page: perPage = PER_PAGE_DEFAULT,
                total = 0,
            } = response.data;

            if (success) {
                dispatch(
                    setExchangeManagement({
                        data,
                        pagination: { lastPage, currentPage, perPage, total } as Pagination,
                    }),
                );
                return true;
            }

            return rejectWithValue(false);
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

const initialState = {
    exchangeManage: {
        loading: false,
        success: false,
        error: false,
        total: 0,
        lastPage: 1,
        currentPage: 1,
        count: 0,
        perPage: PER_PAGE_DEFAULT,
        exchanges: [],
    },
} as ExchangeManagementState;

export const exchangeManagementSlice = createSlice({
    name: 'exchange',
    initialState: initialState,
    reducers: {
        setExchangeManagement: (state: ExchangeManagementState, { payload }) => {
            state.exchangeManage.exchanges = [...payload.data].map(
                (exchange: ExchangeData) =>
                    ({
                        companyId: exchange?.company_id,
                        applicationId: exchange?.application_id,
                        companyName: exchange?.company_name,
                        fundTitle: exchange?.fund_title,
                        userName: exchange?.user_name,
                        status: exchange?.status,
                        scheduleDate: exchange?.schedule_date,
                        scheduleHour: exchange?.schedule_hour,
                        dueDate: exchange?.due_date,
                        officeName: exchange?.office_name,
                        statusId: exchange?.status_id,
                        type: exchange?.type,
                        showDate: exchange?.show_date,
                        createdAt: exchange?.created_at,
                        zoomDisplayName: exchange?.zoom_display_name
                    } as ExchangeState),
            );
            state.exchangeManage.total = payload.pagination.total;
            state.exchangeManage.lastPage = payload.pagination.lastPage;
            state.exchangeManage.perPage = payload.pagination.perPage;
            state.exchangeManage.currentPage = payload.pagination.currentPage;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchExchangeManagement.pending, (state: ExchangeManagementState) => {
                state.exchangeManage.loading = true;
            })
            .addCase(fetchExchangeManagement.rejected, (state: ExchangeManagementState) => {
                state.exchangeManage.loading = false;
                state.exchangeManage.success = false;
                state.exchangeManage.exchanges = [];
            })
            .addCase(fetchExchangeManagement.fulfilled, (state: ExchangeManagementState) => {
                state.exchangeManage.loading = false;
                state.exchangeManage.success = true;
            });
    },
});

export const { setExchangeManagement } = exchangeManagementSlice.actions;
export const exchangeManagementSelector = (state: RootState) => state.exchange;
