import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import {
    BusinessConsultant,
    BusinessConsultantData,
    BusinessExchange,
    BusinessExchangeData,
    DetailBusinessConsultant,
    SendMailRequest,
    Evaluation,
    RegisterBusinessConsultants, ConsultantData,
} from 'types';
import {
    createBusinessConsultants,
    deleteBusinessConsultants,
    detailsBusinessConsultants,
    getBusinessConsultants,
    getBusinessExchange,
    sendMail,
    getEvaluation,
} from 'api/diagnosticians';
import { getErrorMessage } from 'api';
import moment from 'moment';

export type ConsultantState = {
    success: boolean;
    message: string | object;
    loading: boolean;
    error: boolean;
};
export type DiagnosticianData = {
    businessExchangeData: BusinessExchangeData;
    businessConsultantData: BusinessConsultantData;
    waitingApproval: BusinessConsultantData;
    deleteConsultant: ConsultantState;
    detailConsultants: DetailBusinessConsultant;
    linkEmailConsultant: ConsultantState;
    newBusinessConsultantData: RegisterBusinessConsultants[];
    evaluation: {
        loading: boolean;
        success: boolean;
        total: number;
        perPage: number;
        currentPage: number;
        lastPage: number;
        Evaluation: Evaluation[];
    };
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | object;
    };
};

/**
 * DELETE business consultants
 **/
export const removeBusinessConsultant = createAsyncThunk(
    'admin/user/:uuid',
    async ({ uuid }: { uuid?: string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await deleteBusinessConsultants(uuid);
            const { success } = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * GET details business consultants
 **/
export const fetchDetailsBusinessConsultants = createAsyncThunk(
    '/admin/users/:uuid',
    async (uuid: string, { dispatch, rejectWithValue }) => {
        try {
            let response = await detailsBusinessConsultants(uuid);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setDetailsBusinessConsultants(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

type BusinessExchangeRequest = {
    values: BusinessExchange;
    uuid: any;
};

/**
 * GET list of business exchange
 **/
export const fetchBusinessExchange = createAsyncThunk(
    'admin/user/:uuid/interviews',
    async (params: BusinessExchangeRequest, { dispatch, rejectWithValue }) => {
        const { uuid, values } = params;
        try {
            let response = await getBusinessExchange(uuid, values);
            const {success} = response.data;
            if (success) {
                dispatch(setBusinessExchange(response.data));
                return success;
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * GET list of business consultants
 **/
export const fetchBusinessConsultants = createAsyncThunk(
    'admin/user/business/consultants',
    async (params: BusinessConsultant, { dispatch, rejectWithValue }) => {
        try {
            let response = await getBusinessConsultants(params);
            const {success} = response.data;
            if (success) {
                dispatch(setBusinessConsultants(response.data));
                return success;
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchWaitingApproval = createAsyncThunk(
    'admin/user/waiting/approval',
    async (params: BusinessConsultant, { dispatch, rejectWithValue }) => {
        try {
            let response = await getBusinessConsultants(params);
            const {success} = response.data;
            if (success) {
                dispatch(waitingOfApproval(response.data));
                return success;
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createConsultants = createAsyncThunk(
    'admin/user/register',
    async (params: RegisterBusinessConsultants, { dispatch, rejectWithValue }) => {
        try {
            let response = await createBusinessConsultants(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setNewBusinessConsultants(data));
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * GET email
 **/
export const sendEmailConsultants = createAsyncThunk(
    'admin/mail/queue/register',
    async (params: SendMailRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await sendMail(params);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * GET list of evaluation
 **/
export const fetchEvaluation = createAsyncThunk(
    'admin/users/:uuid/evaluation',
    async (params: { uuid?: string; page: number }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getEvaluation(params);
            const { success } = response.data;

            if (success) {
                dispatch(setEvaluation(response.data));
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// save data reducers
export const diagnosticianSlice = createSlice({
    name: 'diagnosticians',
    initialState: {
        newBusinessConsultantData: [],
        businessExchangeData: {
            success: false,
            loading: false,
            error: false,
            code: 0,
            total: 0,
            per_page: 0,
            current_page: 0,
            last_page: 0,
            data: [],
        },
        businessConsultantData: {
            success: false,
            loading: false,
            error: false,
            code: 0,
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total: 0,
            total_waiting_not_search: 0,
            data: [],
        },
        waitingApproval: {
            success: false,
            loading: false,
            error: false,
            code: 0,
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total: 0,
            total_waiting_not_search: 0,
            data: [],
        },
        linkEmailConsultant: {
            success: false,
            message: '',
            loading: false,
            error: false,
            code: '',
        },
        deleteConsultant: {
            success: false,
            message: '',
            loading: false,
            error: false,
            code: '',
        },
        evaluation: {
            loading: false,
            success: false,
            total: 0,
            perPage: 0,
            currentPage: 0,
            lastPage: 0,
            Evaluation: [],
        },
        detailConsultants: {
            profile_image_url: '',
            sub_profile_image_url: '',
            uuid: '',
            role: '',
            label: '',
            lastname: '',
            firstname: '',
            lastname_furigana: '',
            firstname_furigana: '',
            email: '',
            office_name: '',
            postal_code: '',
            prefecture: '',
            address: '',
            building_name: '',
            prefectures: [],
            other_areas: '',
            other_address: '',
            office_pr: '',
            office_history: '',
            qualifications: '',
            fields: [],
            industries: [],
            employee_count_ranges: [],
            phone: '',
            twitter_url: '',
            facebook_url: '',
            homepage_url: '',
            business_manual_url: '',
            specialist_certification_images: [],
        },
        register: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
    } as DiagnosticianData,
    reducers: {
        setBusinessExchange: (state, action) => {
            state.businessExchangeData = action?.payload ?? [];
        },
        setBusinessConsultants: (state, { payload }) => {
            state.businessConsultantData = payload ?? [];
            state.businessConsultantData.data = payload.data.map((consultant: ConsultantData) => {
                return {
                    ...consultant,
                    registration_date: consultant.registration_date ? moment(consultant.registration_date).format('YYYY-MM-DD HH:mm:ss') : null,
                    updated_date: consultant.updated_date ? moment(consultant.updated_date).format('YYYY-MM-DD HH:mm:ss') : null
                }
            })
        },
        waitingOfApproval: (state, action) => {
            state.waitingApproval = action?.payload;
        },
        setDetailsBusinessConsultants: (state, action) => {
            state.detailConsultants = action.payload;
        },
        setNewBusinessConsultants: (state, action) => {
            state.newBusinessConsultantData = action?.payload ?? [];
        },
        resetSendMailState: (state: DiagnosticianData) => {
            state.linkEmailConsultant = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        setEvaluation: (state: DiagnosticianData, { payload }) => {
            state.evaluation.total = payload.total;
            state.evaluation.perPage = payload.per_page;
            state.evaluation.currentPage = payload.current_page;
            state.evaluation.lastPage = payload.last_page;
            state.evaluation.Evaluation = payload.data;
        },
        resetDeleteConsultant: (state: DiagnosticianData) => {
            state.deleteConsultant = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetRegisterState: (state: DiagnosticianData) => {
            state.register = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(removeBusinessConsultant.pending, (state: DiagnosticianData, action) => {
            state.deleteConsultant = {
                ...state.deleteConsultant,
                loading: true,
                message: action.payload as any,
            };
        });
        builder.addCase(removeBusinessConsultant.rejected, (state: DiagnosticianData, action) => {
            state.deleteConsultant = {
                ...state.deleteConsultant,
                loading: false,
                success: false,
                error: true,
                message: action.payload as string | object,
            };
        });
        builder.addCase(removeBusinessConsultant.fulfilled, (state: DiagnosticianData, action) => {
            state.deleteConsultant = {
                ...state.deleteConsultant,
                loading: false,
                success: true,
            };
        });

        builder.addCase(sendEmailConsultants.pending, (state: DiagnosticianData, action) => {
            state.linkEmailConsultant.loading = true;
        });
        builder.addCase(sendEmailConsultants.rejected, (state: DiagnosticianData, action) => {
            state.linkEmailConsultant = {
                loading: false,
                success: false,
                error: true,
                message: action.payload as string | object,
            };
        });
        builder.addCase(sendEmailConsultants.fulfilled, (state: DiagnosticianData, action) => {
            state.linkEmailConsultant.success = true;
            state.linkEmailConsultant.loading = false;
        });

        builder.addCase(createConsultants.pending, (state: DiagnosticianData, action) => {
            state.register.loading = true;
        });
        builder.addCase(createConsultants.rejected, (state: DiagnosticianData, { payload }) => {
            state.register.loading = false;
            state.register.success = false;
            state.register.error = true;
            state.register.message = payload as string | object;
        });
        builder.addCase(createConsultants.fulfilled, (state: DiagnosticianData, action) => {
            state.register.loading = false;
            state.register.success = true;
            state.register.error = false;
        });

        builder.addCase(fetchBusinessConsultants.pending, (state: DiagnosticianData) => {
            state.businessConsultantData.loading = true;
        });
        builder.addCase(fetchBusinessConsultants.rejected, (state: DiagnosticianData) => {
            state.businessConsultantData.loading = false;
            state.businessConsultantData.success = false;
            state.businessConsultantData.error = true;
        });
        builder.addCase(fetchBusinessConsultants.fulfilled, (state: DiagnosticianData) => {
            state.businessConsultantData.loading = false;
            state.businessConsultantData.success = true;
            state.businessConsultantData.error = false;
        });

        builder.addCase(fetchWaitingApproval.pending, (state: DiagnosticianData) => {
            state.waitingApproval.loading = true;
        });
        builder.addCase(fetchWaitingApproval.rejected, (state: DiagnosticianData) => {
            state.waitingApproval.loading = false;
            state.waitingApproval.success = false;
            state.waitingApproval.error = true;
        });
        builder.addCase(fetchWaitingApproval.fulfilled, (state: DiagnosticianData) => {
            state.waitingApproval.loading = false;
            state.waitingApproval.success = true;
            state.waitingApproval.error = false;
        });

        builder.addCase(fetchBusinessExchange.pending, (state: DiagnosticianData) => {
            state.businessExchangeData.loading = true;
        });
        builder.addCase(fetchBusinessExchange.rejected, (state: DiagnosticianData) => {
            state.businessExchangeData.loading = false;
            state.businessExchangeData.success = false;
            state.businessExchangeData.error = true;
        });
        builder.addCase(fetchBusinessExchange.fulfilled, (state: DiagnosticianData) => {
            state.businessExchangeData.loading = false;
            state.businessExchangeData.success = true;
            state.businessExchangeData.error = false;
        });

        builder.addCase(fetchEvaluation.pending, (state: DiagnosticianData) => {
            state.evaluation.loading = true;
        }).addCase(fetchEvaluation.rejected, (state: DiagnosticianData) => {
            state.evaluation.loading = false;
            state.evaluation.success = false;
        }).addCase(fetchEvaluation.fulfilled, (state: DiagnosticianData) => {
            state.evaluation.loading = false;
            state.evaluation.success = true;
        })
    },
});

export const {
    setBusinessExchange,
    setBusinessConsultants,
    waitingOfApproval,
    setDetailsBusinessConsultants,
    resetSendMailState,
    setNewBusinessConsultants,
    setEvaluation,
    resetDeleteConsultant,
    resetRegisterState,
} = diagnosticianSlice.actions;
export const diagnosticianSelector = (state: RootState) => state.diagnosticians;
