import { getAxiosClientWithToken } from 'api';
import { UpdateJurisdictionRequest } from 'app/slice/jurisdictionSlice';

let controller: AbortController | undefined;

export const getJurisdictions = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/jurisdictions');
};

export const updateJurisdictions = async (data: UpdateJurisdictionRequest) => {
    if (controller) controller.abort();
    controller = new AbortController();

    const restClient = getAxiosClientWithToken();
    const result = await restClient.put('/resources/jurisdictions', data, { signal: controller.signal });

    controller = undefined;

    return result;
};
