import { EditCapital, NewCapitalParams } from 'types';
import { getAxiosClientWithToken } from 'api';
import { handleFormData } from 'lib/utils';

export const getPurposes = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/purposes');
};

export const getHeadquarters = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/prefectures');
};

export const getIndustries = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/search-industries');
};

export const getNumberEmployees = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/search-employee-count-ranges');
};

export const getIndividualOrCorporation = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/coverage-types');
};

export const getCapitalOrInvestment = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/capital-ranges');
};

export const getYearsEstablished = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/years-established-ranges');
};

export const createNewCapital = (params: NewCapitalParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/funds', handleFormData(params));
};

export const updateCapital = ({ loanId, data }: EditCapital) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/funds/${loanId}`, handleFormData(data));
};
