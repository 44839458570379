import React, { useCallback, useEffect } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import IconConfirmDelete from 'assets/images/icon_question.png';
import { setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import iconSuccess from 'assets/images/success_check.png';
import iconWarning from 'assets/images/icon_warning.png';
import { useAppDispatch } from 'app/hooks';
import { resetDelete, resetRestore } from 'app/slice/traskSlice';
import { RestoreTrashManagement } from 'types';

type Props = {
    isVisible: boolean;
    onClose: () => void;
    onRestoreAccount: () => void;
    title: string;
    messageContent: string;
    tabActive: string;
    trashState: RestoreTrashManagement;
};

const RestoreTrashModal = (props: Props) => {
    const dispatch = useAppDispatch();
    const { isVisible, onClose, onRestoreAccount, title, tabActive, messageContent, trashState } = props;
    const onCloseModal = useCallback(() => {
        dispatch(resetRestore());
        dispatch(resetDelete());
        onClose();
    }, [dispatch, onClose]);

    useEffect(() => {
        if (trashState.error) {
            onCloseModal();
            const successModalPayload: SuccessModalState = {
                isShow: true,
                title: tabActive === '1' ? '社労士を元に戻す' : '診断士を元に戻す',
                message: tabActive === '1' ? '社労士の削除は失敗しました。' : '診断士の削除は失敗しました。',
                withButton: false,
                image: iconWarning,
                centered: true,
                modalClassname: 'send-mail-success',
            };
            dispatch(setSuccessState(successModalPayload));
        }
    }, [onCloseModal, dispatch, tabActive, trashState.error]);

    useEffect(() => {
        if (trashState.success) {
            onCloseModal();
            const successModalPayload: SuccessModalState = {
                isShow: true,
                title: tabActive === '1' ? '社労士を元に戻す' : '診断士を元に戻す',
                message: tabActive === '1' ? '正常に社労士を元に戻せました。' : '正常に診断士を元に戻せました。',
                withButton: false,
                image: iconSuccess,
                centered: true,
                modalClassname: 'send-mail-success',
            };
            dispatch(setSuccessState(successModalPayload));
        }
    }, [onCloseModal, dispatch, tabActive, trashState.success]);

    return (
        <Modal
            centered
            visible={isVisible}
            footer={null}
            width={572}
            className='confirm-modal confirm-delete-modal'
            onCancel={onCloseModal}
        >
            <div className='text-center title'>{title}</div>
            <div className='text-center'>
                <img src={IconConfirmDelete} alt='confirm-icon' />
                <div className='message'>{messageContent}</div>
            </div>
            <Row gutter={[0, 10]} justify='center' align='middle' className='form-buttons'>
                <Col span={24} className='d-flex justify-content-center align-items-center'>
                    <Button type='primary' block loading={trashState.loading} disabled={trashState.loading} onClick={onRestoreAccount}>
                        はい
                    </Button>
                </Col>
                <Col span={24} className='d-flex justify-content-center align-items-center'>
                    <Button block onClick={onCloseModal}>
                        いいえ
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default RestoreTrashModal;
