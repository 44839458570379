import React, { useEffect, useState } from 'react';
import { TrashParams, TraskData } from 'types';
import { Button, Col, Empty, Row, Table, Tabs } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    deleteTrashManagement,
    fetchTrashBusiness,
    fetchTrashData,
    restoreTrashManagement,
    trashManagementSelector,
} from 'app/slice/traskSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RestoreTrashModal from 'components/Trash/RestoreTrashModal';
import { DELETE_NUMBER_DEFAULT, PAGE_NUMBER_DEFAULT, Role, STATUS_TRASH } from 'config/constant';
import DeleteTrashModal from 'components/Trash/DeleteTrashModal';

const { TabPane } = Tabs;
const { Column } = Table;
const TrashManagement = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { trashData, trashBusiness, restoreTrashState, deleteTrashState } = useAppSelector(trashManagementSelector);
    const [searchParams, setSearchParams] = useSearchParams();
    const tabActiveKey = searchParams.get('type') ?? '1';

    type Data = {
        option: string | undefined;
        value: string | undefined;
        page: number;
    };
    const [trash, setTrash] = useState<Data>({
        option: '',
        value: '',
        page: PAGE_NUMBER_DEFAULT,
    });
    const [userId, setUserId] = useState<number>(0);
    const [isDelModalVisible, setIsDelModalVisible] = useState<boolean>(false);
    const [isRestoreModalVisible, setIsRestoreModalVisible] = useState<boolean>(false);

    const onChangeTab = (activeKey: string) => {
        setSearchParams({ type: activeKey });
        setTrash({
            option: '',
            value: '',
            page: PAGE_NUMBER_DEFAULT,
        });
    };
    const onOpenRestoreModal = (record: TraskData) => {
        setIsRestoreModalVisible(true);
        setUserId(record.user_id);
    };
    const onOpenDelModal = (record: TraskData) => {
        setIsDelModalVisible(true);
        setUserId(record.user_id);
    };
    const onCloseRestoreModal = () => setIsRestoreModalVisible(false);
    const onCloseDelModal = () => setIsDelModalVisible(false);

    useEffect(() => {
        if (tabActiveKey === '1') {
            dispatch(
                fetchTrashData({
                    status: STATUS_TRASH,
                    role_id: Role.SHAROUSHI,
                    option: trash.option,
                    value: trash.value,
                    page: trash.page,
                    deleted: DELETE_NUMBER_DEFAULT,
                } as TrashParams),
            );
        } else {
            dispatch(
                fetchTrashBusiness({
                    status: STATUS_TRASH,
                    role_id: Role.SHINDANSHI,
                    option: trash.option,
                    value: trash.value,
                    page: trash.page,
                    deleted: DELETE_NUMBER_DEFAULT,
                } as TrashParams),
            );
        }
    }, [
        dispatch,
        tabActiveKey,
        trash.option,
        trash.page,
        trash.value,
        restoreTrashState.success,
        deleteTrashState.success,
    ]);

    const onRestoreAccount = () => {
        setIsRestoreModalVisible(false);
        dispatch(restoreTrashManagement(userId));
    };

    const onDeleteAccount = () => {
        dispatch(deleteTrashManagement(userId));
    };

    const onViewDetailAccount = (record: TraskData) => {
        if (tabActiveKey === '1') {
            navigate(`/accounts/${record.user_id}?in-trash=true`);
        } else {
            navigate(`/diagnosticians/${record.user_id}?in-trash=true`);
        }
    };

    const renderActionButtons = (value: any, record: TraskData) => {
        return (
            <div className='action-buttons'>
                <Row gutter={[6, 6]}>
                    <Col>
                        <Button onClick={() => onViewDetailAccount(record)} className='detail-button'>
                            詳細
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => onOpenRestoreModal(record)} className='restore-button'>
                            元に戻す
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => onOpenDelModal(record)} className='delete-button'>
                            削除
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <>
            <div className='content trash-management'>
                <div className='header-container'>
                    <div className='title'>ゴミ箱</div>
                </div>

                <Tabs defaultActiveKey={tabActiveKey} activeKey={tabActiveKey} onChange={onChangeTab}>
                    <TabPane tab='社労士' key='1'>
                        <p className='total-record'>合計：{trashData?.total}人</p>
                        <Table
                            className='custom-table'
                            dataSource={trashData?.data}
                            rowKey='user_id'
                            loading={trashData?.loading}
                            scroll={{ x: 1000 }}
                            pagination={{
                                position: ['bottomCenter'],
                                onChange: (value) => {
                                    setTrash({
                                        option: trash.option,
                                        value: trash.value,
                                        page: value,
                                    });
                                },
                                pageSize: trashData?.per_page,
                                current: trashData?.current_page,
                                total: trashData?.total,
                                showSizeChanger: false,
                                showPrevNextJumpers: false,
                            }}
                            size='middle'
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='データなし' /> }}
                        >
                            <Column width='10%' title='幹事番号' dataIndex='secretary_number' key='secretary_number' />
                            <Column width='10%' title='社労士名' dataIndex='fullname' key='fullname' />
                            <Column width='20%' title='Eメール' dataIndex='email' key='email' />
                            <Column width='15%' title='事務所名' dataIndex='office_name' key='office_name' />
                            <Column width='10%' title='面談回数' dataIndex='finished_count' key='finished_count' />
                            <Column width='10%' title='申込回数' dataIndex='rejected_count' key='rejected_count' />
                            <Column
                                className='text-center'
                                title=''
                                dataIndex='actionButtons'
                                key='actionButtons'
                                width='25%'
                                render={renderActionButtons}
                            />
                        </Table>
                    </TabPane>
                    <TabPane tab='診断士' key='2'>
                        <p className='total-record'>合計：{trashBusiness?.total}人</p>
                        <Table
                            className='custom-table'
                            dataSource={trashBusiness?.data}
                            rowKey='user_id'
                            loading={trashBusiness?.loading}
                            scroll={{ x: 1000 }}
                            pagination={{
                                position: ['bottomCenter'],
                                onChange: (value) => {
                                    setTrash({
                                        option: trash.option,
                                        value: trash.value,
                                        page: value,
                                    });
                                },
                                current: trashBusiness?.current_page,
                                total: trashBusiness?.total,
                                defaultCurrent: trashBusiness?.current_page,
                                showSizeChanger: false,
                            }}
                            size='middle'
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='データなし' /> }}
                        >
                            <Column width='10%' title='幹事番号' dataIndex='secretary_number' key='secretary_number' />
                            <Column width='10%' title='診断士' dataIndex='fullname' key='fullname' />
                            <Column width='20%' title='Eメール' dataIndex='email' key='email' />
                            <Column width='15%' title='事務所名' dataIndex='office_name' key='office_name' />
                            <Column width='10%' title='面談回数' dataIndex='finished_count' key='finished_count' />
                            <Column width='10%' title='申込回数' dataIndex='rejected_count' key='rejected_count' />
                            <Column
                                className='text-center'
                                title=''
                                dataIndex='actionButtons'
                                key='actionButtons'
                                width='25%'
                                render={renderActionButtons}
                            />
                        </Table>
                    </TabPane>
                </Tabs>
            </div>
            <RestoreTrashModal
                messageContent={
                    tabActiveKey === '1' ? '社労士を元に戻しますが、よろしいですか？' : '診断士を元に戻しますが、よろしいですか？'
                }
                title={tabActiveKey === '1' ? '社労士を元に戻す' : '診断士を元に戻す'}
                onRestoreAccount={onRestoreAccount}
                tabActive={tabActiveKey}
                trashState={restoreTrashState}
                isVisible={isRestoreModalVisible}
                onClose={onCloseRestoreModal}
            />
            <DeleteTrashModal
                messageContent={
                    tabActiveKey === '1'
                        ? '社労士を削除したら元に戻せませんが、削除してもよろしいですか？'
                        : '診断士を削除したら元に戻せませんが、削除してもよろしいですか？'
                }
                title={tabActiveKey === '1' ? '社労士を削除' : '診断士を削除'}
                onRestoreAccount={onDeleteAccount}
                tabActive={tabActiveKey}
                trashState={deleteTrashState}
                isVisible={isDelModalVisible}
                onClose={onCloseDelModal}
            />
        </>
    );
};

export default TrashManagement;
