import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { AppointmentStatus, DataExchange, StyleList } from 'types';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { first, get, has, isArray, isEmpty, isNumber, isObject, isString, keysIn, last, mapKeys } from 'lodash';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { NamePath } from 'rc-field-form/es/interface';
import { APPLICATION_POLICY_TYPE_LABELS, ConsultationStatuses, StatusAppointment } from 'config/constant';
import { CustomUploadAdapter } from './CustomUploadAdapter';

/**
 * Set body id for styling
 *
 * @param {string} style
 */
export const useStyle = (...style: StyleList[]) => {
    document.body.id = style.join(' ');
    document.body.classList.add('w-100');
};

/**
 * Scroll to top
 */
export const scrollToTop = (behavior?: ScrollBehavior) =>
    window.scroll({
        top: 0,
        left: 0,
        behavior: behavior,
    });

export const isDevMode = () => {
    return process.env.NODE_ENV === 'development';
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange();

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return isMobile;
};

export function useIsActiveMenu(): [string[], Dispatch<SetStateAction<string[]>>] {
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const { pathname } = useLocation();

    useEffect(() => {
        const accounts = [
            '/accounts',
            '/accounts/:uuid',
            '/accounts/:uuid/edit',
            '/accounts/approve-detail/:uuid',
            '/accounts/:userId/:companyId/detail/:applicationId',
            '/accounts/:userId/:companyId/working-regulation/:applicationId',
            '/diagnosticians',
            '/diagnosticians/:uuid',
            '/diagnosticians/:userId/:companyId/detail/:applicationId',
            '/diagnosticians/:userId/:companyId/working-regulation/:applicationId',
            '/diagnosticians/:uuid/edit',
            '/diagnosticians/approve-detail/:uuid',
            '/email-management',
            '/email-management/email-details/:uuid',
            '/trash-management',
            '/master-data-management',
        ];
        const businessManagement = [
            '/business-management',
            '/business-management/:uuid',
            '/business-management/appointment-detail',
            '/business-management/:uuid/:applicationId/appointment-detail',
            '/interview-management',
            '/interview-management/:uuid/:applicationId/detail',
            '/loan-management',
            '/loan-management/register',
            '/loan-management/:uuid',
            '/loan-management/:uuid/edit',
            '/notification',
            '/notification/:uuid',
            '/notification/:uuid/edit',
            '/notification/register',
            '/subsidy-management',
            '/subsidy-management/:uuid',
            '/subsidy-management/:uuid/edit',
            '/subsidy-management/register',
            '/grant-management',
            '/grant-management/register',
            '/grant-management/:uuid',
            '/grant-management/:uuid/edit',
            '/email-notification',
            '/email-notification/email-detail/:uuid',
            '/trash-management-notification',
            '/export',
            '/setting',
        ];

        const companyManagement = [
            '/company',
            '/notification-content',
            '/notification-content/category',
            '/notification-content/detail',
            '/notification-content/edit',
            '/notification-content/register',
            '/diagnosis-email',
            '/diagnosis-email/diagnosis-email-detail/:uuid',
            '/export-diagnosis',
            '/company/:uuid',
            '/company/:uuid/:applicationId/exchange-detail',
            '/company/:uuid/detail/:applicationId'
        ];

        const masterDataSubsidyGranManagement = ['/master-data-subsidy-management', '/master-data-grant-management'];
        const searchDataManagement = ['/search-data-management'];
        if (isMatchPath(accounts, pathname)) {
            setOpenKeys(['/management/accounts']);
        }
        if (isMatchPath(businessManagement, pathname)) {
            setOpenKeys(['support-online']);
        }
        if (isMatchPath(companyManagement, pathname)) {
            setOpenKeys(['diagnosi-online']);
        }
        if (isMatchPath(masterDataSubsidyGranManagement, pathname)) {
            setOpenKeys(['support-online', 'master-data-subsidy-grand-management']);
        }
        if (isMatchPath(searchDataManagement, pathname)) {
            setOpenKeys(['search-data-management']);
        }
    }, [pathname]);

    return [openKeys, setOpenKeys];
}

export const isMatchPath = (paths: string[], pathname: string) => {
    return paths.some(
        (path) =>
            !isEmpty(
                matchPath(
                    {
                        path,
                        end: true,
                        caseSensitive: true,
                    },
                    pathname,
                ),
            ),
    );
};

export const useSelectedMenu = () => {
    const { pathname } = useLocation();
    const accounts = [
        '/accounts/:uuid',
        '/accounts/:uuid/edit',
        '/accounts/approve-detail/:uuid',
        '/accounts/:userId/:companyId/detail/:applicationId',
        '/accounts/:userId/:companyId/working-regulation/:applicationId',
    ];
    const diagnosticians = [
        '/diagnosticians/:uuid',
        '/diagnosticians/:userId/:companyId/detail/:applicationId',
        '/diagnosticians/:userId/:companyId/working-regulation/:applicationId',
        '/diagnosticians/:uuid/edit',
        '/diagnosticians/approve-detail/:uuid',
    ];
    const emails = ['/email-management/email-details/:uuid'];

    const notification = ['/notification/:uuid', '/notification/:uuid/edit', '/notification/register'];

    const emailNotification = ['/email-notification', '/email-notification/email-detail/:uuid'];

    const businessManagement = [
        '/business-management',
        '/business-management/:uuid',
        '/business-management/:uuid/:applicationId/appointment-detail',
        '/business-management/:uuid/:applicationId/appointment-detail/:specialistUuid/schedule',
    ];

    const interviewManagement = [
        '/interview-management', '/interview-management/:uuid/:applicationId/detail',
        '/interview-management', '/interview-management/:uuid/:applicationId/detail/:specialistUuid/schedule',
    ];

    const NotificationContentManagement = [
        '/notification-content',
        '/notification-content/category',
        '/notification-content/detail',
        '/notification-content/edit',
        '/notification-content/category',
        '/notification-content/detail',
        '/notification-content/register',
    ];

    const trashManagementNotification = ['/trash-management-notification'];

    const CompanyManagement = [
        '/company',
        '/company/:uuid',
        '/company/:uuid/:applicationId/exchange-detail',
        '/company/:uuid/detail/:applicationId',
    ];

    const diagnosisEmail = ['/diagnosis-email', '/diagnosis-email/diagnosis-email-detail/:uuid'];
    const exportBusiness = ['/export'];
    const exportDiagnosis = ['/export-diagnosis'];
    const setting = ['./setting'];

    const loanManagement = [
        '/loan-management',
        '/loan-management/register',
        '/loan-management/:uuid',
        '/loan-management/:uuid/edit',
    ];

    const subsidyManagement = [
        '/subsidy-management',
        '/subsidy-management/:uuid',
        '/subsidy-management/register',
        '/subsidy-management/:uuid/edit',
    ];

    const grantManagement = [
        '/grant-management',
        '/grant-management/:uuid',
        '/grant-management/register',
        '/grant-management/:uuid/edit',
    ];

    let selectedMenus = [];
    switch (true) {
        case isMatchPath(accounts, pathname):
            selectedMenus = ['/accounts'];
            break;
        case isMatchPath(diagnosticians, pathname):
            selectedMenus = ['/diagnosticians'];
            break;
        case isMatchPath(emails, pathname):
            selectedMenus = ['/email-management'];
            break;
        case isMatchPath(businessManagement, pathname):
            selectedMenus = ['/business-management'];
            break;
        case isMatchPath(interviewManagement, pathname):
            selectedMenus = ['/interview-management'];
            break;
        case isMatchPath(notification, pathname):
            selectedMenus = ['/notification'];
            break;
        case isMatchPath(emailNotification, pathname):
            selectedMenus = ['/email-notification'];
            break;
        case isMatchPath(NotificationContentManagement, pathname):
            selectedMenus = ['/notification-content'];
            break;
        case isMatchPath(trashManagementNotification, pathname):
            selectedMenus = ['/trash-management-notification'];
            break;
        case isMatchPath(CompanyManagement, pathname):
            selectedMenus = ['/company'];
            break;
        case isMatchPath(diagnosisEmail, pathname):
            selectedMenus = ['/diagnosis-email'];
            break;
        case isMatchPath(exportBusiness, pathname):
            selectedMenus = ['/export'];
            break;
        case isMatchPath(exportDiagnosis, pathname):
            selectedMenus = ['/export-diagnosis'];
            break;
        case isMatchPath(setting, pathname):
            selectedMenus = ['/setting'];
            break;
        case isMatchPath(loanManagement, pathname):
            selectedMenus = ['/loan-management'];
            break;
        case isMatchPath(subsidyManagement, pathname):
            selectedMenus = ['/subsidy-management'];
            break;
        case isMatchPath(grantManagement, pathname):
            selectedMenus = ['/grant-management'];
            break;
        default:
            selectedMenus = [pathname];
            break;
    }

    return selectedMenus;
};

export const activeClass = (condition: boolean, activeClassNames?: string | undefined, defaultClassNames?: string | undefined) => {
    return classNames({ active: condition }, { [`${activeClassNames}`]: condition }, defaultClassNames);
};

export const activeMenuClass = (condition: boolean, defaultClassNames?: string | undefined) => {
    return activeClass(condition, 'ant-menu-item-selected', defaultClassNames);
};

export const renderValidate = (messages: any, key: string) => {
    if (isObject(messages) && has(messages, key)) {
        return { validateStatus: 'error', help: get(messages, key) } as FormItemProps;
    }

    return { validateStatus: undefined, help: undefined } as FormItemProps;
};

export const getImageBase64 = (file: RcFile) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const handleFormData = (params: object) => {
    const formData = new FormData();
    mapKeys(params, (value, key) => {
        if (isArray(value)) {
            [...value].map((val) => formData.append(`${key}[]`, mbTrim(val)));
        } else {
            formData.append(key, mbTrim(value));
        }
        if (value === undefined) {
            formData.delete(key);
        }
    });
    return formData;
};

export const parseFileListUpload = (fileList: UploadFile[]) => {
    return getFileListUpload(fileList).filter((file) => {
        return file?.response?.success && file.status === 'done';
    });
};

export const getFileListUpload = (fileList: UploadFile[]) => {
    return fileList.map((file) => {
        if (file?.response?.success) {
            file.url = file.response?.data?.path;
        }
        return file;
    });
};

export const beforeUploadImage = (file: RcFile, formInstance: FormInstance, namePath: NamePath): Promise<boolean> => {
    return new Promise((resolve) => {
        const isJpgOrPng = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
        if (!isJpgOrPng) {
            formInstance.setFields([
                {
                    name: namePath,
                    errors: ['プロフィール画像の形式はpng、 jpg、 jpegです。'],
                    touched: true,
                },
            ]);
            return Promise.reject('プロフィール画像の形式はpng、 jpg、 jpegです。');
        }

        const isLt2M = file.size / 1024 / 1024 <= 2;
        if (!isLt2M) {
            formInstance.setFields([
                {
                    name: namePath,
                    errors: ['プロフィール画像の最大サイズは2MBです。'],
                    touched: true,
                },
            ]);
            return Promise.reject('プロフィール画像の最大サイズは2MBです。');
        }

        if (namePath === 'certificate_file') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', (event: any) => {
                const image = document.createElement('img');
                image.src = event.target.result;
                image.addEventListener('load', () => {
                    const { width, height } = image;
                    if (width === 500 && height === 500) {
                        resolve(true);
                        return Promise.resolve(true);
                    } else {
                        formInstance.setFields([
                            {
                                name: namePath,
                                errors: ['認証・認定マークのファイルは最小500x500 pxのサイズになります。'],
                                touched: true,
                            },
                        ]);
                        return Promise.reject('認証・認定マークのファイルは最小500x500 pxのサイズになります。');
                    }
                });
            });
        } else {
            resolve(true);
            return Promise.resolve(true);
        }
    });
};

export const beforeUploadFilePdfOrWord = (file: RcFile, formInstance: FormInstance, namePath: NamePath): Promise<boolean> => {
    return new Promise((resolve) => {
        const isPdfFile = [
            'application/pdf',
            'application/doc',
            'application/docx',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ].includes(file.type);
        if (!isPdfFile) {
            formInstance.setFields([
                {
                    name: namePath,
                    errors: ['事業所案内の形式はpdfです。'],
                    touched: true,
                },
            ]);
        }
        const isLt2M = file.size / 1024 / 1024 <= 2;
        if (!isLt2M) {
            formInstance.setFields([
                {
                    name: namePath,
                    errors: ['事業所案内の最大サイズは2MBです。'],
                    touched: true,
                },
            ]);
        }
        if (isPdfFile && isLt2M) {
            resolve(true);
            return Promise.resolve(true);
        }
        return Promise.reject(false);
    });
};

export const beforeUploadFileExelOrCsv = (file: RcFile): Promise<boolean> => {
    return new Promise((resolve) => {
        const isExcelOrCsv = [
            'text/csv',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
        ].includes(file.type);
        const isLt2M = file.size / 1024 / 1024 <= 2;
        if (isExcelOrCsv && isLt2M) {
            resolve(true);
            return Promise.resolve(true);
        }
        return Promise.reject(false);
    });
};

export function useFormValidate<S = undefined>(form: FormInstance): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
    const [messageErrors, setMessageErrors] = useState<S>();

    useEffect(() => {
        if (!isEmpty(messageErrors) && isObject(messageErrors)) {
            const firstKeyError = first(keysIn(messageErrors));
            isString(firstKeyError) && form.scrollToField(firstKeyError.replace(/\.[0-9]/g, ''), { behavior: 'smooth' });
            form.setFields(
                keysIn(messageErrors).map((key) => {
                    return {
                        name: key.replace(/\.[0-9]/g, ''),
                        errors: get(messageErrors, key),
                        touched: true,
                    };
                }),
            );
        }
    }, [form, messageErrors]);

    return [messageErrors, setMessageErrors];
}

export const mbTrim = (value?: any) => {
    if (isNumber(value) || (!isEmpty(value) && !isString(value))) {
        return value;
    }
    if (isEmpty(value)) {
        return '';
    }
    return value.replace(/^[\s　]+/u, '').replace(/[\s　]+$/u, '');
};

export const parseUrlToUploadFile = (url: string | string[], path?: string | string[]) => {
    const urls = isArray(url) ? url : [url];
    const paths = isArray(path) ? path : [path];
    return urls.map((uri, index) => {
        const path = paths.find((_, key) => key === index);
        return {
            uid: uri,
            name: parseFileName(path) ?? uri,
            url: uri,
            status: 'done',
            response: { success: true, data: { name: path, path: uri } },
        };
    }) as UploadFile[];
};

const parseFileName = (path?: string) => {
    if (!path || !path.includes('/')) {
        return null;
    }

    return last(path.split('/')) ?? null;
};

export function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
}

export const enabledStartTime = () => {
    const data = [];
    for (let i = 0; i < 24; i++) {
        data.push(i);
    }
    let removeIndex = [10, 11, 12, 13, 14, 15, 16];

    return data.filter((value) => !removeIndex.includes(value));
};

export const enabledEndTime = (hour: number) => {
    const data = [];
    for (let i = 0; i < 24; i++) {
        data.push(i);
    }
    let removeIndex = [hour];

    return data.filter((value) => !removeIndex.includes(value));
};

export const downloadCsv = (csvData: string) => {
    const blob = new Blob([csvData], {
        type: 'text/csv;',
    });
    const fileName = 'data.csv';
    const blobUrl = window.URL.createObjectURL(blob);
    const csvLink = document.createElement('a');
    csvLink.href = blobUrl;
    csvLink.download = fileName;
    document.body.appendChild(csvLink);
    csvLink.click();
    document.body.removeChild(csvLink);
    window.URL.revokeObjectURL(blobUrl);
};

export const htmlDecode = (rawHtml: string) => {
    return new DOMParser().parseFromString(rawHtml, 'text/html').documentElement.textContent ?? '';
};

export const ellipsisStr = (str: string, maxLength: number = 15) => {
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
};

export const getStatus = (content?: string) => {
    let value;
    switch (content) {
        case '面談予約済み':
            value = {
                color: '#E6F7FF',
                showCard: StatusAppointment.SELECTED,
                status: '面談予約済み',
            } as AppointmentStatus;
            break;
        case 'キャンセル':
            value = {
                color: '#FCE6E6',
                showCard: StatusAppointment.CANCELLED,
                status: 'キャンセル',
            } as AppointmentStatus;
            break;
        case '専門家選択':
            value = {
                color: '#F1EBEB',
                showCard: StatusAppointment.SELECTING,
                status: '専門家選択',
            } as AppointmentStatus;
            break;
        case 'マッチングなし':
            value = {
                color: '#F2EEFF',
                showCard: StatusAppointment.NO_MATCH,
                status: 'マッチングなし',
            } as AppointmentStatus;
            break;
        case 'マッチング中':
            value = {
                color: '#FFF5EC',
                showCard: StatusAppointment.MATCHED,
                status: 'マッチング中',
            } as AppointmentStatus;
            break;
        case '中企団相談窓口ご案内中':
            value = {
                color: '#F2EEFF',
                showCard: StatusAppointment.CHKD_WAITING,
                status: '中企団相談窓口ご案内中',
            } as AppointmentStatus;
            break;
        case '面談日程確認待ち':
            value = {
                color: '#FFF5EC',
                showCard: StatusAppointment.CHKD_SELECTING,
                status: '面談日程確認待ち',
            } as AppointmentStatus;
            break;
        case '面談済み':
            value = {
                color: '#F6FFED',
                showCard: StatusAppointment.FINISHED,
                status: '面談済み',
            } as AppointmentStatus;
            break;
        case '回答期限切れ':
            value = {
                color: '#F2EEFF',
                showCard: StatusAppointment.PAST_DUE,
                status: '回答期限切れ',
            } as AppointmentStatus;
            break;
        case '未確認':
            value = {
                color: '#fff8d4',
                showCard: StatusAppointment.UNCONFIRMED,
                status: '未確認',
            } as AppointmentStatus;
            break;
        case '企業からの返事待ち':
            value = {
                color: '#F1EBEB',
                showCard: StatusAppointment.WAITING_CONF,
                status: '企業からの返事待ち',
            } as AppointmentStatus;
            break;
        default:
            break;
    }
    return value;
};

export function CustomUploadAdapterPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        return new CustomUploadAdapter(loader);
    };
}

export const downloadBlob = (blob: Blob, filename: string) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.setAttribute('download', filename);
    link.click();
};

export const isHandledBySoken = (consultation?: DataExchange) => {
    return (
        (consultation?.policy_type && APPLICATION_POLICY_TYPE_LABELS.includes(consultation?.policy_type))
        || [
            ConsultationStatuses.REQUESTING,
            ConsultationStatuses.WAITING_CONF,
        ].includes(consultation?.status?.content as ConsultationStatuses)
        || consultation?.selected_specialist?.role === 'chukidan-sharoushi'
    );
};

export const countFormatter = ({ count, maxLength }: { count: number, maxLength?: number }) => {
    return `${count}/${maxLength}`;
};

export const useParamsState = <T = string>(
    key: string,
    defaultValue: any = null,
    transform: ((value: any) => any) = (value) => value,
): [T, (value: T) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    return [
        useMemo(
            () => transform(searchParams.get(key) ?? defaultValue),
            [defaultValue, key, searchParams, transform],
        ),
        useCallback((value: T) => {
            const query = value as any;

            if (searchParams.get(key) !== query) {
                setSearchParams({ ...Object.fromEntries(searchParams), [key]: query });
            }
        }, [key, searchParams, setSearchParams]),
    ];
};
