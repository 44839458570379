import {
    ApproveAccountRequest,
    BusinessConsultant,
    BusinessExchange,
    RegisterBusinessConsultants,
    SendMailRequest,
    UpdateDiagnosticianRequest,
} from '../types';
import { getAxiosClientWithToken } from './index';
import { handleFormData } from '../lib/utils';

export const getBusinessExchange = (uuid: string, params: BusinessExchange) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/users/${uuid}/interviews`, { params });
};

export const getBusinessConsultants = (params?: BusinessConsultant) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/users', { params });
};

export const getDiagnosticianDetail = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/users/${uuid}`);
};

export const updateDiagnostician = (params: UpdateDiagnosticianRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/users/${params.uuid}`, formData);
};

export const deleteBusinessConsultants = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/users/${uuid}`);
};

export const detailsBusinessConsultants = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/users/${uuid}`);
};

export const sendMail = (params: SendMailRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/mail/queue/invite-register', { params });
};

export const createBusinessConsultants = (params: RegisterBusinessConsultants) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/users`, formData);
};

export const getEvaluation = (params: { uuid?: string; page: number }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/users/${params.uuid}/evaluation`, {
        params: {
            page: params.page,
        },
    });
};

export const approveDiagnostician = ({
    uuid,
    secretary_number,
    allow_company_referrals,
    allow_specialist_search,
    rank,
}: ApproveAccountRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData({ secretary_number, allow_company_referrals, allow_specialist_search, rank });
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/users/${uuid}/approve`, formData);
};
