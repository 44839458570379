import { getAxiosClientWithToken } from './index';
import { ApplicationRequest } from 'app/slice/applicationsSlice';

export const getApplications = (params: ApplicationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/applications', { params });
};

export const exportApplications = (params: ApplicationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/applications/export', {
        params: params,
        responseType: 'arraybuffer',
    });
};