import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEmailExpired } from 'api/emailExpired';
import { RootState } from 'app/store';

/**
 * GET API Email Expired
 **/
export const fetchEmailExpired = createAsyncThunk('user/register-expired', async (params: string, { dispatch, rejectWithValue }) => {
    try {
        const response = await getEmailExpired(params);
        const { success } = response.data;
        if (success) {
            dispatch(setEmailExpired(response.data));
            return true;
        }

        return rejectWithValue(false);
    } catch (err) {
        return rejectWithValue(false);
    }
});
export type Data = {
    data: boolean;
    success: boolean;
    message: string | object;
    loading: boolean;
    error: boolean;
};

export const emailExpiredSlice = createSlice({
    name: 'emailExpired',
    initialState: {
        emailExpired: {
            success: false,
            data: false,
        } as Data,
    },

    reducers: {
        setEmailExpired: (state: any, action) => {
            state.emailExpired = action.payload as {};
        },
    },
});

export const { setEmailExpired } = emailExpiredSlice.actions;
export const emailExpiredSelector = (state: RootState) => state.emailExpired;
