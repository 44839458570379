import React, { CSSProperties, useEffect, useState, useCallback } from 'react';
import { Constant } from '../../config/constant';
import classNames from 'classnames';
import { Dropdown, Layout, Menu, Button } from 'antd';
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout, selectAuth } from '../../app/slice/authSlice';
import { Outlet, useLocation, useNavigate, Link } from 'react-router-dom';
import { useIsMobile, useStyle } from '../../lib/utils';
import Sidebar from './Sidebar';

const { Header, Content } = Layout;

type Props = {
    containerClass?: string;
    contentStyle?: CSSProperties;
};

/**
 * Creates Base Layout
 * @param { Props } props
 * @return html
 * */
const BaseLayout = (props: Props) => {
    useStyle('dashboard');

    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const { token, name } = useAppSelector(selectAuth);
    const { containerClass = '', contentStyle } = props;
    const onCloseCollapsed = useCallback(() => isMobile && setIsCollapsed(true), [isMobile]);

    const onLogout = () => {
        if (!window.confirm('Are you sure do you want to logout?')) return;

        dispatch(logout());
        navigate('/login');
    };

    const accountDropdown = (
        <Menu className='rounded-3 px-1'>
            <Menu.Item icon={<UserOutlined className='fs-6 me-2' />}>
                <Link to='/detail-profile'>プロフィール</Link>
            </Menu.Item>
            <Menu.Item onClick={onLogout} icon={<LogoutOutlined className='fs-6 me-2' />}>
                ログアウト
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        if (!token) {
            navigate('/login', { state: { from: location }, replace: true });
        }
    }, [token, location, navigate]);

    return (
        <Layout hasSider className='base-layout-container'>
            <Sidebar
                key={+isMobile}
                breakpoint='md'
                collapsedWidth='0'
                collapsed={isCollapsed}
                onCollapse={(collapsed) => setIsCollapsed(collapsed)}
                onCloseCollapsed={onCloseCollapsed}
            />
            <Layout className='site-layout' style={{ marginLeft: isMobile ? 0 : Constant.SIDEBAR_WIDTH }} onClick={onCloseCollapsed}>
                <Header className='site-layout-sub-header-background site-default-header position-sticky top-0'>
                    <div className='float-end'>
                        <Dropdown arrow overlay={accountDropdown} placement='bottomRight' trigger={['click']}>
                            <Button type='link' className='btn-toggle-account-menu'>
                                {name} <DownOutlined className='icon-down fs-6 ms-2 align-middle' />
                            </Button>
                        </Dropdown>
                    </div>
                </Header>
                <Content className={classNames('px-5 py-4 background-white', containerClass)} style={contentStyle}>
                    <div className='site-layout-background'>
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default BaseLayout;
