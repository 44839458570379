import { getAxiosClientWithToken } from 'api';
import { UpdateMatchingSettingRequest } from 'types';

export const getMatchingSetting = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/settings/show');
};

export const updateMatchingSetting = (params: UpdateMatchingSettingRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/settings', params);
};
