import { TrashParams } from 'types';
import { getAxiosClientWithToken } from './index';

export const getBusinessConsultingTrash = (params: TrashParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/users', { params });
};

export const restoreTrash = (userId: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/users/${userId}/restore`);
};

export const deleteTrash = (userId: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`admin/users/${userId}/delete`);
};
