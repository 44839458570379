import { Button, Form, Input, Layout, Spin, Alert } from 'antd';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { Rule } from 'antd/lib/form';
import { useStyle } from '../../lib/utils';
import { Header } from 'antd/lib/layout/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthRequest } from '../../app/slice/authSlice';
import { login, selectLogin, reset } from '../../app/slice/loginSlice';

const LoginPage = () => {
    useStyle('labor-social');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { success, message, loading } = useAppSelector(selectLogin);
    const location = useLocation();

    const onFormSubmit = async (values: AuthRequest) => {
        const { email, password } = values;
        dispatch(login({ email, password }));
    };

    const emailRules: Rule[] = [
        {
            required: true,
            message: 'メールアドレスは必須項目です。',
            type: 'email',
        },
    ];

    const passwordRules = [
        {
            required: true,
            message: 'パスワードは必須項目です。',
        },
    ];

    useEffect(() => {
        if (success) {
            // @ts-ignore
            const from = location?.state?.from?.pathname ?? '/';
            dispatch(reset());
            navigate(from, { replace: true });
        }
    }, [success, location, navigate, dispatch]);

    return (
        <Layout>
            <Header className='labor_social_navbar'>
                <div className='title'>
                    <span>中企団 案件ご紹介管理サイト</span>
                </div>
            </Header>
            <div className='ant-layout login-form-container'>
                <div className='login-form'>
                    <div className='login'>
                        <div className='title'>中小企業福祉事業団</div>
                        <div className='sub-title ant-form-item-label'>
                            <label className='ant-form-item-required'>は必須項目です</label>
                        </div>
                        <Form form={form} layout='vertical' onFinish={onFormSubmit} autoComplete='off'>
                            {!success && message && <Alert className='mb-3' message={message} type='error' showIcon closable />}
                            <Form.Item name='email' label='メールアドレス' rules={emailRules}>
                                <Input />
                            </Form.Item>
                            <Form.Item name='password' label='パスワード' rules={passwordRules}>
                                <Input.Password />
                            </Form.Item>
                            <Button type='primary' htmlType='submit' className='w-100 mt-5' disabled={loading}>
                                {loading ? <Spin size='small' /> : 'ログイン'}
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default LoginPage;
