import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import {
    getListInterviewData,
    getCompanyManagementList,
    getCompanyProfile,
    getDetailExchange,
    postCancelInterview,
    postInterviewSchedule,
    getSpecialSearch,
} from 'api/business';
import {
    CompanyProfile,
    FetchState,
    CheckStatus,
    DataExchange,
    ExchangeParams,
    Pagination,
    ReasonParams,
    ChangeInterview,
    ListSearch,
    StatusState,
    DetailExchangeState,
    FetchCompanyRulesRequest,
    CancellationApplicationState,
    CancellationApplicationValidate,
} from 'types';
import { PER_PAGE_DEFAULT } from 'config/constant';

type DataArray = {
    firstname: string;
    id: number;
    lastname: string;
    office_name: string;
    secretary_number: string;
};

type DataSpecial = {
    success: boolean;
    data: DataArray[];
};
type BusinessState = {
    meetingList: MeetingList;
    company: CompanyListState;
    detailExchange: DataExchange;
    profile: Profile;
    reasonCancel: CancellationApplicationState;
    changeInterview: DetailExchangeState;
    secretaryNumber: DataSpecial;
    detail: DetailExchangeState;
    selectedSchedules: SelectedSchedules[]
};

interface MeetingList extends FetchState, Pagination {
    listInterview: ListInterview[];
}

interface CompanyListState extends FetchState, Pagination {
    companies: CompanyList[];
}

type Profile = {
    companyProfile: CompanyProfile;
    loading: boolean;
    success: boolean;
};

export type ListInterview = {
    schedule_id?: number;
    answer_time: string;
    application_id: number;
    company_id: number;
    company_name?: string;
    fund_title?: string;
    status?: StatusState;
    user_name: string;
    schedule_date?: string;
    type: number;
    status_id: number;
    lapses: boolean;
    show_date: boolean;
};

export type CompanyList = {
    id: number;
    company_name: string;
    email: string;
    manager_name: string;
    daido_employee_name: string;
    daido_employee_code: string;
    daido_branch_name: string;
    daido_branch_code: string;
};

export type CompanyDetailRequest = {
    uuid?: string;
    keyword?: string;
};

export type CompanyMeetingRequest = {
    uuid?: string;
    keyword?: string;
    status?: number;
    page?: number;
};

export type SelectedSchedules = {
    id: number,
    number: number,
    date: string,
    content: string,
}

export const getListMeeting = createAsyncThunk(
    '/admin/companies/:uuid/meetings',
    async (params: CompanyMeetingRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListInterviewData(params);
            const {
                data = [],
                success,
                current_page: currentPage = 1,
                last_page: lastPage = 1,
                per_page: perPage = PER_PAGE_DEFAULT,
                total = 0,
            } = response.data;

            if (success) {
                dispatch(setListMeetingData({ data, pagination: { lastPage, currentPage, perPage, total } as Pagination }));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//GET company management list
export const getListCompany = createAsyncThunk(
    '/admin/companies',
    async (params: FetchCompanyRulesRequest, { dispatch, rejectWithValue }) => {
        try {
            if (params.status === undefined || params.status === 2) {
                delete params.status;
            }
            const response = await getCompanyManagementList(params);
            const {
                data = [],
                success,
                current_page: currentPage = 1,
                last_page: lastPage = 1,
                per_page: perPage = PER_PAGE_DEFAULT,
                total = 0,
            } = response.data;
            if (success) {
                dispatch(setListCompanyData({ data, pagination: { lastPage, currentPage, perPage, total } as Pagination }));
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchCompanyProfile = createAsyncThunk(
    '/admin/companies/:uuid',
    async ({ uuid }: CompanyDetailRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getCompanyProfile(uuid);
            dispatch(setDetailCompanyData(response.data));
            return true;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//GET Detail Exchange
export const fetchDetailExchange = createAsyncThunk(
    '/admin/companies/detail',
    async (params: ExchangeParams, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailExchange(params);
            const { success } = response.data;
            if (success) {
                dispatch(setDetailExchange(response.data.data));
            }
            return true;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//POST Cancel Interview
export const reasonCancelInterview = createAsyncThunk(
    '/admin/companies/application/cancellation',
    async (params: ReasonParams, { dispatch, rejectWithValue }) => {
        try {
            let response = await postCancelInterview(params);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//POST Change Interview Schedule
export const changeInterviewSchedule = createAsyncThunk(
    '/admin/change/schedule ',
    async (params: ChangeInterview, { dispatch, rejectWithValue }) => {
        try {
            let response = await postInterviewSchedule(params);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const speciaListSearch = createAsyncThunk('/admin/specialists/search', async (params: ListSearch, { dispatch, rejectWithValue }) => {
    try {
        let response = await getSpecialSearch(params);
        const { success } = response.data;
        if (success) {
            dispatch(setSpecialSearch(response.data));
        }
        return success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

const initialState = {
    meetingList: {
        loading: false,
        success: false,
        error: false,
        total: 0,
        lastPage: 1,
        currentPage: 1,
        count: 0,
        perPage: PER_PAGE_DEFAULT,
        listInterview: [] as ListInterview[],
    },
    company: {
        loading: false,
        success: false,
        error: false,
        total: 0,
        lastPage: 1,
        currentPage: 1,
        count: 0,
        perPage: PER_PAGE_DEFAULT,
        companies: [] as CompanyList[],
    },
    detailExchange: {
        status: {} as CheckStatus,
    } as DataExchange,
    profile: {
        companyProfile: {} as CompanyProfile,
        loading: false,
        success: false,
    },
    reasonCancel: {
        loading: false,
        success: false,
        error: false,
        message: '',
    } as CancellationApplicationState,
    detail: {
        loading: false,
        success: false,
        error: false,
        message: '',
    } as DetailExchangeState,
    changeInterview: {
        loading: false,
        success: false,
        error: false,
        message: '',
    } as DetailExchangeState,
    secretaryNumber: {
        data: [] as DataArray[],
    } as DataSpecial,
    selectedSchedules: [] as SelectedSchedules[]
} as BusinessState;

export const businessSlice = createSlice({
    name: 'business',
    initialState: initialState,
    reducers: {
        setListMeetingData: (state: BusinessState, { payload }) => {
            state.meetingList.total = payload.pagination.total;
            state.meetingList.lastPage = payload.pagination.lastPage;
            state.meetingList.perPage = payload.pagination.perPage;
            state.meetingList.currentPage = payload.pagination.currentPage;
            state.meetingList.listInterview = payload.data;
        },
        setListCompanyData: (state: BusinessState, { payload }) => {
            state.company.companies = payload.data;
            state.company.total = payload.pagination.total;
            state.company.lastPage = payload.pagination.lastPage;
            state.company.perPage = payload.pagination.perPage;
            state.company.currentPage = payload.pagination.currentPage;
        },
        setDetailExchange: (state, actions) => {
            state.detailExchange = actions.payload as DataExchange;
        },
        setDetailCompanyData: (state, { payload }) => {
            state.profile.companyProfile = payload.data;
        },
        resetCancelInterview: (state: any) => {
            state.reasonCancel = {
                loading: false,
                success: false,
                error: false,
                message: '',
            } as CancellationApplicationState;
        },
        resetInterviewSchedule: (state: any) => {
            state.changeInterview = {
                loading: false,
                success: false,
                error: false,
                message: '',
            } as DetailExchangeState;
        },
        setSpecialSearch: (state, action) => {
            state.secretaryNumber = action?.payload as DataSpecial;
        },
        setSchedules: (state, { payload }) => {
            const schedules: SelectedSchedules[] = [];
            payload?.forEach((item: SelectedSchedules) => {
                let newDate;
                let newTime;
                let schedDateAndTime = item.content.split('　');
                if (item.content.includes('（')) {
                    const splitDate = item.content.split('）');
                    newDate = splitDate[0] + '）';
                    newTime = splitDate[1];
                } else {
                    newDate = schedDateAndTime[0];
                    newTime = schedDateAndTime[1];
                }
                schedules.push({
                    id: item.number,
                    number: item.number,
                    date: newDate,
                    content: newTime,
                });
            });

            state.selectedSchedules = schedules;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyProfile.pending, (state) => {
                state.profile = {
                    ...state.profile,
                    loading: true,
                };
            })
            .addCase(fetchCompanyProfile.fulfilled, (state) => {
                state.profile = {
                    ...state.profile,
                    loading: false,
                    success: true,
                };
            })
            .addCase(fetchCompanyProfile.rejected, (state) => {
                state.profile = {
                    ...state.profile,
                    loading: false,
                    success: false,
                };
            })

            .addCase(changeInterviewSchedule.pending, (state) => {
                state.changeInterview.loading = true;
            })
            .addCase(changeInterviewSchedule.fulfilled, (state) => {
                state.changeInterview.loading = false;
                state.changeInterview.success = true;
                state.changeInterview.error = false;
            })
            .addCase(changeInterviewSchedule.rejected, (state) => {
                state.changeInterview.loading = false;
                state.changeInterview.success = false;
                state.changeInterview.error = true;
            })
            .addCase(getListCompany.pending, (state: BusinessState) => {
                state.company.loading = true;
            })
            .addCase(getListCompany.rejected, (state: BusinessState) => {
                state.company.loading = false;
                state.company.success = false;
                state.company.companies = [];
            })
            .addCase(getListCompany.fulfilled, (state: BusinessState) => {
                state.company.loading = false;
                state.company.success = true;
            })
            .addCase(getListMeeting.pending, (state: BusinessState) => {
                state.meetingList.loading = true;
            })
            .addCase(getListMeeting.rejected, (state: BusinessState) => {
                state.meetingList.loading = false;
                state.meetingList.success = false;
                state.meetingList.listInterview = [];
            })
            .addCase(getListMeeting.fulfilled, (state: BusinessState) => {
                state.meetingList.loading = false;
                state.meetingList.success = true;
            })
            .addCase(reasonCancelInterview.pending, (state) => {
                state.reasonCancel.loading = true;
            })
            .addCase(reasonCancelInterview.fulfilled, (state) => {
                state.reasonCancel.loading = false;
                state.reasonCancel.success = true;
                state.reasonCancel.error = false;
            })
            .addCase(reasonCancelInterview.rejected, (state, { payload }) => {
                state.reasonCancel.loading = false;
                state.reasonCancel.success = false;
                state.reasonCancel.error = true;
                state.reasonCancel.message = payload as string | CancellationApplicationValidate;
            })
            .addCase(fetchDetailExchange.pending, (state) => {
                state.detail.loading = true;
            })
            .addCase(fetchDetailExchange.fulfilled, (state) => {
                state.detail.loading = false;
                state.detail.success = true;
                state.detail.error = false;
            })
            .addCase(fetchDetailExchange.rejected, (state, { payload }) => {
                state.detail.loading = false;
                state.detail.success = false;
                state.detail.error = true;
                state.detail.message = payload as string;
            });
    },
});

export const {
    setListMeetingData,
    setListCompanyData,
    setDetailExchange,
    setDetailCompanyData,
    resetCancelInterview,
    resetInterviewSchedule,
    setSpecialSearch,
    setSchedules
} = businessSlice.actions;
export const businessSelector = (state: RootState) => state.business;
