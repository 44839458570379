import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { getSearchLogs, downloadSearchLogs } from 'api/searchLog';

type Pagination = {
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number
}

type SearchLogState = {
    success: boolean | null,
    downloadSuccess: boolean | null,
    loading: boolean,
    downloadLoading: boolean,
    data: Data[],
    downloadData: Blob | null,
    pagination: Pagination
}

type Data = {
    created_at: string,
    type: string,
    company: {
        name: string | null
    }
    purpose:  {
        name: string
    }
    email: string | null,
    keyword: string
}

export type SearchLogRequest = {
    from?: string,
    to?: string,
    page?: number,
    per_page?: number,
}

export const getSearchLogData = createAsyncThunk(
    '/admin/search-log',
    async (params: SearchLogRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSearchLogs(params);
            dispatch(setData(response.data));
            dispatch(setPagination(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const downloadSearchLog = createAsyncThunk(
    '/admin/download/search-log',
    async (params: SearchLogRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await downloadSearchLogs(params);
            dispatch(setDownloadData(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const searchLogSlice = createSlice({
    name: 'searchLog',
    initialState: {
        success: null,
        downloadSuccess: null,
        downloadLoading: false,
        loading: false,
        data: [],
        downloadData: null,
        pagination: {} as Pagination,
    }  as SearchLogState,
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload.data.map((data: Data) => ({
                created_at: data.created_at,
                type: data.type,
                company: {
                    name: data?.company.name
                },
                purpose: {
                    name: data?.purpose.name
                },
                email: data?.email,
                keyword: data.keyword
            })); 
        },
        setDownloadData: (state, { payload }) => {
            state.downloadData = payload
        },
        resetSearchLog: (state) => {
            state.success = null;
            state.loading = false;
            state.data = [];
        },

        setPagination: (state, { payload }) => {
            state.pagination = {
                currentPage: payload.current_page,
                lastPage: payload.last_page,
                perPage: payload.per_page,
                total: payload.total,
            };
        },
        resetPagination: (state) => {
            state.pagination = {} as Pagination;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSearchLogData.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.data = [];
        });
        builder.addCase(getSearchLogData.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getSearchLogData.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(downloadSearchLog.pending, (state) => {
            state.downloadLoading = true;
            state.downloadSuccess = false;
            state.downloadData = null;
        });
        builder.addCase(downloadSearchLog.fulfilled, (state) => {
            state.downloadLoading = false;
            state.downloadSuccess = true;
        });
        builder.addCase(downloadSearchLog.rejected, (state) => {
            state.downloadLoading = false;
            state.downloadSuccess = false;
        });
    },
});

export const { resetSearchLog, setPagination, setData, setDownloadData, resetPagination } = searchLogSlice.actions;
export const selectSearchLog = (state: RootState) => state.searchLog;
