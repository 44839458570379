import { FetchNotificationList } from 'types';
import { getAxiosClientWithToken } from './index';

export const trashNotiMoney = (params: FetchNotificationList) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/funds/trash', params);
};

export const trashSubsidyMoney = (fundId: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/funds/${fundId}`);
};

export const trashPermanentlyDelete = (fundId: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/funds/trash/${fundId}/delete`);
};

export const trashRecovery = (fundId: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/funds/trash/${fundId}/recovery`);
};
