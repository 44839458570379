import { getAxiosClientWithToken } from './index';
import {
    CreateMasterDataRequest,
    DeleteMasterDataRequest,
    MasterDataSubsidiesRequest,
    UpdateMasterDataRequest,
} from '../app/slice/masterDataSubsidiesSlide';
import { handleFormData } from '../lib/utils';

export const getPurposes = (params: MasterDataSubsidiesRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/purposes', { params });
};

export const getHeadquarters = (params: MasterDataSubsidiesRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/resources/prefectures', { params });
};

export const editPurpose = (params: UpdateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/purposes/${params.code}`, formData);
};

export const editSearchIndustries = (params: UpdateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/search-industries/${params.code}`, formData);
};

export const editSearchEmployeeCountRanges = (params: UpdateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/search-employee-count-ranges/${params.code}`, formData);
};

export const editCoverageType = (params: UpdateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/coverage-types/${params.code}`, formData);
};

export const editCapitalRange = (params: UpdateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/capital-ranges/${params.code}`, formData);
};

export const editYearsEstablishedRanges = (params: UpdateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/years-established-ranges/${params.code}`, formData);
};

export const editPrefectures = (params: UpdateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/prefectures/${params.code}`, formData);
};

export const storePurpose = (params: CreateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/purposes`, formData);
};

export const storeSearchIndustries = (params: CreateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/search-industries`, formData);
};

export const storeSearchEmployeeCountRanges = (params: CreateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/search-employee-count-ranges`, formData);
};

export const storeCoverageType = (params: CreateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/coverage-types`, formData);
};

export const storeCapitalRange = (params: CreateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/capital-ranges`, formData);
};

export const storeYearsEstablishedRanges = (params: CreateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/years-established-ranges`, formData);
};

export const storePrefectures = (params: CreateMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/prefectures`, formData);
};

export const destroyPurpose = (params: DeleteMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/purposes/${params.code}`);
};

export const destroySearchIndustries = (params: DeleteMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/search-industries/${params.code}`);
};

export const destroySearchEmployeeCountRanges = (params: DeleteMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/search-employee-count-ranges/${params.code}`);
};

export const destroyCoverageType = (params: DeleteMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/coverage-types/${params.code}`);
};

export const destroyCapitalRange = (params: DeleteMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/capital-ranges/${params.code}`);
};

export const destroyYearsEstablishedRanges = (params: DeleteMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/years-established-ranges/${params.code}`);
};

export const destroyPrefectures = (params: DeleteMasterDataRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/prefectures/${params.code}`);
};
