import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import {
    createNotification,
    destroyNotificationDetail,
    editNotification,
    getNotificationDetail,
    getNotificationList,
} from 'api/notification';
import { FetchNotificationList, NotificationList, NotificationDetail, NotificationParams, DetailExchangeState, FetchState } from 'types';
import { PER_PAGE_DEFAULT } from 'config/constant';

export type NotificationData = {
    notificationList: {
        loadingList: boolean;
        success: boolean;
        error: boolean;
        total: number;
        lastPage: number;
        currentPage: number;
        perPage: number;
        data: NotificationList[];
    };
    notificationDetail: NotificationDetail;
    createNewNotification: DetailExchangeState;
    deleteNotification: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    updateNotification: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    fetchNotificationDetail: FetchState;
};

export type UpdateNotificationRequest = {
    id?: string | number;
    title: string;
    category: number;
    content: string;
    type: number;
    status: number;
};

//GET notification list
export const getListNotification = createAsyncThunk(
    '/admin/notifications/list',
    async (params: FetchNotificationList, { dispatch, rejectWithValue }) => {
        try {
            const response = await getNotificationList(params);
            const { success } = response.data;

            if (success) {
                dispatch(setListNotificationData(response.data));
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//GET notification detail
export const getDetailNotification = createAsyncThunk(
    '/admin/notifications/:uuid',
    async ({ uuid }: { uuid?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getNotificationDetail(uuid);
            const { success } = response.data;
            if (success) {
                dispatch(setDetailNotification(response.data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//POST notifications
export const postNotification = createAsyncThunk(
    '/admin/notifications/create',
    async (params: NotificationParams, { dispatch, rejectWithValue }) => {
        try {
            let response = await createNotification(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setNewNotification(data));
                return true;
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deleteNotification = createAsyncThunk(
    '/admin/notifications/delete/:uuid',
    async (uuid: number, { dispatch, rejectWithValue }) => {
        try {
            let response = await destroyNotificationDetail(uuid);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateNotification = createAsyncThunk(
    '/admin/notifications/update/:uuid',
    async (params: UpdateNotificationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await editNotification(params);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        notificationList: {
            loadingList: false,
            success: false,
            error: false,
            total: 0,
            lastPage: 1,
            currentPage: 1,
            perPage: PER_PAGE_DEFAULT,
            data: [] as NotificationList[],
        },
        notificationDetail: {} as NotificationDetail,
        createNewNotification: {
            loading: false,
            success: false,
            error: false,
            message: '',
        } as DetailExchangeState,
        deleteNotification: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        updateNotification: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        fetchNotificationDetail: {
            loading: false,
            success: false,
            error: false,
        },
    } as NotificationData,
    reducers: {
        setListNotificationData: (state, { payload }) => {
            state.notificationList.total = payload.total;
            state.notificationList.perPage = payload.per_page;
            state.notificationList.currentPage = payload.current_page;
            state.notificationList.lastPage = payload.lastPage;
            state.notificationList.data = payload.data;
        },
        setDetailNotification: (state, { payload }) => {
            state.notificationDetail = payload.data;
        },
        setNewNotification: (state, action) => {
            state.createNewNotification = action?.payload ?? [];
        },
        resetDeleteNotificationState: (state) => {
            state.deleteNotification.loading = false;
            state.deleteNotification.success = false;
            state.deleteNotification.error = false;
            state.deleteNotification.message = '';
        },
        resetUpdateNotificationState: (state) => {
            state.updateNotification.loading = false;
            state.updateNotification.success = false;
            state.updateNotification.error = false;
            state.updateNotification.message = '';
        },
        resetCreateNotificationState: (state) => {
            state.createNewNotification.loading = false;
            state.createNewNotification.success = false;
            state.createNewNotification.error = false;
            state.createNewNotification.message = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListNotification.pending, (state: NotificationData) => {
                state.notificationList.loadingList = true;
            })
            .addCase(getListNotification.rejected, (state: NotificationData) => {
                state.notificationList.loadingList = false;
                state.notificationList.success = false;
                state.notificationList.data = [];
            })
            .addCase(getListNotification.fulfilled, (state: NotificationData) => {
                state.notificationList.loadingList = false;
                state.notificationList.success = true;
            })
            .addCase(postNotification.pending, (state: NotificationData) => {
                state.createNewNotification.loading = true;
            })
            .addCase(postNotification.fulfilled, (state: NotificationData) => {
                state.createNewNotification.loading = false;
                state.createNewNotification.success = true;
                state.createNewNotification.error = false;
            })
            .addCase(postNotification.rejected, (state: NotificationData, { payload }) => {
                state.createNewNotification.loading = false;
                state.createNewNotification.success = false;
                state.createNewNotification.error = true;
                state.createNewNotification.message = payload as string;
            })
            .addCase(deleteNotification.pending, (state: NotificationData) => {
                state.deleteNotification.loading = true;
            })
            .addCase(deleteNotification.fulfilled, (state: NotificationData) => {
                state.deleteNotification.loading = false;
                state.deleteNotification.success = true;
                state.deleteNotification.error = false;
            })
            .addCase(deleteNotification.rejected, (state: NotificationData, { payload }) => {
                state.deleteNotification.loading = false;
                state.deleteNotification.success = false;
                state.deleteNotification.error = true;
                state.deleteNotification.message = payload as string;
            })
            .addCase(updateNotification.pending, (state: NotificationData) => {
                state.updateNotification.loading = true;
            })
            .addCase(updateNotification.fulfilled, (state: NotificationData) => {
                state.updateNotification.loading = false;
                state.updateNotification.success = true;
                state.updateNotification.error = false;
            })
            .addCase(updateNotification.rejected, (state: NotificationData, { payload }) => {
                state.updateNotification.loading = false;
                state.updateNotification.success = false;
                state.updateNotification.error = true;
                state.updateNotification.message = payload as string;
            })
            .addCase(getDetailNotification.pending, (state: NotificationData) => {
                state.fetchNotificationDetail.loading = true;
            })
            .addCase(getDetailNotification.fulfilled, (state: NotificationData) => {
                state.fetchNotificationDetail.loading = false;
                state.fetchNotificationDetail.success = true;
                state.fetchNotificationDetail.error = false;
            })
            .addCase(getDetailNotification.rejected, (state: NotificationData) => {
                state.fetchNotificationDetail.loading = false;
                state.fetchNotificationDetail.success = false;
                state.fetchNotificationDetail.error = true;
            });
    },
});

export const {
    setListNotificationData,
    setDetailNotification,
    setNewNotification,
    resetDeleteNotificationState,
    resetUpdateNotificationState,
    resetCreateNotificationState,
} = notificationSlice.actions;
export const notificationSelector = (state: RootState) => state.notification;
