import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { createCategory, editCategory, getCategoriesList, deleteCategory, getAllCategory } from 'api/categories';
import {
    CategoryData,
    UpdateNotificationCategoryRequest,
    PageCategory,
    RestoreTrashManagement,
    StoreNotificationCategoryRequest,
} from 'types';
import { mbTrim } from '../../lib/utils';

export type CategoriesManagementData = {
    categoriesList: {
        loading?: boolean;
        success: boolean;
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        data: CategoryData[];
    };
    allCategory: CategoryData[];
    categoriesResponse: RestoreTrashManagement;
};

//GET  categories list
export const getListCategories = createAsyncThunk('/admin/categories', async (params: PageCategory, { dispatch, rejectWithValue }) => {
    try {
        const response = await getCategoriesList(params);
        const { success } = response.data;
        if (success) {
            dispatch(setListCategoriesData(response.data));
            return true;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const getAllCategoriesForNotification = createAsyncThunk(
    '/admin/categories/all',
    async (type: number, { dispatch, rejectWithValue }) => {
        try {
            const response = await getAllCategory(type);
            const { success, data } = response.data;
            if (success) {
                dispatch(setAllCategoriesData(data));
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * POST Create Category
 **/
export const createNewCategory = createAsyncThunk(
    '/admin/categories/create',
    async (params: StoreNotificationCategoryRequest, { rejectWithValue }) => {
        try {
            params.name = mbTrim(params.name);
            const response = await createCategory(params);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * POST Edit Category
 **/
export const updateCategory = createAsyncThunk(
    '/admin/categories/edit',
    async (params: UpdateNotificationCategoryRequest, { rejectWithValue }) => {
        try {
            const response = await editCategory(params);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//DELETE category
export const removeCategory = createAsyncThunk(
    '/admin/categories/:uuid',
    async ({ uuid }: { uuid?: number }, { dispatch, rejectWithValue }) => {
        try {
            const response = await deleteCategory(uuid);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        categoriesList: {
            loading: false,
            success: false,
            total: 0,
            per_page: 0,
            current_page: 0,
            last_page: 0,
            data: [],
        },
        allCategory: [],
        categoriesResponse: {},
    } as CategoriesManagementData,
    reducers: {
        setListCategoriesData: (state, { payload }) => {
            state.categoriesList = payload;
        },
        resetCategoriesData: (state) => {
            state.categoriesResponse = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        setDeleteCategory: (state) => {
            state.categoriesResponse = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        setAllCategoriesData: (state, action) => {
            state.allCategory = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListCategories.pending, (state: CategoriesManagementData) => {
                state.categoriesList.loading = true;
            })
            .addCase(getListCategories.rejected, (state: CategoriesManagementData) => {
                state.categoriesList.loading = false;
                state.categoriesList.success = false;
                state.categoriesList.data = [];
            })
            .addCase(getListCategories.fulfilled, (state: CategoriesManagementData) => {
                state.categoriesList.loading = false;
                state.categoriesList.success = true;
            })
            .addMatcher(
                isAnyOf(createNewCategory.pending, updateCategory.pending, removeCategory.pending),
                (state: CategoriesManagementData) => {
                    state.categoriesResponse.loading = true;
                }
            )
            .addMatcher(
                isAnyOf(createNewCategory.fulfilled, updateCategory.fulfilled, removeCategory.fulfilled),
                (state: CategoriesManagementData) => {
                    state.categoriesResponse.loading = false;
                    state.categoriesResponse.success = true;
                    state.categoriesResponse.error = false;
                }
            )
            .addMatcher(
                isAnyOf(createNewCategory.rejected, updateCategory.rejected, removeCategory.rejected),
                (state: CategoriesManagementData, { payload }) => {
                    state.categoriesResponse.loading = false;
                    state.categoriesResponse.success = false;
                    state.categoriesResponse.error = true;
                    state.categoriesResponse.message = payload as string | object;
                }
            );
    },
});

export const { setListCategoriesData, resetCategoriesData, setDeleteCategory, setAllCategoriesData } = categoriesSlice.actions;
export const categoriesSelector = (state: RootState) => state.categories;
