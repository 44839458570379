import axios, { AxiosError } from 'axios';
import { store } from 'app/store';
import { isDevMode } from 'lib/utils';

const BASE_URL = process.env.REACT_APP_API_URL;

export const UPLOAD_FILE_URL = `${BASE_URL}/upload-file`;

/**
 * Call axios.create without token on the header
 **/
export const getAxiosClient = () => {
    return axios.create({
        baseURL: BASE_URL,
    });
};

/**
 * Call axios.create and append header with token
 **/
export const getAxiosClientWithToken = () => {
    const client = getAxiosClient();
    client.defaults.headers.common['Authorization'] = 'Bearer ' + store.getState().auth.token;
    client.defaults.headers.common['Content-Type'] = 'application/json';
    client.defaults.headers.common['Accept'] = 'application/json';
    return client;
};

export const getErrorMessage = (error: AxiosError) => {
    const { status, data } = error.response || {};

    if (status && status === 422) {
        return data?.errors ?? data.message;
    }
    if (status && status < 500) {
        return data.message;
    }

    if (isDevMode()) console.error(error);
    return 'Server Error';
};
