import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { getApplications } from 'api/applications';

type ApplicationState = {
    success: boolean | null,
    loading: boolean,
    data: Application[],
    pagination: Pagination
}

type Pagination = {
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

type ApplicationResponse = {
    created_at: string,
    company: {
        name: string,
        name_furigana: string,
        postal_code: string,
        prefecture: string,
        address: string,
        building_name: string,
        representative_position: string,
        industry: string,
        employee_count_range: string,
        parttime_employee_count: string,
        insurance_plans: string,
        labor_regulations_compliant: boolean
        allow_sharoushi: boolean
        allow_shindanshi: boolean
        daido_branch_name: string,
        daido_branch_code: string,
        daido_department_name: string,
        daido_department_code: string,
        daido_employee_code: string,
        daido_employee_name: string,
        profile: {
            fullname: string,
            fullname_kana: string,
            phone: string,
            email: string,
        },
    },
    person_in_charge: string,
    funds: string[],
    status: string,
    schedule: {
        daido_joins: boolean,
        schedule: string,
    },
    specialist: {
        fullname: string,
        secretary_number: string,
    },
    meeting_type: string,
    requirements: string[],
    fund_remarks: string[],
    meeting_evaluations: string[],
    comments: string[],
    type: string,
    topics: string[],
    remarks: string,
}

export type Application = {
    createdAt: string,
    company: {
        name: string,
        nameFurigana: string,
        postalCode: string,
        prefecture: string,
        address: string,
        buildingName: string,
        representativePosition: string,
        industry: { name: string }[],
        employeeCountRange: { name: string }[],
        parttimeEmployeeCount: string,
        insurancePlans: string[],
        laborRegulationsCompliant: boolean,
        allowSharoushi: boolean,
        allowShindanshi: boolean,
        daidoBranchName: string,
        daidoBranchCode: string,
        daidoDepartmentName: string,
        daidoDepartmentCode: string,
        daidoEmployeeCode: string,
        daidoEmployeeName: string,
        profile: {
            fullname: string,
            fullnameKana: string,
            phone: string,
            email: string,
        },
    },
    personInCharge: string,
    funds: string[],
    status: string,
    schedule: {
        daidoJoins: boolean,
        schedule: string,
    },
    specialist: {
        fullname: string,
        secretaryNumber: string,
    },
    meetingType: string,
    requirements: string[],
    fundRemarks: string[],
    meetingEvaluations: string[],
    comments: string[],
    type: string,
    topics: string[],
    remarks: string,
}

export type ApplicationRequest = {
    type: number,
    from?: string,
    to?: string,
    page?: number,
    per_page?: number,
}

/**
 * API Update Profile
 **/
export const fetchApplications = createAsyncThunk(
    '/admin/applications',
    async (params: ApplicationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getApplications(params);
            dispatch(setData(response.data.data));
            dispatch(setPagination(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const applicationsSlice = createSlice({
    name: 'applications',
    initialState: {
        success: null,
        loading: false,
        data: [],
        pagination: {} as Pagination,
    } as ApplicationState,
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload.map((application: ApplicationResponse) => ({
                createdAt: application.created_at,
                company: {
                    name: application.company.name,
                    nameFurigana: application.company.name_furigana,
                    postalCode: application.company.postal_code,
                    prefecture: application.company.prefecture,
                    address: application.company.address,
                    buildingName: application.company.building_name,
                    representativePosition: application.company.representative_position,
                    industry: application.company.industry,
                    employeeCountRange: application.company.employee_count_range,
                    parttimeEmployeeCount: application.company.parttime_employee_count,
                    insurancePlans: application.company.insurance_plans,
                    laborRegulationsCompliant: application.company.labor_regulations_compliant,
                    allowSharoushi: application.company.allow_sharoushi,
                    allowShindanshi: application.company.allow_shindanshi,
                    daidoBranchName: application.company.daido_branch_name,
                    daidoBranchCode: application.company.daido_branch_code,
                    daidoDepartmentName: application.company.daido_department_name,
                    daidoDepartmentCode: application.company.daido_department_code,
                    daidoEmployeeCode: application.company.daido_employee_code,
                    daidoEmployeeName: application.company.daido_employee_name,
                    profile: {
                        fullname: application.company.profile.fullname,
                        fullnameKana: application.company.profile.fullname_kana,
                        phone: application.company.profile.phone,
                        email: application.company.profile.email,
                    },
                },
                personInCharge: application.person_in_charge,
                funds: application.funds,
                status: application.status,
                schedule: {
                    daidoJoins: application.schedule?.daido_joins,
                    schedule: application.schedule?.schedule,
                },
                specialist: {
                    fullname: application.specialist?.fullname,
                    secretaryNumber: application.specialist?.secretary_number,
                },
                meetingType: application.meeting_type,
                requirements: application.requirements,
                fundRemarks: application.fund_remarks,
                meetingEvaluations: application.meeting_evaluations,
                comments: application.comments,
                type: application.type,
                topics: application.topics,
                remarks: application.remarks,
            }));
        },
        setPagination: (state, { payload }) => {
            state.pagination = {
                currentPage: payload.current_page,
                lastPage: payload.last_page,
                perPage: payload.per_page,
                total: payload.total,
            };
        },
        resetPagination: (state) => {
            state.pagination = {} as Pagination;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchApplications.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.data = [];
        });
        builder.addCase(fetchApplications.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchApplications.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
    },
});

export const { setData, setPagination, resetPagination } = applicationsSlice.actions;
export const selectApplications = (state: RootState) => state.applications;
