import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { getUserData, updateProfile } from 'api/user';
import { UpdateProfileRequest, changePasswordRequest, ChangePasswordState } from 'types';
import { changePassword } from 'api/changePassword';

/**
 * API Change Pasword
 **/
export const updatePassword = createAsyncThunk(
    '/admin/users/update-passowrd',
    async (params: changePasswordRequest, { dispatch, rejectWithValue }) => {
        const { email, old_password, new_password, new_password_confirmation } = params;
        try {
            let response = await changePassword(email.trim(), old_password.trim(), new_password.trim(), new_password_confirmation.trim());
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * API Update Profile
 **/
export const updateProfiles = createAsyncThunk(
    '/admin/users/update-profiles',
    async ({ name, email }: UpdateProfileRequest, { dispatch, rejectWithValue }) => {
        let fullname = `　${name}　`;
        try {
            let response = await updateProfile(fullname.trim(), email.trim());
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(editProfiles(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * Calls User API
 **/
export const getDetailProfile = createAsyncThunk('/user', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await getUserData();
        dispatch(setUser(response.data));
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

const changePasswordState: ChangePasswordState = {
    loading: false,
    success: false,
    status: 'pending',
    message: '',
    data: {
        email: '',
        uuid: '',
        name: '',
        profile: {
            firstname: '',
            lastname: '',
        },
    },
    profileData: '',
};
export const userSlice = createSlice({
    name: 'user',
    initialState: changePasswordState,
    reducers: {
        reset: (state: ChangePasswordState) => {
            state.success = false;
            state.loading = false;
            state.status = 'pending';
            state.message = '';
        },
        setUser: (state, { payload }) => {
            state.data = payload.data;
        },
        editProfiles: (state, { payload }) => {
            state.profileData = payload?.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updatePassword.pending, (state: ChangePasswordState) => {
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase(updatePassword.fulfilled, (state: ChangePasswordState) => {
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase(updatePassword.rejected, (state: ChangePasswordState, action) => {
            state.loading = false;
            state.status = 'failed';
            state.message = action.payload as string;
            state.success = false;
        });

        //update profile
        builder.addCase(updateProfiles.pending, (state: ChangePasswordState) => {
            state.loading = true;
        });
        builder.addCase(updateProfiles.fulfilled, (state: ChangePasswordState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(updateProfiles.rejected, (state: ChangePasswordState, action) => {
            state.loading = false;
            state.message = action.payload as string;
            state.success = false;
        });
    },
});

export const { setUser, editProfiles, reset } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
