import { FetchNotificationList, NotificationParams } from 'types';
import { getAxiosClientWithToken } from './index';
import { handleFormData } from '../lib/utils';
import { UpdateNotificationRequest } from '../app/slice/notificationSlice';

export const getNotificationList = (params: FetchNotificationList) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/notifications', { params });
};

export const getNotificationDetail = (uuid?: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/notifications/${uuid}`);
};

export const createNotification = (params: NotificationParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/notifications', params);
};

export const destroyNotificationDetail = (uuid: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/notifications/${uuid}`);
};

export const editNotification = (params: UpdateNotificationRequest) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    formData.append('_method', 'PUT');
    return restClient.post(`/admin/notifications/${params.id}`, formData);
};
