import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import { getDetailEmailTemplate, getEmailTemplateManagement, updateEmailTemplate } from 'api/emailTemplate';

import { RootState } from 'app/store';
import { DetailEmailTemplate, EditEmailTemplateRequest, EmailManagement, EmailParams, FetchState } from 'types';

/**
 * GET Email Management
 **/
export const fetchEmailTemplateManagement = createAsyncThunk(
    'admin/mail-template',
    async (params: EmailParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await getEmailTemplateManagement(params);
            const { success } = response.data;

            if (success) {
                dispatch(setManagementEmailTemplate(response.data));
                return success;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export type DetailEmailTemplateRequest = {
    uuid?: string;
};

export const fetchDetailEmailTemplate = createAsyncThunk(
    'admin/mail-template/:uuid',
    async ({ uuid }: DetailEmailTemplateRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getDetailEmailTemplate(uuid);
            const { success } = response.data;

            if (success) {
                dispatch(setDetailEmailTemplate(response.data));
                return success;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const editEmailTemplate = createAsyncThunk(
    'admin/mail-template/:uuid/edit',
    async (params: EditEmailTemplateRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await updateEmailTemplate(params);
            const { success } = response.data;

            if (success) {
                dispatch(setDetailEmailTemplate(response.data));
                return success;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

type EmailTemplateState = {
    emailTemplate: EmailManagement;
    detailEmailTemplate: DetailEmailTemplateState;
    update: UpdateState;
};

interface DetailEmailTemplateState extends FetchState {
    detailEmailTemplate: DetailEmailTemplate;
}

interface UpdateState extends FetchState {
    messages: string | object;
}

const initialState = {
    emailTemplate: {
        loading: false,
        success: false,
        error: false,
    } as EmailManagement,
    detailEmailTemplate: {
        loading: false,
        success: false,
        error: false,
        message: '',
        detailEmailTemplate: {} as DetailEmailTemplate,
    },
    update: {
        loading: false,
        success: false,
        error: false,
        messages: '',
    },
} as EmailTemplateState;

export const emailManagementSlice = createSlice({
    name: 'emailManagement',
    initialState,
    reducers: {
        setManagementEmailTemplate: (state: EmailTemplateState, action) => {
            state.emailTemplate = action?.payload ?? { data: [] };
        },
        setDetailEmailTemplate: (state: EmailTemplateState, { payload }) => {
            state.detailEmailTemplate.detailEmailTemplate = payload.data;
        },
        resetUpdateState: (state: EmailTemplateState) => {
            state.update = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        resetDetailEmailTemplate: (state: EmailTemplateState) => {
            state.detailEmailTemplate.detailEmailTemplate = {
                id: 0,
                name: '',
                sender: '',
                content: '',
                status: 0,
                type: 0,
                code: '',
            };
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(fetchEmailTemplateManagement.pending, (state: EmailTemplateState) => {
                state.emailTemplate.loading = true;
            })
            .addCase(fetchEmailTemplateManagement.fulfilled, (state: EmailTemplateState) => {
                state.emailTemplate.loading = false;
                state.emailTemplate.success = true;
                state.emailTemplate.error = false;
            })
            .addCase(fetchEmailTemplateManagement.rejected, (state: EmailTemplateState) => {
                state.emailTemplate.loading = false;
                state.emailTemplate.success = false;
                state.emailTemplate.error = true;
            })
            .addCase(fetchDetailEmailTemplate.pending, (state: EmailTemplateState) => {
                state.detailEmailTemplate.loading = true;
            })
            .addCase(fetchDetailEmailTemplate.fulfilled, (state: EmailTemplateState) => {
                state.detailEmailTemplate.loading = false;
                state.detailEmailTemplate.success = true;
                state.detailEmailTemplate.error = false;
            })
            .addCase(fetchDetailEmailTemplate.rejected, (state: EmailTemplateState) => {
                state.detailEmailTemplate.loading = false;
                state.detailEmailTemplate.success = false;
                state.detailEmailTemplate.error = true;
                state.detailEmailTemplate.detailEmailTemplate = {} as DetailEmailTemplate;
            })
            .addCase(editEmailTemplate.pending, (state: EmailTemplateState) => {
                state.update.loading = true;
            })
            .addCase(editEmailTemplate.fulfilled, (state: EmailTemplateState) => {
                state.update.loading = false;
                state.update.success = true;
                state.update.error = false;
            })
            .addCase(editEmailTemplate.rejected, (state: EmailTemplateState, { payload }) => {
                state.update.loading = false;
                state.update.success = false;
                state.update.error = true;
                state.update.messages = payload as string | object;
            });
    },
});

export const { setManagementEmailTemplate, setDetailEmailTemplate, resetUpdateState, resetDetailEmailTemplate } = emailManagementSlice.actions;
export const emailManagementSelector = (state: RootState) => state.emailManagement;
