import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { approveDiagnostician, getDiagnosticianDetail, updateDiagnostician } from 'api/diagnosticians';
import { ApproveAccountRequest, Rank, UpdateDiagnosticianRequest } from 'types';
import { getErrorMessage } from 'api';
import {
    ALLOW_COMPANY_REFERRALS,
    ALLOW_SPECIALIST_SEARCH,
    NOT_ALLOW_COMPANY_REFERRALS,
    NOT_ALLOW_SPECIALIST_SEARCH,
} from 'config/constant';

export type DiagnosticianState = {
    detail: AccountDetailState;
    update: {
        success: boolean;
        error: boolean;
        messages: string | object;
        loading: boolean;
    };
    approve: {
        success: boolean;
        error: boolean;
        messages: string | object;
        loading: boolean;
    };
};
export type AccountDetailState = {
    profileImage: string;
    profileImagePath: string;
    subProfileImage?: string;
    subProfileImagePath?: string;
    lastname: string;
    firstname: string;
    lastnameFurigana: string;
    firstnameFurigana: string;
    officeName: string;
    postalCode: string;
    prefectureId?: number;
    address: string;
    buildingName: string;
    prefectureSpecialist?: number[];
    supportedAreas: string;
    otherAddress: string;
    officePr: string;
    officeHistory: string;
    qualifications: string;
    fieldIds: number[];
    fieldIdOther: string[];
    industryIds: number[];
    ecrIds: number[];
    phone: string;
    email: string;
    twitterUrl: string;
    facebookUrl: string;
    homepageUrl: string;
    onlineSupport?: 0 | 1;
    allowNotifications?: 0 | 1;
    certificateFileUrl?: string[];
    certificateFilePath?: string[];
    businessManualUrl?: string;
    businessManualPath?: string;
    allowCompanyReferrals?: 0 | 1;
    allowSpecialistSearch?: 0 | 1;
    secretaryNumber?: string;
    rank?: Rank;
    loading: boolean;
    success: boolean;
    error: boolean;
};

export type AccountDetailRequest = {
    uuid?: string;
};

export const fetchDiagnosticianDetailData = createAsyncThunk(
    'admin/users/detail',
    async ({ uuid }: AccountDetailRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDiagnosticianDetail(uuid);
            const { data = {}, success } = response.data;

            if (success) {
                dispatch(setDiagnosticianDetailData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(false);
        }
    }
);

export const asyncUpdateDiagnostician = createAsyncThunk(
    'admin/users/update',
    async (params: UpdateDiagnosticianRequest, { dispatch, rejectWithValue }) => {
        try {
            params.allow_specialist_search = params.allow_specialist_search ? ALLOW_SPECIALIST_SEARCH : NOT_ALLOW_SPECIALIST_SEARCH;
            params.allow_company_referrals = params.allow_company_referrals ? ALLOW_COMPANY_REFERRALS : NOT_ALLOW_COMPANY_REFERRALS;
            const response = await updateDiagnostician(params);
            const { data = {}, success } = response.data;

            if (success) {
                dispatch(setDiagnosticianDetailData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const asyncApproveDiagnostician = createAsyncThunk(
    'admin/diagnosticians/approve',
    async (params: ApproveAccountRequest, { dispatch, rejectWithValue }) => {
        try {
            params.allow_specialist_search = params.allow_specialist_search ? ALLOW_SPECIALIST_SEARCH : NOT_ALLOW_SPECIALIST_SEARCH;
            params.allow_company_referrals = params.allow_company_referrals ? ALLOW_COMPANY_REFERRALS : NOT_ALLOW_COMPANY_REFERRALS;
            const response = await approveDiagnostician(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const diagnosticianDetailSlide = createSlice({
    name: 'diagnosticianDetail',
    initialState: {
        detail: {
            profileImage: '',
            subProfileImage: '',
            lastname: '',
            firstname: '',
            lastnameFurigana: '',
            firstnameFurigana: '',
            officeName: '',
            postalCode: '',
            address: '',
            buildingName: '',
            supportedAreas: '',
            otherAddress: '',
            officePr: '',
            officeHistory: '',
            qualifications: '',
            phone: '',
            email: '',
            twitterUrl: '',
            facebookUrl: '',
            homepageUrl: '',
            loading: false,
            success: false,
        },
        update: {
            loading: false,
            success: false,
            error: false,
            messages: '',
        },
        approve: {
            loading: false,
            success: false,
            error: false,
            messages: '',
        },
    } as DiagnosticianState,
    reducers: {
        setDiagnosticianDetailData: (state: DiagnosticianState, { payload }) => {
            state.detail = {
                ...state.detail,
                profileImage: payload?.profile_image_url,
                profileImagePath: payload?.profile_image_path,
                subProfileImage: payload?.sub_profile_image_url,
                certificateFileUrl: payload?.specialist_certification_images,
                certificateFilePath: payload?.specialist_certification_images_path,
                qualifications: payload?.qualifications,
                prefectureSpecialist: payload?.prefectures,
                supportedAreas: payload?.other_areas,
                prefectureId: payload?.prefecture,
                postalCode: payload?.postal_code,
                email: payload?.email,
                firstname: payload?.firstname,
                lastname: payload?.lastname,
                firstnameFurigana: payload?.firstname_furigana,
                lastnameFurigana: payload?.lastname_furigana,
                officeName: payload?.office_name,
                address: payload?.address,
                buildingName: payload?.building_name,
                otherAddress: payload?.other_address,
                officePr: payload?.office_pr,
                officeHistory: payload?.office_history,
                fieldIds: payload?.fields ?? [],
                fieldIdOther: payload?.field_id_other ?? [],
                industryIds: payload?.industries,
                phone: payload?.phone,
                twitterUrl: payload?.twitter_url,
                facebookUrl: payload?.facebook_url,
                homepageUrl: payload?.homepage_url,
                businessManualUrl: payload?.business_manual_url,
                businessManualPath: payload?.business_manual_path,
                onlineSupport: payload?.online_support,
                allowNotifications: payload?.allow_notifications,
                ecrIds: payload?.employee_count_ranges,
                allowCompanyReferrals: payload?.allow_company_referrals,
                allowSpecialistSearch: payload?.allow_specialist_search,
                secretaryNumber: payload?.secretary_number,
                rank: payload?.rank,
            };
        },
        resetUpdateState: (state: DiagnosticianState) => {
            state.update = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        resetApproveState: (state: DiagnosticianState) => {
            state.approve = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDiagnosticianDetailData.pending, (state: DiagnosticianState) => {
                state.detail.loading = true;
            })
            .addCase(fetchDiagnosticianDetailData.fulfilled, (state: DiagnosticianState) => {
                state.detail.loading = false;
                state.detail.success = true;
                state.detail.error = false;
            })
            .addCase(fetchDiagnosticianDetailData.rejected, (state: DiagnosticianState) => {
                state.detail.loading = false;
                state.detail.success = false;
                state.detail.error = true;
            })
            .addCase(asyncUpdateDiagnostician.pending, (state: DiagnosticianState) => {
                state.update = {
                    ...state.update,
                    loading: true,
                };
            })
            .addCase(asyncUpdateDiagnostician.fulfilled, (state: DiagnosticianState) => {
                state.update = {
                    ...state.update,
                    loading: false,
                    success: true,
                };
            })
            .addCase(asyncUpdateDiagnostician.rejected, (state: DiagnosticianState, { payload }) => {
                state.update = {
                    ...state.update,
                    loading: false,
                    success: false,
                    error: true,
                    messages: payload as string | object,
                };
            })
            .addCase(asyncApproveDiagnostician.pending, (state: DiagnosticianState) => {
                state.approve = {
                    ...state.approve,
                    loading: true,
                };
            })
            .addCase(asyncApproveDiagnostician.fulfilled, (state: DiagnosticianState) => {
                state.approve = {
                    ...state.approve,
                    loading: false,
                    success: true,
                };
            })
            .addCase(asyncApproveDiagnostician.rejected, (state: DiagnosticianState, { payload }) => {
                state.approve = {
                    ...state.approve,
                    loading: false,
                    success: false,
                    error: true,
                    messages: payload as string | object,
                };
            });
    },
});

export const { setDiagnosticianDetailData, resetUpdateState, resetApproveState } = diagnosticianDetailSlide.actions;
export const diagnosticianDetailSelector = (state: RootState) => state.diagnosticianDetail;
