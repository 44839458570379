import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import {
    CompanyDetail,
    CompanyFile,
    CompanyRulesData,
    UpdateDaiDoEmployeeAndInterviewState,
    DaidoEmployee,
    FetchCompanyRulesRequest,
    FetchState,
    Pagination,
    InterviewResultRequest,
} from 'types';
import { getErrorMessage } from 'api';
import {
    getCompanyFile,
    getInfoCompany,
    getCompanyRules,
    updateStatusCompany,
    destroyCompany,
    getDetailExchange,
    personCharge,
    exchangeResultInterview,
} from 'api/companies';
import { PER_PAGE_DEFAULT } from 'config/constant';
import { mbTrim } from 'lib/utils';

export type CompaniesState = {
    companyFile: CompanyFileState;
    companyRule: CompanyRuleState;
    changeStatus: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | object;
        companyInfo: CompanyRulesData;
    };
    deleteCompany: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | object;
    };
    companyInfo: CompanyDetail;
    fetchCompanyDetailState: FetchState;
    detailExchange: any;
    updateDaiDoEmployeeAndInterviewState: UpdateDaiDoEmployeeAndInterviewState;
};

interface CompanyFileState extends FetchState, Pagination {
    files: CompanyFile[];
}

interface CompanyRuleState extends FetchState, Pagination {
    companyRules: CompanyRulesData[];
}

/**
 * GET list of business exchange
 **/
export const fetchCompanyFile = createAsyncThunk(
    'admin/companies/:uuid/files',
    async (params: { uuid?: string; page: number }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getCompanyFile(params);
            const {
                data = [],
                success,
                current_page: currentPage = 1,
                last_page: lastPage = 1,
                per_page: perPage = PER_PAGE_DEFAULT,
                total = 0,
            } = response.data;
            if (success) {
                dispatch(setCompanyFile({ data, pagination: { lastPage, currentPage, perPage, total } as Pagination }));
                return success;
            }
        } catch (error: any) {
            return rejectWithValue(false);
        }
    }
);

//GET company information
export const fetchCompanyInfo = createAsyncThunk(
    'admin/companies/:uuid',
    async ({ uuid }: { uuid?: string }, { dispatch, rejectWithValue }) => {        
        try {
            const response = await getInfoCompany(uuid);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setCompanyInfo(data));
                return success;
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * GET list of business rules
 **/
export const fetchCompanyRules = createAsyncThunk(
    '/admin/companies/rules',
    async (params: FetchCompanyRulesRequest, { dispatch, rejectWithValue }) => {
        try {
            if (params.status === undefined || params.status === 2) {
                delete params.status;
            }
            const response = await getCompanyRules(params);
            const {
                data = [],
                success,
                current_page: currentPage = 1,
                last_page: lastPage = 1,
                per_page: perPage = PER_PAGE_DEFAULT,
                total = 0,
            } = response.data;
            if (success) {
                dispatch(setCompanyRules({ data, pagination: { lastPage, currentPage, perPage, total } as Pagination }));
            }
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//GET Detail exchange
export const fetchDetailExchange = createAsyncThunk(
    'admin/companies/topic/:uuid',
    async ({ uuid }: { uuid?: string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailExchange(uuid);
            dispatch(setDetailExchange(response.data));
            return true;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);
export const changeCompanyActivation = createAsyncThunk(
    '/admin/companies/change-status',
    async ({ uuid }: { uuid?: string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await updateStatusCompany(uuid);
            const { success, data } = response.data;
            if (success) {
                dispatch(setCompanyInfo(data));
                return success;
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deleteCompany = createAsyncThunk(
    '/admin/companies/delete',
    async ({ uuid }: { uuid?: string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await destroyCompany(uuid);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// update Daido Person Charge
export const updatePersonCharge = createAsyncThunk(
    '/admin/companies/employeeId/update_person_in_charge',
    async (params: DaidoEmployee, { dispatch, rejectWithValue }) => {
        try {
            params.name = mbTrim(params.name);
            const response = await personCharge(params);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// update Exchange Results
export const updateExchangeResults = createAsyncThunk(
    '/admin/companies/employeeId/update-interview-result',
    async (params: InterviewResultRequest, { dispatch, rejectWithValue }) => {
        try {
            params.interview_result = mbTrim(params.interview_result);
            const response = await exchangeResultInterview(params);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// save data reducers
export const companiesSlice = createSlice({
    name: 'companies',
    initialState: {
        companyFile: {
            loading: false,
            success: false,
            total: 0,
            perPage: 0,
            lastPage: 1,
            currentPage: 1,
            files: [],
            error: false,
            count: 0,
        },
        companyRule: {
            loading: false,
            success: false,
            error: false,
            total: 0,
            lastPage: 1,
            currentPage: 1,
            count: 0,
            perPage: PER_PAGE_DEFAULT,
            companyRules: [] as CompanyRulesData[],
        },
        changeStatus: {
            loading: false,
            success: false,
            error: false,
            message: '',
            companyInfo: {} as CompanyRulesData,
        },
        deleteCompany: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        companyInfo: {} as CompanyDetail,
        fetchCompanyDetailState: {
            loading: false,
            error: false,
            success: false,
        },
        detailExchange: [],
        updateDaiDoEmployeeAndInterviewState: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
    } as CompaniesState,
    reducers: {
        setCompanyFile: (state, { payload }) => {
            state.companyFile.total = payload.pagination.total;
            state.companyFile.perPage = payload.pagination.perPage;
            state.companyFile.currentPage = payload.pagination.currentPage;
            state.companyFile.lastPage = payload.pagination.lastPage;
            state.companyFile.files = payload.data;
        },
        setCompanyInfo: (state, { payload }) => {
            state.companyInfo = payload;
        },
        setCompanyRules: (state: CompaniesState, { payload }) => {
            state.companyRule.companyRules = payload.data;
            state.companyRule.total = payload.pagination.total;
            state.companyRule.lastPage = payload.pagination.lastPage;
            state.companyRule.perPage = payload.pagination.perPage;
            state.companyRule.currentPage = payload.pagination.currentPage;
        },
        setDetailExchange: (state, { payload }) => {
            state.detailExchange = payload.data;
        },
        resetChangeStatusState: (state: CompaniesState) => {
            state.changeStatus.loading = false;
            state.changeStatus.success = false;
            state.changeStatus.error = false;
            state.changeStatus.message = '';
        },
        resetDeleteCompanyState: (state: CompaniesState) => {
            state.deleteCompany.loading = false;
            state.deleteCompany.success = false;
            state.deleteCompany.error = false;
            state.deleteCompany.message = '';
        },
        resetDaidoEmployee: (state: CompaniesState) => {
            state.updateDaiDoEmployeeAndInterviewState.loading = false;
            state.updateDaiDoEmployeeAndInterviewState.success = false;
            state.updateDaiDoEmployeeAndInterviewState.error = false;
            state.updateDaiDoEmployeeAndInterviewState.message = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeCompanyActivation.pending, (state: CompaniesState) => {
                state.changeStatus.loading = true;
            })
            .addCase(changeCompanyActivation.fulfilled, (state: CompaniesState) => {
                state.changeStatus.loading = false;
                state.changeStatus.success = true;
                state.changeStatus.error = false;
            })
            .addCase(changeCompanyActivation.rejected, (state: CompaniesState, { payload }) => {
                state.changeStatus.loading = false;
                state.changeStatus.success = false;
                state.changeStatus.error = true;
                state.changeStatus.message = payload as string | object;
            })
            .addCase(deleteCompany.pending, (state: CompaniesState) => {
                state.deleteCompany.loading = true;
            })
            .addCase(deleteCompany.fulfilled, (state: CompaniesState) => {
                state.deleteCompany.loading = false;
                state.deleteCompany.success = true;
                state.deleteCompany.error = false;
            })
            .addCase(deleteCompany.rejected, (state: CompaniesState, { payload }) => {
                state.deleteCompany.loading = false;
                state.deleteCompany.success = false;
                state.deleteCompany.error = true;
                state.deleteCompany.message = payload as string | object;
            })
            .addCase(fetchCompanyRules.pending, (state: CompaniesState) => {
                state.companyRule.loading = true;
            })
            .addCase(fetchCompanyRules.fulfilled, (state: CompaniesState) => {
                state.companyRule.loading = false;
                state.companyRule.success = true;
                state.companyRule.error = false;
            })
            .addCase(fetchCompanyRules.rejected, (state: CompaniesState, { payload }) => {
                state.companyRule.loading = false;
                state.companyRule.success = false;
                state.companyRule.error = true;
                state.companyRule.companyRules = [];
            })
            .addCase(fetchCompanyInfo.pending, (state: CompaniesState) => {
                state.fetchCompanyDetailState.loading = true;
            })
            .addCase(fetchCompanyInfo.fulfilled, (state: CompaniesState) => {
                state.fetchCompanyDetailState.loading = false;
                state.fetchCompanyDetailState.success = true;
                state.fetchCompanyDetailState.error = false;
            })
            .addCase(fetchCompanyInfo.rejected, (state: CompaniesState, { payload }) => {
                state.fetchCompanyDetailState.loading = false;
                state.fetchCompanyDetailState.success = false;
                state.fetchCompanyDetailState.error = true;
                state.companyInfo = {} as CompanyDetail;
            })
            .addCase(fetchCompanyFile.pending, (state: CompaniesState) => {
                state.companyFile.loading = true;
            })
            .addCase(fetchCompanyFile.fulfilled, (state: CompaniesState) => {
                state.companyFile.loading = false;
                state.companyFile.success = true;
                state.companyFile.error = false;
            })
            .addCase(fetchCompanyFile.rejected, (state: CompaniesState, { payload }) => {
                state.companyFile.loading = false;
                state.companyFile.success = false;
                state.companyFile.error = true;
                state.companyFile.files = [];
            })
            .addMatcher(isAnyOf(updatePersonCharge.pending, updateExchangeResults.pending), (state: CompaniesState) => {
                state.updateDaiDoEmployeeAndInterviewState.loading = true;
            })
            .addMatcher(isAnyOf(updatePersonCharge.fulfilled, updateExchangeResults.fulfilled), (state: CompaniesState) => {
                state.updateDaiDoEmployeeAndInterviewState.loading = false;
                state.updateDaiDoEmployeeAndInterviewState.success = true;
                state.updateDaiDoEmployeeAndInterviewState.error = false;
            })
            .addMatcher(isAnyOf(updatePersonCharge.rejected, updateExchangeResults.rejected), (state: CompaniesState, { payload }) => {
                state.updateDaiDoEmployeeAndInterviewState.loading = false;
                state.updateDaiDoEmployeeAndInterviewState.success = false;
                state.updateDaiDoEmployeeAndInterviewState.error = true;
                state.updateDaiDoEmployeeAndInterviewState.message = payload as string | object;
            });
    },
});

export const {
    setCompanyFile,
    setCompanyRules,
    setCompanyInfo,
    resetChangeStatusState,
    resetDeleteCompanyState,
    resetDaidoEmployee,
    setDetailExchange,
} = companiesSlice.actions;
export const companiesSelector = (state: RootState) => state.companies;
