import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getJurisdictions, updateJurisdictions } from 'api/jurisdiction';
import { flatten } from 'lodash';
import { getErrorMessage } from 'api';

export const FETCH_JURISDICTION = 'FETCH_JURISDICTION';
export const SAVE_JURISDICTION = 'SAVE_JURISDICTION';

export type UpdateJurisdictionRequest = {
    id: number,
    rank: number,
    funds: {
        id: number,
        rank: number
    }[]
}[]

export type JurisdictionFundsData = {
    id: number,
    title: string,
    rank: number,
    jurisdiction: string
    created_at: any
}

export type JurisdictionData = {
    id: number,
    name: string,
    rank: number,
    funds: JurisdictionFundsData[][]
}

type JurisdictionState = {
    loading: boolean,
    success: boolean | null,
    data: JurisdictionData[],
    type: string
}

export const fetchJurisdiction = createAsyncThunk(
    'resource/jurisdication',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            let response = await getJurisdictions();
            const { data = {}, success } = response.data;

            if (success) {
                dispatch(setJurisdictions(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const saveJurisdication = createAsyncThunk(
    'resource/jurisdication/save',
    async (_, { rejectWithValue, getState }) => {
        try {
            const { data } = (getState() as RootState).jurisdiction;

            let params = data.map((row, i) => ({
                id: row.id,
                rank: i + 1,
                funds: flatten(row.funds).map((fund, j) => ({
                    id: fund.id,
                    rank: j + 1,
                })),
            }));

            let response = await updateJurisdictions(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const jurisdictionSlice = createSlice({
    name: 'jurisdiction',
    initialState: {
        loading: false,
        success: null,
        data: [],
        type: '',
    } as JurisdictionState,
    reducers: {
        setJurisdictions: (state, action) => {
            state.data = action.payload;
        },
        setJurisdictionFunds: (state, action) => {
            const { id, group, funds } = action.payload;

            state.data = state.data.map(row => {
                if (row.id === id) {
                    row.funds[group] = funds;
                }

                return row;
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchJurisdiction.pending, (state) => {
            state.loading = true;
            state.success = null;
            state.type = FETCH_JURISDICTION;
        });
        builder.addCase(fetchJurisdiction.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
            state.type = FETCH_JURISDICTION;
        });
        builder.addCase(fetchJurisdiction.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.type = FETCH_JURISDICTION;
        });
        builder.addCase(saveJurisdication.pending, (state) => {
            state.loading = true;
            state.success = null;
            state.type = SAVE_JURISDICTION;
        });
        builder.addCase(saveJurisdication.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
            state.type = SAVE_JURISDICTION;
        });
        builder.addCase(saveJurisdication.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.type = SAVE_JURISDICTION;
        });
    },
});

export const selectJurisdiction = (state: RootState) => state.jurisdiction;
export const { setJurisdictions, setJurisdictionFunds } = jurisdictionSlice.actions;
