import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getMatchingSetting, updateMatchingSetting } from 'api/setting';
import { FetchState, UpdateMatchingSettingData } from 'types';
import { getErrorMessage } from 'api';

export type SettingState = {
    matchingSetting: MatchingSettingState;
    updateMatchingSetting: UpdateMatchingSettingState;
};

interface MatchingSettingState extends FetchState {
    value: number;
}

interface UpdateMatchingSettingState extends FetchState {
    messages: string | object;
}

export const fetchMatchingSettingData = createAsyncThunk('admins/fetch/matching/settings', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await getMatchingSetting();
        const { data = {}, success = false } = response.data;

        if (success) {
            dispatch(setMatchingSettingData(data));
            return true;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        return rejectWithValue(false);
    }
});

export const asyncUpdateMatchingSettingData = createAsyncThunk(
    'admins/update/matching/settings',
    async (params: UpdateMatchingSettingData, { dispatch, rejectWithValue }) => {
        try {
            let response = await updateMatchingSetting({ matching_setting: params.matchingSetting });
            const { data = {}, success = false } = response.data;

            if (success) {
                dispatch(setMatchingSettingData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const settingSlice = createSlice({
    name: 'settings',
    initialState: {
        matchingSetting: {
            loading: false,
            success: false,
            error: false,
            value: 0,
        },
        updateMatchingSetting: {
            loading: false,
            success: false,
            error: false,
            messages: '',
        },
    } as SettingState,
    reducers: {
        setMatchingSettingData: (state: SettingState, { payload }) => {
            state.matchingSetting.value = payload.value as number;
        },
        resetUpdateMatchingSettingState: (state: SettingState) => {
            state.updateMatchingSetting = {
                loading: false,
                error: false,
                success: false,
                messages: '',
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMatchingSettingData.pending, (state: SettingState) => {
                state.matchingSetting.loading = true;
            })
            .addCase(fetchMatchingSettingData.fulfilled, (state: SettingState) => {
                state.matchingSetting.loading = false;
                state.matchingSetting.success = true;
                state.matchingSetting.error = false;
            })
            .addCase(fetchMatchingSettingData.rejected, (state: SettingState) => {
                state.matchingSetting.loading = false;
                state.matchingSetting.success = false;
                state.matchingSetting.error = true;
            })
            .addCase(asyncUpdateMatchingSettingData.pending, (state: SettingState) => {
                state.updateMatchingSetting.loading = true;
            })
            .addCase(asyncUpdateMatchingSettingData.fulfilled, (state: SettingState) => {
                state.updateMatchingSetting.loading = false;
                state.updateMatchingSetting.success = true;
                state.updateMatchingSetting.error = false;
            })
            .addCase(asyncUpdateMatchingSettingData.rejected, (state: SettingState, { payload }) => {
                state.updateMatchingSetting.loading = false;
                state.updateMatchingSetting.success = false;
                state.updateMatchingSetting.error = true;
                state.updateMatchingSetting.messages = payload as string | object;
            });
    },
});

export const settingSelector = (state: RootState) => state.settings;
export const { setMatchingSettingData, resetUpdateMatchingSettingState } = settingSlice.actions;
