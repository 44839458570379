import { UpdateNotificationCategoryRequest, PageCategory, StoreNotificationCategoryRequest } from 'types';
import { getAxiosClientWithToken } from './index';

export const getCategoriesList = (params: PageCategory) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/categories', { params });
};

export const createCategory = (params: StoreNotificationCategoryRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/categories', params);
};

export const editCategory = (categoryParams: UpdateNotificationCategoryRequest) => {
    const { categoryId, name } = categoryParams;
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/categories/${categoryId}`, {
        _method: 'PUT',
        name,
    });
};

export const deleteCategory = (uuid?: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/admin/categories/${uuid}`);
};

export const getAllCategory = (type: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/admin/categories/all', {type});
};
