import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
    destroyCapitalRange,
    destroyCoverageType,
    destroyPrefectures,
    destroyPurpose,
    destroySearchEmployeeCountRanges,
    destroySearchIndustries,
    destroyYearsEstablishedRanges,
    editCapitalRange,
    editCoverageType,
    editPrefectures,
    editPurpose,
    editSearchEmployeeCountRanges,
    editSearchIndustries,
    editYearsEstablishedRanges,
    getHeadquarters,
    getPurposes,
    storeCapitalRange,
    storeCoverageType,
    storePrefectures,
    storePurpose,
    storeSearchEmployeeCountRanges,
    storeSearchIndustries,
    storeYearsEstablishedRanges,
} from '../../api/masterDataSubsidies';
import { FetchState } from '../../types';
import { getErrorMessage } from '../../api';

export type MasterDataSubsidiesState = {
    purpose: PurposeState;
    headquarter: HeadquarterState;
    updateMasterData: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | object;
    };
    createMasterData: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | object;
    };
    deleteMasterData: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | object;
    };
};

interface PurposeState extends FetchState {
    purposes: Purpose[];
}

interface HeadquarterState extends FetchState {
    headquarters: Headquarter[];
}

export type Purpose = {
    id: number;
    code: string;
    name: string;
};

export type Headquarter = {
    id: number;
    code: string;
    name: string;
};

export type MasterDataSubsidiesRequest = {
    column?: OptionFilterSubsidies;
    keyword?: string;
};

export type UpdateMasterDataRequest = {
    code: string;
    name: string;
};

export type CreateMasterDataRequest = {
    code: string;
    name: string;
};

export type DeleteMasterDataRequest = {
    code: string;
};

export type OptionFilterSubsidies = 'code' | 'name';

export const fetchPurposes = createAsyncThunk(
    'admin/master/subsidies/purpose',
    async (params: MasterDataSubsidiesRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getPurposes(params);
            const { data = {}, success } = response.data;

            if (success) {
                dispatch(setPurposeData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(false);
        }
    }
);

export const fetchHeadquarters = createAsyncThunk(
    'admin/master/subsidies/headquarter',
    async (params: MasterDataSubsidiesRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getHeadquarters(params);
            const { data = {}, success } = response.data;

            if (success) {
                dispatch(setHeadquarterData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(false);
        }
    }
);

export const updatePurpose = createAsyncThunk(
    'admin/master/subsidies/updatePurpose',
    async (params: UpdateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await editPurpose(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateSearchIndustries = createAsyncThunk(
    'admin/master/subsidies/updateSearchIndustries',
    async (params: UpdateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await editSearchIndustries(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateSearchEmployeeCountRanges = createAsyncThunk(
    'admin/master/subsidies/updateSearchEmployeeCountRanges',
    async (params: UpdateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await editSearchEmployeeCountRanges(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateCoverageType = createAsyncThunk(
    'admin/master/subsidies/updateCoverageType',
    async (params: UpdateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await editCoverageType(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateCapitalRange = createAsyncThunk(
    'admin/master/subsidies/updateCapitalRange',
    async (params: UpdateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await editCapitalRange(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateYearsEstablishedRanges = createAsyncThunk(
    'admin/master/subsidies/updateYearsEstablishedRanges',
    async (params: UpdateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await editYearsEstablishedRanges(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updatePrefectures = createAsyncThunk(
    'admin/master/subsidies/updatePrefectures',
    async (params: UpdateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await editPrefectures(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createPurpose = createAsyncThunk(
    'admin/master/subsidies/createPurpose',
    async (params: CreateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await storePurpose(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createSearchIndustries = createAsyncThunk(
    'admin/master/subsidies/createSearchIndustries',
    async (params: CreateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await storeSearchIndustries(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createSearchEmployeeCountRanges = createAsyncThunk(
    'admin/master/subsidies/createSearchEmployeeCountRanges',
    async (params: CreateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await storeSearchEmployeeCountRanges(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createCoverageType = createAsyncThunk(
    'admin/master/subsidies/createCoverageType',
    async (params: CreateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await storeCoverageType(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createCapitalRange = createAsyncThunk(
    'admin/master/subsidies/createCapitalRange',
    async (params: CreateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await storeCapitalRange(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createYearsEstablishedRanges = createAsyncThunk(
    'admin/master/subsidies/createYearsEstablishedRanges',
    async (params: CreateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await storeYearsEstablishedRanges(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createPrefectures = createAsyncThunk(
    'admin/master/subsidies/createPrefectures',
    async (params: CreateMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await storePrefectures(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deletePurpose = createAsyncThunk(
    'admin/master/subsidies/deletePurpose',
    async (params: DeleteMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await destroyPurpose(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deleteSearchIndustries = createAsyncThunk(
    'admin/master/subsidies/deleteSearchIndustries',
    async (params: DeleteMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await destroySearchIndustries(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deleteSearchEmployeeCountRanges = createAsyncThunk(
    'admin/master/subsidies/deleteSearchEmployeeCountRanges',
    async (params: DeleteMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await destroySearchEmployeeCountRanges(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deleteCoverageType = createAsyncThunk(
    'admin/master/subsidies/deleteCoverageType',
    async (params: DeleteMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await destroyCoverageType(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deleteCapitalRange = createAsyncThunk(
    'admin/master/subsidies/deleteCapitalRange',
    async (params: DeleteMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await destroyCapitalRange(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deleteYearsEstablishedRanges = createAsyncThunk(
    'admin/master/subsidies/deleteYearsEstablishedRanges',
    async (params: DeleteMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await destroyYearsEstablishedRanges(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deletePrefectures = createAsyncThunk(
    'admin/master/subsidies/deletePrefectures',
    async (params: DeleteMasterDataRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await destroyPrefectures(params);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const masterDataSubsidiesSlide = createSlice({
    name: 'master-data-subsidies',
    initialState: {
        purpose: {
            loading: false,
            success: false,
            error: false,
            purposes: [],
        },
        headquarter: {
            loading: false,
            success: false,
            error: false,
            headquarters: [],
        },
        updateMasterData: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        createMasterData: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        deleteMasterData: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
    } as MasterDataSubsidiesState,
    reducers: {
        setPurposeData: (state: MasterDataSubsidiesState, { payload }) => {
            state.purpose.purposes = payload;
        },
        setHeadquarterData: (state: MasterDataSubsidiesState, { payload }) => {
            state.headquarter.headquarters = payload;
        },
        resetUpdateState: (state: MasterDataSubsidiesState) => {
            state.updateMasterData.loading = false;
            state.updateMasterData.success = false;
            state.updateMasterData.error = false;
            state.updateMasterData.message = '';
        },
        resetCreateState: (state: MasterDataSubsidiesState) => {
            state.createMasterData.loading = false;
            state.createMasterData.success = false;
            state.createMasterData.error = false;
            state.createMasterData.message = '';
        },
        resetDeleteState: (state: MasterDataSubsidiesState) => {
            state.deleteMasterData.loading = false;
            state.deleteMasterData.success = false;
            state.deleteMasterData.error = false;
            state.deleteMasterData.message = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPurposes.pending, (state: MasterDataSubsidiesState) => {
                state.purpose.loading = true;
            })
            .addCase(fetchPurposes.fulfilled, (state: MasterDataSubsidiesState) => {
                state.purpose.loading = false;
                state.purpose.success = true;
                state.purpose.error = false;
            })
            .addCase(fetchPurposes.rejected, (state: MasterDataSubsidiesState) => {
                state.purpose.loading = false;
                state.purpose.success = false;
                state.purpose.error = true;
                state.purpose.purposes = [];
            })
            .addCase(fetchHeadquarters.pending, (state: MasterDataSubsidiesState) => {
                state.headquarter.loading = true;
            })
            .addCase(fetchHeadquarters.fulfilled, (state: MasterDataSubsidiesState) => {
                state.headquarter.loading = false;
                state.headquarter.success = true;
                state.headquarter.error = false;
            })
            .addCase(fetchHeadquarters.rejected, (state: MasterDataSubsidiesState) => {
                state.headquarter.loading = false;
                state.headquarter.success = false;
                state.headquarter.error = true;
                state.headquarter.headquarters = [];
            })
            .addMatcher(
                isAnyOf(
                    updatePurpose.pending,
                    updateSearchIndustries.pending,
                    updateSearchEmployeeCountRanges.pending,
                    updateCoverageType.pending,
                    updateCapitalRange.pending,
                    updateYearsEstablishedRanges.pending,
                    updatePrefectures.pending
                ),
                (state: MasterDataSubsidiesState) => {
                    state.updateMasterData.loading = true;
                }
            )
            .addMatcher(
                isAnyOf(
                    updatePurpose.fulfilled,
                    updateSearchIndustries.fulfilled,
                    updateSearchEmployeeCountRanges.fulfilled,
                    updateCoverageType.fulfilled,
                    updateCapitalRange.fulfilled,
                    updateYearsEstablishedRanges.fulfilled,
                    updatePrefectures.fulfilled
                ),
                (state: MasterDataSubsidiesState) => {
                    state.updateMasterData.loading = false;
                    state.updateMasterData.success = true;
                    state.updateMasterData.error = false;
                }
            )
            .addMatcher(
                isAnyOf(
                    updatePurpose.rejected,
                    updateSearchIndustries.rejected,
                    updateSearchEmployeeCountRanges.rejected,
                    updateCoverageType.rejected,
                    updateCapitalRange.rejected,
                    updateYearsEstablishedRanges.rejected,
                    updatePrefectures.rejected
                ),
                (state: MasterDataSubsidiesState, { payload }) => {
                    state.updateMasterData.loading = false;
                    state.updateMasterData.success = false;
                    state.updateMasterData.error = true;
                    state.updateMasterData.message = payload as string | object;
                }
            )
            .addMatcher(
                isAnyOf(
                    createPurpose.pending,
                    createSearchIndustries.pending,
                    createSearchEmployeeCountRanges.pending,
                    createCoverageType.pending,
                    createCapitalRange.pending,
                    createYearsEstablishedRanges.pending,
                    createPrefectures.pending
                ),
                (state: MasterDataSubsidiesState) => {
                    state.createMasterData.loading = true;
                }
            )
            .addMatcher(
                isAnyOf(
                    createPurpose.fulfilled,
                    createSearchIndustries.fulfilled,
                    createSearchEmployeeCountRanges.fulfilled,
                    createCoverageType.fulfilled,
                    createCapitalRange.fulfilled,
                    createYearsEstablishedRanges.fulfilled,
                    createPrefectures.fulfilled
                ),
                (state: MasterDataSubsidiesState) => {
                    state.createMasterData.loading = false;
                    state.createMasterData.success = true;
                    state.createMasterData.error = false;
                }
            )
            .addMatcher(
                isAnyOf(
                    createPurpose.rejected,
                    createSearchIndustries.rejected,
                    createSearchEmployeeCountRanges.rejected,
                    createCoverageType.rejected,
                    createCapitalRange.rejected,
                    createYearsEstablishedRanges.rejected,
                    createPrefectures.rejected
                ),
                (state: MasterDataSubsidiesState, { payload }) => {
                    state.createMasterData.loading = false;
                    state.createMasterData.success = false;
                    state.createMasterData.error = true;
                    state.createMasterData.message = payload as string | object;
                }
            )
            .addMatcher(
                isAnyOf(
                    deletePurpose.pending,
                    deleteSearchIndustries.pending,
                    deleteSearchEmployeeCountRanges.pending,
                    deleteCoverageType.pending,
                    deleteCapitalRange.pending,
                    deleteYearsEstablishedRanges.pending,
                    deletePrefectures.pending
                ),
                (state: MasterDataSubsidiesState) => {
                    state.deleteMasterData.loading = true;
                }
            )
            .addMatcher(
                isAnyOf(
                    deletePurpose.fulfilled,
                    deleteSearchIndustries.fulfilled,
                    deleteSearchEmployeeCountRanges.fulfilled,
                    deleteCoverageType.fulfilled,
                    deleteCapitalRange.fulfilled,
                    deleteYearsEstablishedRanges.fulfilled,
                    deletePrefectures.fulfilled
                ),
                (state: MasterDataSubsidiesState) => {
                    state.deleteMasterData.loading = false;
                    state.deleteMasterData.success = true;
                    state.deleteMasterData.error = false;
                }
            )
            .addMatcher(
                isAnyOf(
                    deletePurpose.rejected,
                    deleteSearchIndustries.rejected,
                    deleteSearchEmployeeCountRanges.rejected,
                    deleteCoverageType.rejected,
                    deleteCapitalRange.rejected,
                    deleteYearsEstablishedRanges.rejected,
                    deletePrefectures.rejected
                ),
                (state: MasterDataSubsidiesState, { payload }) => {
                    state.deleteMasterData.loading = false;
                    state.deleteMasterData.success = false;
                    state.deleteMasterData.error = true;
                    state.deleteMasterData.message = payload as string | object;
                }
            );
    },
});

export const masterDataSubsidiesSelector = (state: RootState) => state.subsidies;
export const { setPurposeData, setHeadquarterData, resetUpdateState, resetCreateState, resetDeleteState } =
    masterDataSubsidiesSlide.actions;
