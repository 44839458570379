import { getAxiosClientWithToken } from './index';
import { SubsidyManagementRequest, DownloadCSVRequest } from '../app/slice/subsidiesSlice';
import { GrantRequest } from 'types';
import { handleFormData } from 'lib/utils';

export const getSubsidies = (params: SubsidyManagementRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/funds`, { params });
};

export const getSubsidyDetail = (uuid?: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/funds/${uuid}`);
};

export const downloadCSV = (params: DownloadCSVRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/funds/export`, params);
};
export const updateGrants = ({ grantId, data }: GrantRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/admin/funds/${grantId}`, handleFormData(data));
};
