import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { GrantRequest, IsDraft, MoneyDetail, MoneyInfo, RegisterSubsidyRequest } from 'types';
import { getErrorMessage } from 'api';
import { getSubsidies, getSubsidyDetail, updateGrants, downloadCSV } from '../../api/subsidies';
import { FUND_TYPE_GRANT } from 'config/constant';
import { registerSubsidy } from 'api/companies';
import moment from 'moment';

export type GrantsData = {
    grants: {
        loading: boolean;
        success: boolean;
        total: number;
        per_page: number;
        current_page: number;
        lastPage: number;
        grantsData: MoneyInfo[];
    };
    grantDrafts: {
        loading: boolean;
        success: boolean;
        total: number;
        per_page: number;
        current_page: number;
        lastPage: number;
        grantsDraftData: MoneyInfo[];
    };
    detail: {
        loading: boolean;
        success: boolean;
        error: boolean;
        grantDetail: MoneyDetail;
    };
    downloadCSVData: string;
    registerGrant: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | object;
    };
    editGrant: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | object;
    };
};

export type GrantManagementRequest = {
    column?: OptionFilterGrantManagement;
    keyword?: string;
    page?: number;
    type?: number;
    is_draft?: IsDraft;
};
export type DownloadCSVRequest = {
    column?: OptionFilterGrantManagement;
    keyword?: string;
    type?: number;
    is_draft?: IsDraft;
};

export type OptionFilterGrantManagement = 'code' | 'title' | 'overview' | 'target_groups' | 'amount' | 'application_period';

export const fetchGrants = createAsyncThunk(
    'admin/funds/grant/list',
    async (params: GrantManagementRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSubsidies(params);
            dispatch(setGrants(response?.data));
            return response?.data?.success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);
export const fetchGrantDrafts = createAsyncThunk(
    'admin/funds/grant/draft',
    async (params: GrantManagementRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSubsidies(params);
            dispatch(setGrantDrafts(response?.data));
            return response?.data?.success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchGrantDetail = createAsyncThunk(
    'admin/funds/grant/:uuid',
    async (params: string | undefined, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSubsidyDetail(params);
            const { data = {}, success } = response.data;

            if (success) {
                dispatch(setGrantDetail(data));

                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//Download csv file
export const downloadCSVSubsidy = createAsyncThunk(
    'admin/funds/export',
    async (params: DownloadCSVRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await downloadCSV(params);
            dispatch(setDownloadCSV(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);
export const asyncRegisterGrants = createAsyncThunk(
    '/admins/grants/register',
    async (params: RegisterSubsidyRequest, { dispatch, rejectWithValue }) => {
        try {
            params.type = FUND_TYPE_GRANT;
            params.allow_consultation = 1;
            params.updated_at = moment(params.updated_at, 'YYYY年MM月DD日').format('YYYY/MM/DD');
            const response = await registerSubsidy(params);
            const { success } = response.data;
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const asyncEditGrants = createAsyncThunk('/admins/grants/edit', async (params: GrantRequest, { dispatch, rejectWithValue }) => {
    try {
        params.data.allow_consultation = 1;
        params.data.updated_at = moment(params.data.updated_at, 'YYYY年MM月DD日').format('YYYY/MM/DD');
        params.data._method = 'PUT';
        const response = await updateGrants(params);
        const { success } = response.data;
        return success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

// save data reducers
export const grantsManagementSlice = createSlice({
    name: 'grantsManagement',
    initialState: {
        grants: {
            loading: false,
            success: false,
            total: 0,
            per_page: 0,
            current_page: 1,
            lastPage: 0,
            grantsData: [],
        },
        grantDrafts: {
            loading: false,
            success: false,
            total: 0,
            per_page: 0,
            current_page: 1,
            lastPage: 0,
            grantsDraftData: [],
        },
        detail: {
            loading: false,
            success: false,
            error: false,
            grantDetail: {} as MoneyDetail,
        },
        downloadCSVData: '',
        registerGrant: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        editGrant: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
    } as GrantsData,
    reducers: {
        setGrants: (state, { payload }) => {
            state.grants.total = payload.total;
            state.grants.per_page = payload.per_page;
            state.grants.current_page = payload.current_page;
            state.grants.lastPage = payload.last_page;
            state.grants.grantsData = payload.data;
        },
        setGrantDrafts: (state, { payload }) => {
            state.grantDrafts.total = payload.total;
            state.grantDrafts.per_page = payload.per_page;
            state.grantDrafts.current_page = payload.current_page;
            state.grantDrafts.lastPage = payload.last_page;
            state.grantDrafts.grantsDraftData = payload.data;
        },
        setGrantDetail: (state, { payload }) => {
            state.detail.grantDetail = payload;
        },
        resetGrantDetailState: (state) => {
            state.detail.loading = false;
            state.detail.success = false;
            state.detail.error = false;
            state.detail.grantDetail = {} as MoneyDetail;
        },
        setDownloadCSV: (state, { payload }) => {
            state.downloadCSVData = payload;
        },
        resetGrant: (state) => {
            state.registerGrant = {
                loading: false,
                success: false,
                error: false,
                message: '',
            };
        },
        resetEditGrant: (state) => {
            state.editGrant = {
                loading: false,
                success: false,
                error: false,
                message: '',
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGrantDetail.pending, (state: GrantsData) => {
                state.detail.loading = true;
            })
            .addCase(fetchGrantDetail.fulfilled, (state: GrantsData) => {
                state.detail.loading = false;
                state.detail.success = true;
            })
            .addCase(fetchGrantDetail.rejected, (state: GrantsData, { payload }) => {
                state.detail.loading = false;
                state.detail.success = false;
                state.detail.error = true;
            })
            // create
            .addCase(asyncRegisterGrants.pending, (state: GrantsData) => {
                state.registerGrant.loading = true;
            })
            .addCase(asyncRegisterGrants.fulfilled, (state: GrantsData) => {
                state.registerGrant.loading = false;
                state.registerGrant.success = true;
            })
            .addCase(asyncRegisterGrants.rejected, (state: GrantsData, { payload }) => {
                state.registerGrant.loading = false;
                state.registerGrant.success = false;
                state.registerGrant.error = true;
                state.registerGrant.message = payload as string | object;
            })
            // edit
            .addCase(asyncEditGrants.pending, (state: GrantsData) => {
                state.editGrant.loading = true;
            })
            .addCase(asyncEditGrants.fulfilled, (state: GrantsData) => {
                state.editGrant.loading = false;
                state.editGrant.success = true;
            })
            .addCase(asyncEditGrants.rejected, (state: GrantsData, { payload }) => {
                state.editGrant.loading = false;
                state.editGrant.success = false;
                state.editGrant.error = true;
                state.editGrant.message = payload as string | object;
            })
            .addCase(fetchGrants.pending, (state: GrantsData) => {
                state.grants.loading = true;
            })
            .addCase(fetchGrants.fulfilled, (state: GrantsData) => {
                state.grants.loading = false;
                state.grants.success = true;
            })
            .addCase(fetchGrants.rejected, (state: GrantsData) => {
                state.grants.loading = false;
                state.grants.success = false;
            })
            .addCase(fetchGrantDrafts.pending, (state: GrantsData) => {
                state.grantDrafts.loading = true;
            })
            .addCase(fetchGrantDrafts.fulfilled, (state: GrantsData) => {
                state.grantDrafts.loading = false;
                state.grantDrafts.success = true;
            })
            .addCase(fetchGrantDrafts.rejected, (state: GrantsData) => {
                state.grantDrafts.loading = false;
                state.grantDrafts.success = false;
            });
    },
});

export const { setGrants, resetGrant, resetEditGrant, setGrantDetail, setGrantDrafts, resetGrantDetailState, setDownloadCSV } =
    grantsManagementSlice.actions;
export const grantsManagementSelector = (state: RootState) => state.grantsManagement;
