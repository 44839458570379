import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { exportUsers } from 'api/user';
import { exportCompanies } from 'api/companies';
import { ApplicationRequest } from 'app/slice/applicationsSlice';
import { exportApplications } from 'api/applications';

type ExportState = {
    success: boolean | null,
    loading: boolean,
    data: Blob | null
}

export const getExportUsers = createAsyncThunk(
    '/admin/users/export',
    async (roleId: number, { dispatch, rejectWithValue }) => {
        try {
            let response = await exportUsers(roleId);
            dispatch(setData(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const getExportCompanies = createAsyncThunk(
    '/admin/companies/export',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            let response = await exportCompanies();
            dispatch(setData(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const getExportApplications = createAsyncThunk(
    '/admin/applications/export',
    async (params: ApplicationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await exportApplications(params);
            dispatch(setData(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const exportSlice = createSlice({
    name: 'export',
    initialState: {
        success: null,
        loading: false,
        data: null,
    } as ExportState,
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload;
        },
        resetExport: (state) => {
            state.success = null;
            state.loading = false;
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getExportUsers.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.data = null;
        });
        builder.addCase(getExportUsers.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getExportUsers.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getExportCompanies.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.data = null;
        });
        builder.addCase(getExportCompanies.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getExportCompanies.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getExportApplications.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.data = null;
        });
        builder.addCase(getExportApplications.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getExportApplications.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
    },
});

export const { resetExport, setData } = exportSlice.actions;
export const selectExport = (state: RootState) => state.export;
