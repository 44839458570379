import { getAxiosClientWithToken } from './index';

export const changePassword = (email: string, old_password: string, new_password: string, new_password_confirmation: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch('/admin/user/update-password', {
        email,
        old_password,
        new_password,
        new_password_confirmation,
    });
};
