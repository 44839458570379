import { getAxiosClientWithToken } from './index';
import { SearchLogRequest } from 'app/slice/searchLogSlice';

export const getSearchLogs = (params: SearchLogRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/search-log', {
        params: params
    });
};

export const downloadSearchLogs = (params: SearchLogRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/search-log/download', {
        params: params,
        responseType: 'arraybuffer'
    });
};