import { getAxiosClient, UPLOAD_FILE_URL } from '../api';

export class CustomUploadAdapter {
    public loader: any;
    constructor(loader: any) {
        this.loader = loader;
    }

    upload() {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            this.loader.file.then((file: File) => {
                formData.append('file', file);
                formData.append('_method', 'PUT');
                try {
                    getAxiosClient().post(UPLOAD_FILE_URL, formData)
                        .then(response => {
                            const { data, success } = response.data;
                            if (success) {
                                resolve({ default: data.path });
                            } else {
                                reject('Error!');
                            }
                        })
                        .catch(e => reject(e))
                } catch (e) {
                    reject(e);
                }
            });
        });
    }
}
