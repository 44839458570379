import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { MoneyInfo, NewCapitalParams, MoneyDetail, EditCapital, IsDraft } from 'types';
import { getErrorMessage } from 'api';
import { getSubsidies, getSubsidyDetail, downloadCSV } from '../../api/subsidies';
import {
    createNewCapital,
    getCapitalOrInvestment,
    getHeadquarters,
    getIndividualOrCorporation,
    getIndustries,
    getNumberEmployees,
    getPurposes,
    getYearsEstablished,
    updateCapital,
} from 'api/loan';
import { FUND_TYPE_LOAN } from 'config/constant';
import moment from 'moment';

export type LoanManagementRequest = {
    column?: OptionFilterLoanManagement;
    keyword?: string;
    page?: number;
    type?: number;
    is_draft?: IsDraft;
};

export type DataRequest = {
    id?: number;
    code?: string;
    name?: string;
};

export type DownloadCSVRequest = {
    column?: OptionFilterLoanManagement;
    keyword?: string;
    type?: number;
    is_draft?: IsDraft;
};

export type OptionFilterLoanManagement = 'code' | 'title' | 'overview' | 'target_groups' | 'amount' | 'application_period';

export const fetchLoan = createAsyncThunk('admin/funds/loan/list', async (params: LoanManagementRequest, { dispatch, rejectWithValue }) => {
    try {
        let response = await getSubsidies(params);
        dispatch(setLoan(response.data));
        return true;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const fetchLoanDrafts = createAsyncThunk(
    'admin/funds/loan/drafts',
    async (params: LoanManagementRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSubsidies(params);
            dispatch(setLoanDrafts(response.data));
            return true;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchLoanDetail = createAsyncThunk(
    'admin/funds/loan/:uuid',
    async (params: string | undefined, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSubsidyDetail(params);
            const { data = {}, success } = response.data;

            if (success) {
                dispatch(setLoanDetail(data));

                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// Purposes
export const fetchPurposes = createAsyncThunk('admin/master/subsidies/purpose', async (params, { dispatch, rejectWithValue }) => {
    try {
        const response = await getPurposes();
        const { success } = response.data;
        if (success) {
            dispatch(setPurposeData(response.data));
            return true;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

// Industries
export const fetchIndustries = createAsyncThunk('admin/master/industries', async (params, { dispatch, rejectWithValue }) => {
    try {
        const response = await getIndustries();
        const { success } = response.data;

        if (success) {
            dispatch(setIndustriesData(response.data));
            return true;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

// Number Employees
export const fetchNumberEmployees = createAsyncThunk('admin/master/employees', async (params, { dispatch, rejectWithValue }) => {
    try {
        const response = await getNumberEmployees();
        const { success } = response.data;

        if (success) {
            dispatch(setNumberEmployeeData(response.data));
            return true;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

// Individual or corporation
export const fetchIndividualCorporation = createAsyncThunk(
    'admin/master/individual/corporatio',
    async (params, { dispatch, rejectWithValue }) => {
        try {
            const response = await getIndividualOrCorporation();
            const { success } = response.data;

            if (success) {
                dispatch(setIndividualOrCorporationData(response.data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// Capital or total investment
export const fetchCapitalOrInvestment = createAsyncThunk(
    'admin/master/individual/corporatio',
    async (params, { dispatch, rejectWithValue }) => {
        try {
            const response = await getCapitalOrInvestment();
            const { success } = response.data;

            if (success) {
                dispatch(setCapitalOrInvestmentData(response.data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// Years since the establishment of the corporation
export const fetchYearsEstablished = createAsyncThunk(
    'admin/master/individual/corporatio',
    async (params, { dispatch, rejectWithValue }) => {
        try {
            const response = await getYearsEstablished();
            const { success } = response.data;

            if (success) {
                dispatch(setYearsEstablishedData(response.data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// The location of the company
export const fetchLocationCompany = createAsyncThunk('admin/master/subsidy/headquarters', async (params, { dispatch, rejectWithValue }) => {
    try {
        const response = await getHeadquarters();
        const { success } = response.data;

        if (success) {
            dispatch(setLocationCompanyData(response.data));
            return true;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

// Add new capital
export const addNewCapital = createAsyncThunk('/admin/funds/capital', async (params: NewCapitalParams, { dispatch, rejectWithValue }) => {
    try {
        params.allow_consultation = 0;
        params.type = FUND_TYPE_LOAN;
        params.updated_at = moment(params.updated_at, 'YYYY年MM月DD日').format('YYYY/MM/DD');
        const response = await createNewCapital(params);
        return response.data?.success ?? false;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

// Edit capital
export const capitalCorrection = createAsyncThunk(
    '/admin/funds/edit/capital',
    async (params: EditCapital, { dispatch, rejectWithValue }) => {
        try {
            params.data.allow_consultation = 0;
            params.data._method = 'PUT';
            params.data.updated_at = moment(params.data.updated_at, 'YYYY年MM月DD日').format('YYYY/MM/DD');
            const response = await updateCapital(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

//Download csv file
export const downloadCSVSubsidy = createAsyncThunk(
    'admin/funds/export',
    async (params: DownloadCSVRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await downloadCSV(params);
            dispatch(setDownloadCSV(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export type LoanData = {
    loan: {
        loading: boolean;
        success: boolean;
        total: number;
        per_page: number;
        current_page: number;
        lastPage: number;
        loanData: MoneyInfo[];
    };
    loanDraft: {
        loading: boolean;
        success: boolean;
        total: number;
        per_page: number;
        current_page: number;
        lastPage: number;
        loanDraftData: MoneyInfo[];
    };
    detail: {
        loading: boolean;
        success: boolean;
        error: boolean;
        loanDetail: MoneyDetail;
    };
    purposeData: {
        data: DataRequest[];
    };
    industriesData: {
        data: DataRequest[];
    };
    numberEmployee: {
        data: DataRequest[];
    };
    individualOrCorporation: {
        data: DataRequest[];
    };
    capitalOrInvestment: {
        data: DataRequest[];
    };
    yearsEstablished: {
        data: DataRequest[];
    };
    locationCompany: {
        data: DataRequest[];
    };
    downloadCSVData: string;
    newCapital: {
        success: boolean;
        message: string | object;
        loading: boolean;
        error: boolean;
    };
    editCapital: {
        success: boolean;
        message: string | object;
        loading: boolean;
        error: boolean;
    };
};

// save data reducers
export const loanManagementSlice = createSlice({
    name: 'loanManagement',
    initialState: {
        loan: {
            loading: false,
            success: false,
            total: 0,
            per_page: 0,
            current_page: 1,
            lastPage: 0,
            loanData: [],
        },
        loanDraft: {
            loading: false,
            success: false,
            total: 0,
            per_page: 0,
            current_page: 1,
            lastPage: 0,
            loanDraftData: [],
        },
        detail: {
            loading: false,
            success: false,
            error: false,
            loanDetail: {} as MoneyDetail,
        },
        purposeData: {
            data: [],
        },
        industriesData: {
            data: [],
        },
        numberEmployee: {
            data: [],
        },
        individualOrCorporation: {
            data: [],
        },
        capitalOrInvestment: {
            data: [],
        },
        yearsEstablished: {
            data: [],
        },
        locationCompany: {
            data: [],
        },
        downloadCSVData: '',
        newCapital: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        editCapital: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
    } as LoanData,
    reducers: {
        setLoan: (state, { payload }) => {
            state.loan.total = payload.total;
            state.loan.per_page = payload.per_page;
            state.loan.current_page = payload.current_page;
            state.loan.lastPage = payload.last_page;
            state.loan.loanData = payload.data;
        },
        setLoanDrafts: (state, { payload }) => {
            state.loanDraft.total = payload.total;
            state.loanDraft.per_page = payload.per_page;
            state.loanDraft.current_page = payload.current_page;
            state.loanDraft.lastPage = payload.last_page;
            state.loanDraft.loanDraftData = payload.data;
        },
        setPurposeData: (state: LoanData, action) => {
            state.purposeData = action?.payload;
        },
        setLoanDetail: (state, { payload }) => {
            state.detail.loanDetail = payload;
        },
        setIndustriesData: (state: LoanData, action) => {
            state.industriesData = action?.payload;
        },
        setNumberEmployeeData: (state: LoanData, action) => {
            state.numberEmployee = action?.payload;
        },
        setCapitalOrInvestmentData: (state: LoanData, action) => {
            state.capitalOrInvestment = action?.payload;
        },
        setIndividualOrCorporationData: (state: LoanData, action) => {
            state.individualOrCorporation = action?.payload;
        },
        setYearsEstablishedData: (state: LoanData, action) => {
            state.yearsEstablished = action?.payload;
        },
        setLocationCompanyData: (state: LoanData, action) => {
            state.locationCompany = action?.payload;
        },
        resetLoanDetailState: (state) => {
            state.detail.loading = false;
            state.detail.success = false;
            state.detail.error = false;
            state.detail.loanDetail = {} as MoneyDetail;
        },
        setDownloadCSV: (state, { payload }) => {
            state.downloadCSVData = payload;
        },
        resetLoan: (state: LoanData) => {
            state.newCapital = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetEditLoan: (state: LoanData) => {
            state.editCapital = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addNewCapital.pending, (state: LoanData) => {
                state.newCapital.loading = true;
            })
            .addCase(addNewCapital.rejected, (state: LoanData, action) => {
                state.newCapital = {
                    success: false,
                    message: action.payload as string | object,
                    loading: false,
                    error: true,
                };
            })
            .addCase(addNewCapital.fulfilled, (state: LoanData) => {
                state.newCapital.success = true;
                state.newCapital.loading = false;
            })

            .addCase(capitalCorrection.pending, (state: LoanData) => {
                state.editCapital.loading = true;
            })
            .addCase(capitalCorrection.rejected, (state: LoanData, action) => {
                state.editCapital = {
                    success: false,
                    message: action.payload as string | object,
                    loading: false,
                    error: true,
                };
            })
            .addCase(capitalCorrection.fulfilled, (state: LoanData) => {
                state.editCapital.success = true;
                state.editCapital.loading = false;
            })
            .addCase(fetchLoanDetail.pending, (state: LoanData) => {
                state.detail.loading = true;
            })
            .addCase(fetchLoanDetail.fulfilled, (state: LoanData) => {
                state.detail.loading = false;
                state.detail.success = true;
            })
            .addCase(fetchLoanDetail.rejected, (state: LoanData) => {
                state.detail.loading = false;
                state.detail.success = false;
                state.detail.error = true;
            })

            .addCase(fetchLoan.pending, (state: LoanData) => {
                state.loan.loading = true;
            })
            .addCase(fetchLoan.fulfilled, (state: LoanData) => {
                state.loan.loading = false;
                state.loan.success = true;
            })
            .addCase(fetchLoan.rejected, (state: LoanData) => {
                state.loan.loading = false;
                state.loan.success = false;
            })
            .addCase(fetchLoanDrafts.pending, (state: LoanData) => {
                state.loanDraft.loading = true;
            })
            .addCase(fetchLoanDrafts.fulfilled, (state: LoanData) => {
                state.loanDraft.loading = false;
                state.loanDraft.success = true;
            })
            .addCase(fetchLoanDrafts.rejected, (state: LoanData) => {
                state.loanDraft.loading = false;
                state.loanDraft.success = false;
            });
    },
});

export const {
    setLoan,
    resetLoan,
    resetEditLoan,
    setLoanDrafts,
    setLoanDetail,
    setPurposeData,
    setIndustriesData,
    resetLoanDetailState,
    setNumberEmployeeData,
    setLocationCompanyData,
    setYearsEstablishedData,
    setCapitalOrInvestmentData,
    setIndividualOrCorporationData,
    setDownloadCSV,
} = loanManagementSlice.actions;
export const loanManagementSelector = (state: RootState) => state.loanManagement;
