import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import { getDashboardata } from 'api/dashboard';
import { RootState } from 'app/store';

/**
 * Calls Dashboard API
 **/
export const getDashboard = createAsyncThunk('/admin/dashboard', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await getDashboardata();
        dispatch(setDashboard(response.data));
        return response.data.success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});
type Dashboard = {
    dashboard: DashboardData
};

type DashboardData = {
    loading: boolean;
    success: boolean;
    error: boolean;
    number_of_company: number,
    number_of_employee: number,
    number_of_meeting_fund: number,
    number_of_diagnoses: number,
    number_of_diagnoses_logged_in: number,
    number_of_meeting_policy: number,
    number_of_sharoushi: number,
    number_of_shindanshi: number,
    number_of_unsubscribe_user: number
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        dashboard: {
            loading: false,
            success: false,
            error: false,
            number_of_company: 0,
            number_of_employee: 0,
            number_of_diagnoses: 0,
            number_of_diagnoses_logged_in: 0,
            number_of_meeting_fund: 0,
            number_of_meeting_policy: 0,
            number_of_sharoushi: 0,
            number_of_shindanshi: 0,
            number_of_unsubscribe_user: 0
        }
    } as Dashboard,
    reducers: {
        setDashboard: (state, { payload }) => {
            state.dashboard = payload.data;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboard.pending, (state: Dashboard) => {
                state.dashboard.loading = true;
            })
            .addCase(getDashboard.fulfilled, (state: Dashboard) => {
                state.dashboard.loading = false;
                state.dashboard.success = true;
            })
            .addCase(getDashboard.rejected, (state: Dashboard) => {
                state.dashboard.loading = false;
                state.dashboard.success = false;
                state.dashboard.error = true;
            })
    }
});

export const { setDashboard } = dashboardSlice.actions;
export const selectDashboard = (state: RootState) => state.dashboard;
